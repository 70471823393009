import Navbar from '../components/navbar/navbar';
import ComnFooter from '../components/caseStudiesFooter/comnFooter';
import style from './aboutPage.module.css';

const AboutPage = () => {
  const userData = {
    text_width: "15vw",
    text: "Your ideas and my magic, Let’s connect."
  };

  return (
    <div className={style.about_main_div}>
      <div className={style.navbar}>
        <Navbar />
      </div>
      <div className={style.main_wrapper}>
        <div className={style.top}>
          <div className={style.top_img}>
            <img src='images/desktop/about/profile.png' alt='profile'></img>
          </div>
          <div className={style.top_right}>
            <p className={style.top_text}>“I create digital experiences that borders on efficiency, aesthetics and functionality. For me projects have a soul”</p>
            <div className={style.bottom_text}>
              <h3>From Lahore, Pakistan</h3>
              <p>Robotics and Human Psychology are two of my passion, In long term I envision a journey that culminates precisely at the crossroads of these two domains—where technology and human experience harmonize seamlessly.</p>
            </div>
          </div>
        </div>
        <div className={style.bottom}>
          <div className={style.pic1}>
            <p>“I am an eternal optimist - there’s always a better, more beautiful way and i enjoy looking for it”</p>
            <img src='images/desktop/about/profile2.png' alt='profile_pic2'></img>
          </div>
          <div className={style.pic2}>
            <p>“My job is to define the important aspects of the brand, demonstrating character and emotions”</p>
            <img src='images/desktop/about/profile3.png' alt='profile_pic3'></img>
          </div>
          <div className={style.pic3}>
            <p>“I merge aesthetics with business goals”</p>
            <img src='images/desktop/about/profile4.png' alt='profile_pic4'></img>
          </div>
          <div className={style.pic4}>
            <p>“an engineer who aspired to be a designer and a designer who aspired to be an engineer”</p>
            <img src='images/desktop/about/profile5.png' alt='profile_pic5'></img>
          </div>
        </div>
      </div>
      <div className={style.footer}>
        <ComnFooter userData={userData}/>
      </div>
    </div>
  )
}

export default AboutPage