import {cloudVideos} from "./videos";

export const cardData = [
  {
    cornerImage: "./images/videocomponent/8a907ca0-65a7-427e-b4a2-a85f6c4a1740(4).png",
    cornerHeading: "Producer Application",
    videoSource: cloudVideos.ProducerAppHome,
    // poster: ,
    buttonColor: "rgba(151, 146, 238, 1)",
    buttonText: "View Case Study",
  },
  {
    cornerImage: "./images/videocomponent/8a907ca0-65a7-427e-b4a2-a85f6c4a1740.png",
    cornerHeading: "Token Sale’s Page",
    videoSource: cloudVideos.TokenHome,
    buttonColor: "rgba(205, 107, 121, 1)",
    buttonText: "View Case Study",
  },
  {
    type: 'design',
    cornerImage: "images/casestudy_3/home_header.png",
    cornerHeading: "i2c Design System Portal",
    videoSource: cloudVideos.Design_Thumbnail,
    buttonColor: "#1434CB",
    buttonText: "View Case Study",
  },
  {
    cornerImage: "./images/videocomponent/8a907ca0-65a7-427e-b4a2-a85f6c4a1740(1).png",
    cornerHeading: "YIP Website’s Revamp",
    videoSource: cloudVideos.YIPHome,
    buttonColor: "rgba(188, 59, 186, 1)",
    buttonText: "View Case Study",
  },
  {
    mainDivWidth: "7.097vw",
    mainDivHeight: "10.684vw",
    cornerImage : "./images/videocomponent/comnvaultCornorImage.svg",
    cornerHeading: "ComnVault Dapp",
    imageSorce: "./images/videocomponent/iphone.png",
    imageWidth: "4.647vw",
    imageHeight: "8.753vw",
    buttonColor: "rgba(0, 86, 86, 1)",
    buttonText: "View Case Study",
  },
  {
    mainDivWidth: "",
    mainDivHeight: "",
    cornerImage : "./images/videocomponent/comnvaultCornorImage.svg",
    cornerHeading: "ComnVault Extension",
    imageSorce: "",
    imageWidth: "8vw",
    imageHeight: "9vw",
    buttonColor: "rgba(0, 86, 86, 1)",
    buttonText: "View Case Study",
    marginBtn: "0.55vw"
  },
  {
    mainDivWidth: "10.710vw",
    mainDivHeight: "10.710vw",
    cornerImage : "./images/videocomponent/8a907ca0-65a7-427e-b4a2-a85f6c4a1740(5).png",
    cornerHeading: "Cat Nft Passports",
    imageSorce: "./images/LandingPage/cat.png",
    imageWidth: "10.710vw",
    imageHeight: "8.212vw",
    buttonColor: "rgba(222, 57, 55, 1)",
    buttonText: "View Case Study",
  },
];

export const contentData = [
  {
    smallHeading:
      "product designer              &           deep learning engineer",
      bigHeading1:
      `Digital Designer Helping Future Tech to Make Their Impact And Drop Those Generic Looks.`,
      bigHeading2:
      ``,
      discription: "I am both, Artsy + Technical. If you're pushing boundaries and seeking a designer passionate about impact and creativity, let's connect.",
      buttonText: "",
      mainWidth: "21.342vw",
      centerDiv: true,
  },
  {
    smallHeading:
      "gamer    -   artist   -   anime fan   -   athletic   -   devout muslim",
      bigHeading1:
      "From Lahore, Pakistan",
      bigHeading2:
      "Pakistan.",
      discription: "Robotics and embedded engineering are two of my passion, In long term I envision a journey that culminates precisely at the crossroads of these two domains—where technology and human experience harmonize seamlessly.",
      buttonText: "More About Me",
      mainWidth: "10.658vw",
  },
  {
    smallHeading:
      "figma  -  blender  -  webflow  -  procreate",
      bigHeading1:
      "Proficient",
      bigHeading2:
      "",
      discription: "My Tool kit can help me tackle any scenario, from product design to concept art to 3d to web development. ",
      buttonText: "",
      mainWidth: "7.123vw",
  },
  {
    smallHeading:
      "divergent    -    quick    -    professional    -    non conventional",
      bigHeading1:
      "My Design Philosophy",
      bigHeading2:
      "Philosophy",
      discription: "With standard UX design process as a solid foundation, ensuring attention to user needs and iterative refinement. What sets my approach apart is my artistic perspective that breathes life into every project. In the era of emerging technologies like AI, Web 3.0, and Blockchain, I break free from conventional design norms, adopting a holistic mindset. Enabling me to navigate the evolving landscape with adaptability.",
      buttonText: "",
      mainWidth: "10.684vw",
  },
  // {
  //   smallHeading:
  //     "Websites   -   mobile Applications   -   software   -   NFts   -   Leadership & Strategy",
  //     bigHeading1:
  //     "Services",
  //     bigHeading2:
  //     "",
  //     discription: "Without Strong Visual Communication, Strategy and Quality Assurance, the difference between you and everyone else is",
  //     buttonText: "",
  //     mainWidth: "14.318vw",
  // },
  
];
