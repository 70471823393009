import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDocs, collection, doc, updateDoc } from 'firebase/firestore';
import CryptoJS from 'crypto-js';
import { db } from '../firebase/index'; // Make sure your Firebase config is imported
import style from './admin.module.css';
import NameLoading from '../components/nameloading/nameLoading';
import NameLoadingMbl from '../mobile/comp/nameloading/nameLoading';

export default function Admin() {
  const navigate = useNavigate();
  const [adminPassword, setAdminPassword] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const usersCollection = collection(db, 'Portfolio'); // Adjust collection name if different

    try {
      const querySnapshot = await getDocs(usersCollection);
      let isAdminAuthenticated = false;

      querySnapshot.forEach((doc) => {
        const userData = doc.data();

        if (userData.role === 'admin') {
          const hashedInput = CryptoJS.SHA256(adminPassword).toString();
          if (userData.hashPassword === hashedInput) {
            isAdminAuthenticated = true;
            handleUpdatePassword(e);
          }
        }
      });

      if (!isAdminAuthenticated) {
        setIsLoading(false)
        setMessage('Authentication Failed');
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error fetching data:', error);
      setMessage('Server Error');
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    const usersCollection = collection(db, 'Portfolio'); // Adjust collection name if different

    try {
      const querySnapshot = await getDocs(usersCollection);
      let userDocId = null;

      // Find the user document
      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        if (userData.role === 'user') {
          userDocId = doc.id; // Capture the document ID for update
        }
      });

      if (userDocId) {
        const hashedNewPassword = CryptoJS.SHA256(newUserPassword).toString();
        const userDocRef = doc(db, 'Portfolio', userDocId);

        // Update the user's password in Firestore
        await updateDoc(userDocRef, { hashPassword: hashedNewPassword });
        setMessage('Password Updated');
        setIsLoading(false)
      } else {
        setMessage('User not found.');
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error updating password:', error);
      setMessage('Server Error');
    }
  };

  return (
    <div className={style.admin_container}>
      <div style={{ position: 'fixed', top: '0', zIndex: '10', display: isLoading ? 'block' : 'none' }}>
        <div className={style.desktop}>
          <NameLoading />
        </div>
        <div className={style.mobile}>
          <NameLoadingMbl />
        </div>
      </div>
      <div className={style.logo} onClick={() => navigate('/')}>
        <img src='/imagesVersion2/mobile/navbar/navTitle.svg' alt='logo' />
      </div>
      <div className={style.login}>
        <form className={style.password} onSubmit={handleSubmit}>
          <input
            placeholder='Admin Pass...'
            type='text'
            required
            value={adminPassword}
            onFocus={() => setMessage(null)}
            onInput={() => setMessage(null)}
            onChange={(e) => setAdminPassword(e.target.value)}
          />
          <input
            placeholder='New User Pass...'
            type='text'
            required
            value={newUserPassword}
            onFocus={() => setMessage(null)}
            onInput={() => setMessage(null)}
            onChange={(e) => setNewUserPassword(e.target.value)}
          />
          <button type='submit'>
            {message ?? 'Submit'}
          </button>
        </form>
      </div>
    </div>
  );
}
