import React from "react";
import styles from "./about.module.css";
import { about } from "../../casestudies/data";
import Navbar from "../comp/navbar/navbar";


function About() {
  return (
    <div className={styles.main_div}>
      <div className={styles.nav_container}>
        <Navbar />
      </div>
      <div className={styles.profile}>
        <img
          alt=""
          src=".\imagesVersion2\mobile\about\profile.svg"
          width={"100%"}
          height={"100%"}
        />
      </div>
      <p className={styles.text}>{about.intro1}</p>
      <p className={styles.address}>{about.location}</p>
      <p className={styles.interest}>{about.interest}</p>
      <p className={`${styles.text_design} ${styles.data}`}>{about.intro2}</p>
      <div className={styles.img_wraper}>
        <div className={styles.img}>
          <img
            alt=""
            src=".\imagesVersion2\mobile\about\profile2.svg"
            width={"100%"}
            height={"100%"}
          />
        </div>
        <div className={styles.img}>
          <img
            alt=""
            src=".\imagesVersion2\mobile\about\profile3.svg"
            width={"100%"}
            height={"100%"}
          />
        </div>
      </div>
      <p className={`${styles.text_design} ${styles.data_1}`}>{about.job}</p>
      <p className={`${styles.text_design} ${styles.data_2}`}>{about.intro3}</p>
      <div className={styles.img_wraper}>
        <div className={styles.img}>
          <img
            alt=""
            src=".\imagesVersion2\mobile\about\profile4.svg"
            width={"100%"}
            height={"100%"}
          />
        </div>
        <div className={styles.img}>
          <img
            alt=""
            src=".\imagesVersion2\mobile\about\profile3.svg"
            width={"100%"}
            height={"100%"}
          />
        </div>
      </div>
      <p className={`${styles.text_design} ${styles.data_1}`}>{about.intro4}</p>
    </div>
  );
}

export default About;