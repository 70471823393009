import React, { useState } from "react";
import styles from "./nameLoading.module.css";

function NameLoading() {
  const [loaded, setLoaded] = useState(false);

  return (
    <section>
      <div className={styles.main_div}>
        <div className={styles.backimg}>
          <img
            alt=""
            src="./images/nameloading/Subtract.svg"
            onLoad={()=>{setLoaded(true)}}
            width={"100%"}
            height={"100%"}
          />
        </div>
        <div className={styles.wrapper_div} style={{display: loaded? 'block': 'none'}}>
          <div className={styles.backcolor_plate}>
            <img
              alt=""
              src="./images/nameloading/backcolorplate.svg"
              width={"100%"}
              height={"100%"}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default NameLoading;
