import React, { useState } from 'react';
import style from './videoComponentShadow.module.css'

function VideoComponent(props) {
  const [isShown, setIsShown] = useState(false);
  const {
    cornerImage,
    cornerHeading,
    videoSource,
    buttonColor,
    buttonText,
    type,
  } = props.userData || {};
  return (
    <div
      className={style.mainDiv}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <div className={style.cornerImage}>
        <img
          alt=''
          width={'100%'}
          height={'100%'}
          src={cornerImage || './images/videocomponent/producerCornorImage.svg'}
        />
      </div>
      <div className={style.cornorText}>
        <p>
          {cornerHeading || 'Producer Application'}
        </p>
      </div>

      <div
        className={style.pictureDiv}
      >
        <div className={style.shadowImage}>
          <img
            alt=''
            width={'100%'}
            height={'100%'}
            src='./images/videocomponent/shadow.png'
          />
        </div>
        <div>
          <video loop muted playsInline preload='none' poster='' className={style.video1Div}
            style={{ height: type === 'design' ? '7.1vw' : '', bottom: type === 'design' ? '0' : '', margin: type === 'design' ? 'auto' : '' }}
          >
            <source src={videoSource} type="video/mp4"></source>
          </video>
        </div>
      </div>


      <div className={style.buttonMainDiv}
      >
        <div className={style.buttonText}>
          <p>
            {buttonText || 'View Case Study'}
          </p>
        </div>
        <div className={style.moveableButtonDiv} style={{ marginLeft: isShown ? "0vw" : "15vw", backgroundColor: buttonColor || 'rgba(151, 146, 238, 1)' }}>

        </div>

      </div>

    </div>
  )
}

export default VideoComponent
