import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from './scrollBox.module.css';

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const pagesToExclude = ['/', '/login', '/admin'];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <div className={style.icons_wraper}
        style={{ display: pagesToExclude.includes(pathname) ? 'none' : 'flex' }}
      >
        <div className={style.close_icon}
          onClick={() => { navigate('/') }}
        >
          <img src="/imagesVersion2/close.svg" alt=""></img>
        </div>
        <div className={style.up_arrow_icon}
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }}
        >
          <img src="/imagesVersion2/up_arrow.svg" alt=""></img>
        </div>
      </div>
    </div>
  );
}