import {cloudVideos} from "../videos";

export const tokenData1 = [
 {   heading: "First Iteration",
    content: "Initially, We focused on the coin's design and aesthetics. This approach stemmed from our vision for the coin to serve not only as an integral part of the Opus ecosystem but also as a versatile, everyday currency.",
 },
 {
    heading: "seconed Iteration",
    heading2: "Feedback and insights prompted a shift.",
    content: "Later on I was tasked with aligning the design with the Opus theme, seamlessly integrating it into the company's visual language, and moving away from the coin-centric imagery. We also changed the Coin Logo, to include a cat motif, in-line with the theme.",
    content2: "This 2nd approach helped in establishing a stronger brand identity. It is no longer about the coin but more about investing in the opus ecosystem.",
 },
];

export const footerData = {
    heading: "Let’s Befriend",
    content:"I'm super friendly! Feel free to drop me an email, send a cheerful hello, or connect with me on Insta. Looking forward to hearing from you!",
    mail: "abdullahqamar600@gmail.com",
    linkedin: "https://www.linkedin.com/in/abdullah-qamar-618057206/",
    instagram: "https://www.instagram.com/nerdy4202?igsh=dG90d251d3J5YXdh&utm_source=qr",

};

export const webData = [
   {
      heading: "Empowering Personas",
      detail: "Targeting distinct personas. Gamers, Performers, Storytellers, and Artists/ Musicians, the design aimed to cater to their unique needs, sparking their interest in joining the Opus AI revolution.",
   },
   {
      heading: "Fostering Global Community",
      detail: "The design sought to foster a strong and vibrant global community by encouraging user participation, collaboration, and contribution to the ecosystem. This was achieved by clearly communicating through audience categorization on the home page.",
   },
   {
      heading: "Communicate the AIM",
      detail: "The website needed to effectively communicate Opus AI's breakthrough technology that turns text into interactive video games, positioning the platform as an industry leader.",
   },
];

export const webData2 = [
   {
      title: "Gamer Persona -",
      videolink: cloudVideos.Opus_V2_Gamer,
      content: "Opus's technology transforms narratives into vibrant, ever-evolving worlds that dynamically shift alongside the story's progression.",
      textWidth: '88.205vw',
   },
   {
      title: "Artists & Musicians Persona -",
      videolink: cloudVideos.Opus_V3_Visual_Artist,
      content: "Through Opus Technology Artists & Musician can give birth to imaginary characters, fantastical environments and bring entire worlds into existence. They can make fantasy real.",
      textWidth: '83.077vw',
   },
   {
      title: "Storytellers Persona - ",
      videolink: cloudVideos.Opus_V4_Story_Teller,
      content: "Opus is building the future where storytellers no longer are a passive wordslinger, rather an active orchestrator interacting with a live 3D canvas which turns their words into a living breathing world in the blink of an eye.",
      textWidth: '84.872vw',
   },
   {
      title: "Performers Persona - ",
      videolink: cloudVideos.Opus_V5_Performer,
      content: "Using performance capture app (another app ) Performers can immortalize their likeness as it is today and retain it digitally forever. The likeness can be endlessly used by a producer (in producer app) and you make money whenever it does.",
      textWidth: '88.462vw',
   },
];

export const extensionData = [
   {
      heading: "Problems Targeted",
      detail: "These problems were concluded from the user interviews, mentioned in ComnVault Case Study linked above.",
   },
   {
      heading: "User Flows: Decentralized On-Boarding",
      detail: "Users are not forced and given both options either go decentralized or choose their familiar onboarding option through email (g-mail).",
   },
];

export const extensionCard = [
   {
      card: "Lack of Trust in decentralization.",
      image: "/imagesVersion2/mobile/extension/card1.png",
   },
   {
      card: "Learning curve and hesitancy to engage with new technology/ application.",
      image: "/imagesVersion2/mobile/extension/card2.png",
   },
   {
      card: "On-boarding non-technical users and millennials without overwhelming with information.",
      image: "/imagesVersion2/mobile/extension/card3.png",
   },
];

export const redesignCards = [
   {
     image: "/imagesVersion2/mobile/producer/card1.svg",
     title: "Conceptualizing",
     content: "Big vision, mapping the biz and user terrain—like treasure hunting with flair.",
   },
   {
     image: "/imagesVersion2/mobile/producer/card2.svg",
     title: "First Iteration",
     content: 'Sketching dreams, crafting trials, and setting sail on the sea of testing mischief.',
   },
   {
     image: "/imagesVersion2/mobile/producer/card3.svg",
     title: "User Testing",
     content: 'Observing the Grandmas, they think LOL means Lots of Love',
   },
   {
     image: "/imagesVersion2/mobile/producer/card4.svg",
     title: "Refinement",
     content: 'Giving our AI a final makeover. Empathizing with no technical. After all it’s their product.',
   },
   {
     image: "/imagesVersion2/mobile/producer/card5.svg",
     title: "2nd Iteration",
     content: "Grandmas, spill the tea on the time you went slim mode. We'll watch!",
   },
 ];
 export const data = [
   {
      image: "/imagesVersion2/mobile/producer/img1.png",
      content: "Editor Mode and main home screen, users can create their new projects and manage library.",
    },
   {
     image: "/imagesVersion2/mobile/producer/img2.png",
     content: "Library view: showcasing all the projects/books user has written or bought.",
   },
   // {
   //   image: "/imagesVersion2/mobile/producer/img3.png",
   //   content: 'Sketching dreams, crafting trials, and setting sail on the sea of testing mischief.',
   // },
   {
     image: "/imagesVersion2/mobile/producer/img3.png",
     content: 'Market Place: Initially marketplace will feature Opus Book NFTs which are fictional stories. As users begin to create stories and community, the marketplace dynamics will evolve accordingly.',
   },
   {
     image: "/imagesVersion2/mobile/producer/img4.png",
     content: 'Multiple Books View.',
   },
   {
     image: "/imagesVersion2/mobile/producer/img5.png",
     content: "Community Mode: Governance screen, view proposals by counselors for ecosystem improvements, where users can vote, fund, and invite counselors to join the project team.",
   },
   {
     image: "/imagesVersion2/mobile/producer/img6.png",
     content: "Detailed proposal view.",
   },
   {
     image: "/imagesVersion2/mobile/producer/img7.png",
     content: "Counselors Screen: Apply for available seats. Counselors act as the personas' moderators, initiating changes. To apply, you need to hold specific amount of comncoins.",
   },
   {
     image: "/imagesVersion2/mobile/producer/img8.png",
     content: "Opus Logbook: I designed it for a specific purpose: fortify community bonds, foster unity, and to work on improving the ecosystem for themselves.",
   },
   {
     image: "/imagesVersion2/mobile/producer/img9.png",
     content: "Opus kicks off with its own narrative, then lets users take the reins as the journey unfolds. This book catalogs chapters, counselor insights, changes, improvements, and more.",
   },
 ];
 export const pointData = [
   {
     image: "/imagesVersion2/mobile/producer/glassess.svg",
     title: "Grandmas weak eyesight require more visual based data",
     data: "We understood that they are more of visual navigator, reading a lot of text frustrates them.",
   },
   {
     image: "/imagesVersion2/mobile/producer/brain.svg",
     title: "Hard time grasping community mode.",
     data: "Non-technical people require gradual learning, one at a time!",
   }
 ];
 export const boxData = [
   {
   img: "/imagesVersion2/mobile/producer/box1.svg",
   title: "Visual improvement",
   detail: "Added project thumbnail previews, icons, reduced text and clutter",
   },
   {
     img: "/imagesVersion2/mobile/producer/box2.svg",
     title: "Removed community mode",
     detail: "Removed Governance, Counsel and Marketplace, will be introduced in the future.",
   },
   {
     img: "/imagesVersion2/mobile/producer/box3.svg",
       title: "Added whiteboard",
       detail: "To flush out ideas, scenes, & create mood boards. the design was kept old school & organic.",
   },
 ];
 export const screenData = [
   {
      screen: "/imagesVersion2/mobile/producer/screen1.svg",
      videolink:cloudVideos.ProducerAppHome,
      heading: "Writer Home First Interaction",
      content: "After onboarding you will get a quick start guide, with two clear and distinct call to actions.",
   },
   {
      screen: "/imagesVersion2/mobile/producer/screen2.svg",
      videolink:cloudVideos.PA_V3_Projects,
      heading: "Home with Projects",
      content: "After using the application and crafting your projects, your recent will be displayed with a thumbnail. Hovering over each thumbnail treats you to a mini preview of the project's video.",
   },
   {
      screen: "/imagesVersion2/mobile/producer/screen3.svg",
      videolink: cloudVideos.PA_V4_Library,
      heading: "Project Library",
      content: "Library is user’s collection of books/stories, consisting of their own projects, bought from market, derivative of originals and their recordings. ",
   },
 ];

 export const dApp = [
   {
      videolink: cloudVideos.TA_V1_Onboarding1,
      text: "Onboarding -",
      poster: '/imagesVersion2/mobile/vault/poster1.png',
   },
   {
      videolink: cloudVideos.TA_V2_Flows2,
      text: "Three Primary flows -",
      poster: '/imagesVersion2/mobile/vault/poster2.png',
   },
   {
      videolink: cloudVideos.TA_V3_Navigation_Center2,
      text: "Navigation Center -",
      poster: '/imagesVersion2/mobile/vault/poster3.png',
   },
   {
      videolink: cloudVideos.TA_V4_Treasury2,
      text: "Treasury -",
      poster: '/imagesVersion2/mobile/vault/poster4.png',
   },
   {
      videolink: cloudVideos.TA_V4_Treasury12,
      text: "Send / Receive -",
      poster: '/imagesVersion2/mobile/vault/poster5.png',
   },
   {
      videolink: cloudVideos.TA_V6_Minting12,
      text: "Minting Customized Coin-",
      poster: '/imagesVersion2/mobile/vault/poster6.png',
   },
 ];

 export const vaultAutoSlider = [
   "imagesVersion2/mobile/vault/autoSlider1.svg",
   "imagesVersion2/mobile/vault/autoSlider2.svg",
   "imagesVersion2/mobile/vault/autoSlider3.svg",
   "imagesVersion2/mobile/vault/autoSlider4.svg",
   "imagesVersion2/mobile/vault/autoSlider5.svg",
   "imagesVersion2/mobile/vault/autoSlider6.svg",
   "imagesVersion2/mobile/vault/autoSlider7.svg",
   "imagesVersion2/mobile/vault/autoSlider8.svg",
   "imagesVersion2/mobile/vault/autoSlider9.svg",
 ];

 export const lastData = [
   {
      image: "/imagesVersion2/mobile/vault/waves.svg",
      heading: "Maintain a flexible mindset",
      content: "Navigating through an unfamiliar landscape and to bring out novel ideas in short period of time was extremely taxing and daunting, it forced me to be flexible, to take steps that can yield the highest output.",
   },
   {
      image: "/imagesVersion2/mobile/vault/megaphone.svg",
      heading: "Overcame biased opinions",
      content: "Although I initially shared the same level of knowledge as users, The preconceived notions I built up while learning about the technology proved to be somewhat different after engaging with users. This emphasized the importance of embracing diverse perspectives, even when concepts seem straightforward.",
   },
 ];

 export const valutData = [
   {
      heading: "ComnCoin",
      text: "Digital crypto money that's not controlled by a single entity.",
   },
   {
      heading: "ComnVault",
      text: "Your secure digital wallet for managing money.",
   },
   {
      heading: "Treasury",
      text: "A place for managing and tracking your digital transactions and personal tokens.",
   },
   {
      heading: "Mint Coin",
      text: "Create your own special digital coins with unique features.",
   },
   {
      heading: "Mint Coin",
      text: "Earn digital coins by completing tasks in the Opus ecosystem.",
   },
   {
      heading: "Navigator",
      text: "Learn about digital money and blockchain through an easy-to-use platform.",
   },
   {
      heading: "Locker",
      text: "Store and view your special digital items and exclusive passes.",
   },
   {
      heading: "Passports",
      text: "Digital passes that grant special access to exclusive features.",
   },
   {
      heading: "Transactions",
      text: "Keep track of your digital money history.",
   },
   {
      heading: "Transfers",
      text: "Send or receive digital coins directly to/ from others.",
   },
 ];

 export const valutCards = [
   "UX Writing: Using Layman Terms to create a layer on blockchain Jargons.",
   "Extension: Making users comfortable with the platform, with filtered features and then funneling them.",
   "Reward System: Users will be rewarded for engaging in opus ecosystem leading to reinforcement learning.",
   "Passports NFTs: An onboarding playful initiative, acting as access to personas.",
   // "Strong Visual Communication",
 ];

 export const circles = [
   "/imagesVersion2/mobile/vault/circle1.svg",
   "/imagesVersion2/mobile/vault/circle2.svg",
   "/imagesVersion2/mobile/vault/circle3.svg",
   "/imagesVersion2/mobile/vault/circle4.svg",
   "/imagesVersion2/mobile/vault/circle5.svg",
   "/imagesVersion2/mobile/vault/circle6.svg",
 ];

 export const blackCard = [
   {
      image: "/imagesVersion2/mobile/vault/imgcard1.svg",
      heading: "Decentralized System",
      text: "Create a decentralized system for Opus Ecosystem.",
   },
   {
      image: "/imagesVersion2/mobile/vault/imgcard2.svg",
      heading: "Economic System",
      text: "Build an integrated opus personas economic system",
   },
 ]

 export const valutProduct = [
   {
   img: "/imagesVersion2/mobile/vault/product1.svg",
   // title: "Gamification",
   detail: "Discover potential novelty, to hook users & community.",
   },
   {
     img: "/imagesVersion2/mobile/vault/product2.svg",
   //   title: "Seamless Access",
     detail: "Identify UX enhancements in blockchain. Reduce the learning curve",
   },
   {
     img: "/imagesVersion2/mobile/vault/product3.svg",
      //  title: "Ownership & Exclusivity",
       detail: "To test & refine ideas before Main Product launch.(text-to-video)",
   },
 ];

 export const question1 =[
   "Could you share your insights on designing a decentralized economic system?",
   "How can we enhance user experience within such a system?",
   "What role do smart contracts play in this context?",
   "How can we ensure inclusivity in our decentralized economic system?",
   "How can we address potential security concerns?",
   "How do you see the role of consensus mechanisms in shaping user experience?",
   "How can we foster user trust in our decentralized economic system?",
   "Finally, any advice on adapting to the evolving landscape of blockchain technology?",
 ];
 export const expertData = [
   {
      heading: "Trust and Transparency",
      text: "Establish trust through decentralized identity verification and real-time transaction updates.",
   },
   {
      heading: "Security",
      text: "Implement advanced encryption, multi-factor authentication, and zero-knowledge proofs.",
   },
   {
      heading: "Consensus Mechanism",
      text: "Opt for mechanisms like Delegated Proof-of-Stake for efficiency and fast confirmations.",
   },
   {
      heading: "Adaptability",
      text: "Stay agile, regularly update the platform, and foster a community-driven approach.",
   },
   {
      heading: "Scalability",
      text: "Utilize sharding techniques to enhance system scalability.",
   },
 ];

 export const expertData1 = [
   {
      heading: "Consensus Mechanism",
      text: "Explore Proof-of-Stake for efficiency and scalability.",
   },
   {
      heading: "User Experience (UX)",
      text: "Clear writing, using familiar terms for easy user understanding.",
   },
   {
      heading: "Smart Contracts",
      text: "Design modular and customizable contracts with visual builders.s.",
   },
   {
      heading: "Inclusivity",
      text: "Prioritize accessibility and provide educational resources.",
   },
 ];

 export const viewsQuestion = [
   {
      heading: "Awareness",
      q1: "How familiar are you with the concept of blockchain technology?",
      q2: "Have you encountered any real-world applications of blockchain in your professional or personal life?",
   },
   {
      heading: "Knowledge",
      q1: "What specific aspects of blockchain technology do you feel most confident about?",
      q2: "Are there any challenges or complexities in blockchain that you find difficult to understand?",
   },
   {
      heading: "Trust",
      q1: "How confident are you in the security and trustworthiness of blockchain systems?",
      q2: "What factors contribute to your trust or lack of trust in blockchain technology?",
   },
   {
      heading: "Future Prediction",
      q1: "In your opinion, how will blockchain technology evolve in the next five years?",
      q2: "Can you foresee any challenges or breakthroughs that might shape the future of blockchain?",
   },
   {
      heading: "Opinions on Opus’s core product",
      q1: "From your perspective, what impact could Opus's ecosystem have on the future of content creation and monetization?",
      q2: "Do you see decentralized platforms like Opus becoming mainstream in the entertainment industry, and why or why not?",
   },
 ];

 export const viewerData = [
   {
      image: "/imagesVersion2/mobile/vault/layla.svg",
      name: "Layla Kaleem",
      data1: "Marketing & Content Strategist",
      data2: "Had written content related to blockchain in gaming sector."
   },
   {
      image: "/imagesVersion2/mobile/vault/mehmood.svg",
      name: "Mahmood Sadiq",
      data1: "Network Administrator",
      data2: "No knowledge about blockchain, knows about investment in crypto"
   },
   {
      image: "/imagesVersion2/mobile/vault/zaid1.svg",
      name: "Zaid Ahmad",
      data1: "Software Engineer",
      data2: "Has worked in solidity, a framework for building blockchain."
   },
   {
      image: "/imagesVersion2/mobile/vault/hamzaq.svg",
      name: "M. Hamza Malik",
      data1: "Machine Learning Engineer & UE Developer",
      data2: "A knowledgeable person in the field of engineering."
   }
 ];