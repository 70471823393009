// const opusPassportData = {
//       role: "Strategy, Illustrator & Designer",
//       lengthOfProject: "1.5 months",
//       conceptObjection: "The primary goal of the Opus Passport project was to develop a secure and engaging login mechanism for Opus AI's text-to-video games platform. By using blockchain technology and Non-Fungible Tokens (NFTs), we sought to create a system where users could 'own' their login credentials in the form of unique Cat NFTs. The passports not only enhance platform security but also add a playful and interactive element, aligning with Opus AI's brand theme of cat illustrations.",
//       userExperience: [
//         {
//           point: "a. Ownership and Exclusivity: Owning an Opus Passport provides a sense of exclusivity and ownership, reinforcing the user's connection to the Opus AI platform."
//         },
//         {
//           point: "b. Gamification: The playful cat theme adds a fun and gamified element to the login process, encouraging users to collect different Cat NFTs for variety and rewards."
//         },
//         {
//           point: "c. Seamless Access: Users who own an Opus Passport experience a streamlined login process with no need for traditional passwords or email verifications."
//         },
//       ],
//       Categorization: "These NFTs are divided in 8 categories respective of the tier. These tier are the purchasable bundles."
//     };
// const opusWebsite = {
//   role: "UX/UI Designer, Web Designer",
//   lengthOfProject: "1.5 months",
//   overview: "This case study delves into the innovative website design of Opus Ai, Opus Ai is a narrative ai, its a pioneering platform that transforms text into captivating video games. With a mission to shape the future of entertainment, Opus AI cultivates a self-sustaining global community that immerses audiences in unparalleled levels of storytelling and engagement.",
//   designObjectives: [
//     {
//       point: "Communicate the Technology: The website needed to effectively communicate Opus AI's breakthrough technology that turns text into interactive video games, positioning the platform as an industry leader."
//     },
//     {
//       point: "Foster Global Community: The design sought to foster a strong and vibrant global community by encouraging user participation, collaboration, and contribution to the ecosystem. This was achieved by clearly communicating through audience categorization on the home page."
//     },
//     {
//       point: "Empower Personas: Targeting four distinct personas - Gamers, Performers, Storytellers, and Artists/Musicians, the design aimed to cater to their unique needs, sparking their interest in joining the Opus AI revolution."
//     }
//   ],
//   gamersPersona: "Opus’s technology turns that narrative into living breathing worlds th,at change dynamically as thenarrative grows and changes.", 
//   artistandMusisian: "Through Opus Technology Artists & Musician can give birth to imaginary characters, fantastical environments and bring entire worlds into existence. They can make fantasy real.",
//   storyTeller: " Opus is building the future where storytellers no longer are a passive wordslinger, rather an active orchestrator interacting with a live 3D canvas which turns their words into a living breathing world in the blink of an eye.",
//   performers: "Using performance capture app (another app ) Performers can immortalize their likeness as it is today and retain it digitally forever. The likeness can be endlessly used by a producer (in producer app) and you make money whenever it does.",
//   twelvePageDesign: "The Design Language used feels broken. The theme breaks at some points while works exceptional at other points, which was intentionally put together. to convey the idea of “different, unique, and change”.",
// };

import {cloudVideos} from "../videos";

export const commonCoin = {
  role: "Strategy, Illustrator & Designerr",
  lengthOfProject: "Two iterations were completed, with each iteration spanning one week.",
  overview: [
    {
      point: "Comncoin, developed by Opus AI, is a cutting-edge cryptocurrency designed to power the Opus AI ecosystem."
    },
    {
      point: "The CommonCoin token sale aims to fund the ongoing development of Opus AI and establish a thriving community of early adopters and supporters."
    }
  ],
  firstApproch: "Initially, the first iteration focused on the coin's design and aesthetics. This approach stemmed from our vision for the coin to serve not only as an integral part of the Opus ecosystem but also as a versatile, everyday currency. Consequently, we treated it as an independent entity, distinct from Opus' overarching theme.",
  secondApproch: [
    {
      point: "Later on I was tasked with aligning the design with the Opus theme, seamlessly integrating it into the company's visual language, and moving away from the coin-centric imagery. We also changed the Coin Logo, to include a cat motif, in line with the theme."
    },
    {
      point: "This 2nd approach helped in establishing a stronger brand identity. It is no longer about the coin but more about investing in the opus ecosystem."
    },
  ]
}

// const CommonCoinWalletData = {
//   role: "UX/UI Designer, Researcher, Product Manager",
//   lengthOfProject: "2 weeks",
//   overview: [
//     {
//       point: "Common Vault is a decentralized application (DApp) that serves as a management platform for the digital currency known as 'Common Coins.' Created by Opus.ai, "
//     },
//     {
//       point: " Common Coin is a cryptocurrency specifically developed for use within the Opus Ecosystem but can also be employed for day-to-day transactions. "
//     },
//   ],
//   contextExtension : [
//     {
//       point: "As the ComnVault app was still in the testing and development phase and required additional time, Opus decided to move forward with the launch of its primary product, the Producer app. However, due to the cryptographic logins that ComnVault serves as the central hub for all Opus apps, there was a necessity for an application to manage NFTs and addresses. This led to the creation of the ComnVault extension."
//     },
//     {
//       point: " To gradually develop ComnVault and educate users on its concepts as we gradually introduce updates. "
//     },
//     {
//       point: " The extension maintains the same design as the ComnVault App but offers a streamlined feature set. We've included only the essential actions required for the initial launch to expedite its release. Our plan is to gradually evolve it to its originally envisioned state."
//     }
//   ],
//   problemDesign : [
//     {
//       point: " ComnVault is a decentralized wallet, and within these types of wallets, users are provided with keys/phrases/codes to recover their wallets. Since many of our users may be newcomers to blockchain and crypto, this concept may be entirely new to them."
//     },
//     {
//       point: "  If users forgets their recovery phrase or code, their funds become inaccessible, and will lose their wallet, which can have significant consequences. Users may turn to stakeholders for assistance in resolving these issues and recovering their funds, potentially harming the reputation and community trust."
//     },
//   ],
//   whatWeDo: " We've provided users with both options: the choice to keep their wallet centralized or opt for decentralization. The initial sign-up process is familiar, using email and a magic link. Afterward, users are presented with the opportunity to select their preferred option. ",
//   homeFundamentals: "As mentioned earlier, the extension aims to be the simplest version of the ComnVault mobile app. This is why it primarily focuses on core features, the basic currency options, send/receive functionality, a direct link to the Comn website for coin purchases, a locker for storing NFTs, and transaction history.",
// }

export const about = {
  intro1:"“I create digital experiences that borders on efficiency, aesthetics and functionality. For me projects have a soul”",
  location: "From Lahore, Pakistan",
  interest: "Robotics and Human Psychology are two of my passion, In long term I envision a journey that culminates precisely at the crossroads of these two domains—where technology and human experience harmonize seamlessly.",
  intro2: "“I am an eternal optimist - there’s always a better, more beautiful way and i enjoy looking for it”",
  job: "“My job is to define the important aspects of the brand, demonstrating character and emotions”.",
  intro3:"“I merge aesthetics with business goals”",
  intro4:"“an engineer who aspired to be a designer and a designer who aspired to be an engineer”"

}

export const opusPassportMbl = [
  {
  img: "\\imagesVersion2\\mobile\\opusPassport\\dice.svg",
  title: "Gamification",
  detail: "The playful cat theme adds a fun and gamified element to the login process, encouraging users to collect different Cat NFTs for variety and rewards.",
  },
  {
    img: "\\imagesVersion2\\mobile\\opusPassport\\key.svg",
    title: "Seamless Access",
    detail: "Owning an Opus Passport Gives you access with no need for traditional passwords or email verifications.",
  },
  {
    img: "\\imagesVersion2\\mobile\\opusPassport\\file.svg",
      title: "Ownership & Exclusivity",
      detail: "Owning an Opus Passport provides a sense of exclusivity and ownership, reinforcing the user's connection to the Opus AI platform.",
  },
];

export const opusData = [
  {
    heading: "Token Utility Beyond Login:",
    detail:"Cat NFTs obtained through Opus Passports extend their utility beyond authentication. Users can leverage these tokens within the Opus ecosystem, unlocking additional features, personalized content, and potential rewards, enhancing the overall value proposition.",
  },
  {
    heading: "",
    detail:"Eight distinct collections were designed to align with various bundles and packages, each offering its unique set of perks and benefits.",
  },
  {
    heading: "Educational Outreach",
    detail:"Beyond functionality, Opus Passports contribute to educational outreach. The novel approach of combining blockchain and NFTs serves as an educational tool, introducing users to decentralized concepts in an accessible and practical manner.",
  },
  {
    heading: "Data Security Reinforcement",
    detail:"The integration of blockchain and NFTs reinforces data security. The immutable nature of blockchain ensures the integrity of user credentials, offering a secure and trustworthy environment for Opus Passport holders.",
  },
  
];

export const visionCards = [
  {
     image: "/images/casestudy_6/card1.svg",
     heading: "Decentralized System",
     text: "Create a decentralized system for Opus Ecosystem.",
  },
  {
     image: "/images/casestudy_6/card2.svg",
     heading: "Economic System",
     text: "Build an integrated opus personas economic system",
  },
];

export const images = [
  "/images/casestudy_6/autoSlider1.png",
  "/images/casestudy_6/autoSlider2.png",
  "/images/casestudy_6/autoSlider3.png",
  "/images/casestudy_6/autoSlider4.png",
  "/images/casestudy_6/autoSlider5.png",
  "/images/casestudy_6/autoSlider6.png",
  "/images/casestudy_6/autoSlider7.png",
  "/images/casestudy_6/autoSlider8.png",
  "/images/casestudy_6/autoSlider9.png",
];

export const videos = [
  {
     link: cloudVideos.TA_V2_Flows,
     heading: "Three Primary flows -",
  },
  {
    link: cloudVideos.TA_V2_Flows,
    heading: "Navigation Center -",
  },
  {
    link: cloudVideos.TA_V4_Treasury,
    heading: "Treasury -",
  },
  {
    link: cloudVideos.TA_V5_Send_Receive_Flow,
    heading: "Send / Receive -",
  },
  {
    link: cloudVideos.TA_V6_Minting,
    heading: "Minting Customized Coin -",
  }
];

export const extensionCard = [
  {
     card: "Blockchain Complexity & New Concepts",
     image: "/images/casestudy_6/factor1.svg",
  },
  {
     card: "Initial emphasis on technical users for smoother onboarding.",
     image: "/images/casestudy_6/factor2.svg",
  },
  {
     card: "So Early Technical Users can act as evangelists for others.",
     image: "/images/casestudy_6/factor3.svg",
  },
  {
    card: "Extending to broader audience in later stage",
    image: "/images/casestudy_6/factor4.svg",
 },
];
export const vaultExtensionCard = [
  {
     card: "Initial emphasis on technical users for smoother onboarding.",
     image: "/images/casestudy_6/factor2.svg",
  },
  {
     card: "So Early Technical Users can act as evangelists for others.",
     image: "/images/casestudy_6/factor3.svg",
  },
  {
    card: "Extending to broader audience in later stage",
    image: "/images/casestudy_6/factor4.svg",
 },
];

export const uxData =["ComnCoin", "ComnVault", "Treasury", "Mint Coin", "Mine Coin", "Navigator", "Locker", "Passports", "Transactions", "Transfers"];

export const uxData2 = [
  "Digital crypto money that's not controlled by a single entity.",
  "Your secure digital wallet for managing money.",
  "A place for managing and tracking your digital transactions and personal tokens.",
  "Create your own special digital coins with unique features.",
  "Earn digital coins by completing tasks in the Opus ecosystem.",
  "Learn about digital money and blockchain through an easy-to-use platform.",
  "Store and view your special digital items and exclusive passes.",
  "Digital passes that grant special access to exclusive features.",
  "Keep track of your digital money history.",
  "Send or receive digital coins directly to/from others."
];

export const expertdata1 =[
  "Trust and Transparency",
  "Security",
  "Consensus Mechanism",
  "Adaptability",
  "Scalability",
 ];

 export const viewsQuestion = [
  {
     heading: "Awareness",
     q1: "How familiar are you with the concept of blockchain technology?",
     q2: "Have you encountered any real-world applications of blockchain in your professional or personal life?",
  },
  {
     heading: "Knowledge",
     q1: "What specific aspects of blockchain technology do you feel most confident about?",
     q2: "Are there any challenges or complexities in blockchain that you find difficult to understand?",
  },
  {
     heading: "Trust",
     q1: "How confident are you in the security and trustworthiness of blockchain systems?",
     q2: "What factors contribute to your trust or lack of trust in blockchain technology?",
  },
  {
     heading: "Future Prediction",
     q1: "In your opinion, how will blockchain technology evolve in the next five years?",
     q2: "Can you foresee any challenges or breakthroughs that might shape the future of blockchain?",
  },  
  {
    heading: "Opinions on Opus’s core product",
    q1: "What are your opinions on text to video games?",
    q2: "What are your opinions on selling your games/movies created through opus platform?",
  },
  {
     heading: "Future Prediction for Opus",
     q1: "From your perspective, what impact could Opus's ecosystem have on the future of content creation and monetization?",
     q2: "Do you see decentralized platforms like Opus becoming mainstream in the entertainment industry, and why or why not?",
  },
];

export const question1 =[
  "Could you share your insights on designing a decentralized economic system?",
  "How can we enhance user experience within such a system?",
  "What role do smart contracts play in this context?",
  "How can we ensure inclusivity in our decentralized economic system?",
  "How can we address potential security concerns?",
  "How do you see the role of consensus mechanisms in shaping user experience?",
  "How can we foster user trust in our decentralized economic system?",
  "Finally, any advice on adapting to the evolving landscape of blockchain technology?",
];

export const expertdata2 = [
  "Establish trust through decentralized identity verification and real-time transaction updates.",
  "Implement advanced encryption, multi-factor authentication, and zero-knowledge proofs.",
  "Opt for mechanisms like Delegated Proof-of-Stake for efficiency and fast confirmations.",
  "Stay agile, regularly update the platform, and foster a community-driven approach.",
  "Utilize sharding techniques to enhance system scalability.",
 ];
 export const expertdata3 =[
  "Consensus Mechanism",
  "User Experience (UX)",
  "Smart Contracts",
  "Inclusivity",
 ];

 export const expertdata4 = [
  "Explore Proof-of-Stake for efficiency and scalability.",
  "Clear writing, using familiar terms for easy user understanding.",
  "Design modular and customizable contracts with visual builders.",
  "Prioritize accessibility and provide educational resources.",
 ]; 

 export const pinPoints1 = [
  "Create an Educational Content Hub with simplified content.",
  "Reinforcement Learning through Reward System",
  "Use UX writing for user-friendly explanations.",
  "Design a Gamified Learning Path with a visually engaging progress tracker.",
  "Incorporate a Visual Glossary with pop-up explanations for complex terms.",
  "Implement an Interactive Glossary for quick term clarification.",
  "Offer Guided Webinars explaining complex blockchain concepts.",
  "Provide an 'Ask an Expert' feature for personalized assistance.",
  "Offer informative resources to address understanding gaps.",
 ];

 export const pinPoints2 = [
  "Implement transparency measures within the platform.",
  "Introduce user testimonials and success stories.",
  "Develop a Comprehensive Security Guide for user education.",
  "Regularly update users on security measures through notifications.",
  "Utilize Multi-Factor Authentication for enhanced security.",
  "Implement a Two-Step Verification process with user-friendly UX.",
  "Use blockchain for secure and verified user interactions."
 ];

 export const pinPoints3 = [
  "Provide onboarding options simulating real-world experiences.",
  "Feature success stories of users who started with limited exposure.",
  "Create Interactive Case Studies demonstrating real-world use.",
  "Encourage small-scale activities for confidence-building.",
  "Offer guided tutorials and interactive simulations."
 ];

 export const plusPoints1 = [
  "Introduce Gamified Learning Paths for an engaging experience.",
  "Foster curiosity and excitement through dynamic learning.",
  "Launch a Blockchain Learning Path with tiered skill levels.",
  "Gamify Learning Achievements with badges and rewards.",
  "Launch a Blockchain Learning Path with tiered skill levels.",
  "Gamify Learning Achievements with badges and rewards.",
  "Reward users for achieving milestones in blockchain learning.",
  "Introduce a Weekly Challenge for users to apply their skills.",
  "Introduce a Weekly Challenge for users to apply their skills.",
 ];

 export const plusPoints2 = [
  "Regularly showcase Innovation and Developments in blockchain.",
  "Demonstrate the platform's potential impact on users' ideas.",
  "Host Virtual Events to discuss and showcase industry trends.",
  "Introduce Opus's Research Corner for in-depth industry insights.",
  "Highlight Opus's role in driving innovation and opportunities."
 ];

 export const plusPoints3 = [
  "Integrate Monetization Features within the platform.",
  "Communicate the potential for financial gains effectively.",
  "Expand Monetization Options with diverse revenue streams.",
  "Offer Tutorials on Maximizing Earnings within the ecosystem.",
  "Provide clear pathways for users to monetize their content."
 ];


// export {opusPassportMbl, opusData, visionCards, images, videos, extensionCard, uxData, uxData2};