import styles from './header.module.css';

const Header = ({ header }) => {
    return <div className={styles.header}>
        <h3>{header?.left}</h3>
        <div className={styles.center}>
            <h3>{header?.center}</h3>
            <p style={{ whiteSpace: "pre-line" }}>
                {header?.description1}
            </p>
            <p>{header?.description2}</p>
        </div>
        <h3>{header?.right}</h3>
    </div>
}
export default Header;