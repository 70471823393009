import styles from "./comnVault.module.css";

import {
  visionCards, images, videos, vaultExtensionCard, uxData, uxData2, expertdata1,
  viewsQuestion, question1, expertdata2, expertdata3, expertdata4, pinPoints1, pinPoints2,
  pinPoints3,
  plusPoints1,
  plusPoints2
} from "../data";
import Navbar from "../../components/navbar/navbar";
import { cloudVideos } from "../../videos";
import ComnFooter from "../../components/caseStudiesFooter/comnFooter";

function ComnVault() {
  const userData = {
    text_width: "22.813vw",
    text: "Diving into the crypto sea? Let's build decentralized wonders together."
  };


  return (
    <div className={styles.main_div}>
      <div className={styles.navbar}>
        <Navbar />
      </div>
      {/* ====================Header=============================== */}
      <div className={styles.section2_passport_detail}>
        <h3>ComnVault</h3>
        {/* <div className={styles.detail}>
          <h5>CASE STUDY</h5>
          <p>A Complete Decentralized Platform for Managing ComnCoins</p>
          <h6>Fall 2023</h6>
        </div> */}
        <div className={styles.overview}>
          <h4>OVERVIEW</h4>
          <p>ComnVault is a decentralized application (dApp) that serves as a management platform for the digital currency known as 'ComnCoins’ Created by Opus.ai ComnCoin is a cryptocurrency specifically developed for use within the Opus Ecosystem.</p>
          <h4>Fall 2023 - 2 months</h4>
        </div>
        <div className={styles.role}>
          {/* <h4>Role</h4> */}
          <p>Solo Product Designer involving skills:<br /><br />Researcher, UI Designer, Interaction Designer, Product Manager</p>
        </div>
        {/* <div className={styles.role_detail}>
          <p className={styles.role_detail_para}>Note: Depending on your internet speed, this page may require time to load. The MP4s take a moment to awaken from hibernation. Appreciate your patience!</p>
        </div> */}
      </div>
      <div className={styles.section1_cat}>
        <img src="\images\casestudy_6\vaultHeader.png" alt=""></img>
      </div>
      {/* =======================Company-Vision================= */}
      <div className={styles.section3}>
        <h1 className={styles.section3_heading}>Company’s Vision</h1>
        <div className={styles.section3_cards_wraper}>
          {visionCards.map((data) => (
            <div className={styles.section3_cards}>
              <div className={styles.section3_card_img}>
                <img src={data.image} alt='' />
              </div>
              <h1 className={styles.section3_card_heading}>{data.heading}</h1>
              <p className={styles.section3_card_text}>{data.text}</p>
            </div>
          ))}
        </div>

      </div>
      {/* ======================Product-goals================== */}
      <div className={styles.section4}>
        <div className={styles.section4_content_wraper}>
          <h1>Primary Product Goals</h1>
          <div className={styles.section4_cards_wraper}>
            <div className={styles.section4_cards}>
              <div className={styles.section4_cards_img}>
                <img src="/images/casestudy_6/card3.svg" alt="" />
              </div>
              <p className={styles.section4_cards_text}>Discover potential<br />novelty, to hook users & <br />community.</p>
            </div>
            <div className={styles.section4_cards}>
              <div className={styles.section4_cards_img}>
                <img src="/images/casestudy_6/card4.svg" alt="" />
              </div>
              <p className={styles.section4_cards_text}>Identify UX enhancements in<br />blockchain. Reduce the learning<br /> curve</p>
            </div>
            <div className={styles.section4_cards}>
              <div className={styles.section4_cards_img}>
                <img src="/images/casestudy_6/card5.svg" alt="" />
              </div>
              <p className={styles.section4_cards_text}>To test & refine ideas before<br />Main Product launch.(text-to-<br />video)</p>
            </div>
          </div>
        </div>
      </div>
        <div className={styles.stages_wraper} style={{ marginTop: "3.385vw" }}>
          <h1 className={styles.stages_wraper_heading} style={{ textAlign: "left" }}>Product Stages & Management</h1>
          <p className={styles.stages_wraper_para}>There was a strict deadline of <span> 2 months</span> so I divided the project into stages. Could not fit usability testing with in. So it was shifted in the 2nd core of the design that was going to start after development. </p>
          <h3>Timeline constraints</h3>
          <div className={styles.stages_cycle}>
            <div className={styles.stages_cycle1}></div>
            <div className={styles.stages_cycle1} style={{ background: "none" }}></div>
            <div className={styles.stages_img}>
              <img src="\images\casestudy_6\stagesCircle.svg" alt="" />
            </div>
          </div>
          <div className={styles.stages_data}>
            <p>Research</p>
            <p>Hypothesize</p>
            <p>Design</p>
            <p style={{ background: "none" }}>Test</p>
            <p style={{ background: "none" }}>Update</p>
            <p style={{ background: "none", border: "none" }}>Scale</p>
          </div>
          <div className={styles.stages_data} style={{ justifyContent: "center", border: "none", gap: "14.531vw" }}>
            <p style={{ color: "#00000099", background: "none", border: "none" }}>Stage 1</p>
            <p style={{ color: "#00000099", background: "none", border: "none" }}>Later Stages</p>
          </div>
        </div>
        {/* ====================Chart==================== */}
        <div className={styles.chart_wraper}>
          <div className={styles.chart_wraper_box1}>
            <p>ComnCoin Design</p>
          </div>
          <div className={styles.chart_wraper_img}>
            <img src="\images\casestudy_6\chart1.png" alt="" />
          </div>
          <div className={styles.left_box} >
            <div className={styles.chart_wraper_box1} style={{ height: "2.5vw", width: "6vw", padding: "0.5vw 0 0 0" }}>
              <p style={{ textAlign: "center", height: "3.125vw" }}>Development Handoff</p>
            </div>
            <div className={styles.chart_wraper_box1} style={{ height: "2.5vw", width: "6vw", padding: "0.5vw 0 0 0" }}>
              <p style={{ textAlign: "center", height: "3.125vw" }}>Extension Design</p>
            </div>
            <div className={styles.chart_wraper_box1} style={{ height: "2.5vw", width: "6vw", padding: "0.5vw 0 0 0" }}>
              <p style={{ textAlign: "center", height: "3.125vw" }}>Cat NFT Passports</p>
            </div>
          </div>
        </div>
        {/* =======================Divider=================== */}
        <div className={styles.line}>
          <img src="\images\casestudy_6\line.png" alt="" />
        </div>
      {/* ===============OverViews=================== */}
      <div className={styles.main_div}>
          <div className={styles.overview_wraper}>
            <h1 className={styles.stages_wraper_heading} style={{ textAlign: "left" }}>Technology Insight’s Overview</h1>
            <div className={styles.overview_img}>
              <img src="\images\casestudy_6\technical.png" alt="" />
            </div>

            {/* ================Expert-Views=================== */}
            {/* <div className={styles.overview_wraper}> */}
            <div className={styles.experts}>
              <div>
                <h1 className={styles.stages_wraper_heading} style={{ textAlign: "left" }}>Expert Interviews</h1>
                <div className={styles.experts_data_wraper}>
                  <h3 className={styles.ux_wraper_heading}>Experts: </h3>
                  <div className={styles.expert_data}>
                    <div className={styles.expert_img}>
                      <img src="/images/casestudy_6/adnan.png" alt="" />
                    </div>
                    <div>
                      <h5>Adnan Yunus</h5>
                      <h6 className={styles.expert_data_text}>Embeded Engineer<br />Technologist since 1995</h6>
                    </div>
                  </div>
                  <div className={styles.expert_data}>
                    <div className={styles.expert_img}>
                      <img src="/images/casestudy_6/asra.png" alt="" />
                    </div>
                    <div>
                      <h5>Asra Nadeem</h5>
                      <h6 className={styles.expert_data_text}>Venture Capitalist<br />Helping Finding and Investing in<br />BlockchainStartups/Space<br />
                        @ Draper University since 2016. </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.questions_wraper}>
                <h3 className={styles.ux_wraper_heading} style={{ marginTop: "6.49vw" }}>Questions: </h3>
                <ol style={{ paddingLeft: "1vw", marginTop: "1.5vw" }}>
                  {question1.map((data) => (
                    <li className={styles.expert_data_text} style={{ marginTop: "0.83vw" }}>{data}</li>
                  ))}
                </ol>
              </div>
            </div>
          </div>
          {/* ==================Key-Takeways=================== */}
          <div className={styles.expert_takeway}>
            <h3 className={styles.ux_wraper_heading}>Key Takeaways</h3>
            <h4 className={styles.expert_names}>Adnan</h4>
            <div className={styles.ux_data} style={{ marginTop: "1.042vw" }}>
              <div className={styles.ux_data1} style={{ background: "#202023", height: "19.6vw", width: "24.906vw", boxSizing: 'border-box' }}>
                <h2>Aspect</h2>
                {expertdata1.map((data) => (
                  <p style={{ width: "17.573vw", marginLeft: "2.344vw", fontSize: "0.938vw" }}>{data}</p>
                ))}
              </div>
              <div className={styles.ux_data2} style={{ background: "#202023", width: "48.448vw", height: "19.6vw", marginRight: "2vw", boxSizing: 'border-box' }}>
                <h2>Recommendations</h2>
                {expertdata2.map((data) => (
                  <p style={{ marginLeft: "2.083vw", marginRight: "0", width: "42.46", fontSize: "0.938vw" }}>{data}</p>
                ))}
              </div>
            </div>
            <h4 className={styles.expert_names} style={{ marginTop: "3.125vw" }}>Asra</h4>
            <div className={styles.ux_data} style={{ marginTop: "1.042vw" }}>
              <div className={styles.ux_data1} style={{ background: "#202023", height: "14.135vw", width: "24.906vw", fontSize: "0.938vw" }}>
                <h2>Aspect</h2>
                {expertdata3.map((data) => (
                  <p style={{ width: "17.573vw", marginLeft: "2.344vw", fontSize: "0.938vw" }}>{data}</p>
                ))}
              </div>
              <div className={styles.ux_data2} style={{ background: "#202023", width: "48.448vw", height: "11.875vw", marginRight: "2vw" }}>
                <h2>Recommendations</h2>
                {expertdata4.map((data) => (
                  <p style={{ marginLeft: "2.083vw", marginRight: "0", width: "42.46" }}>{data}</p>
                ))}
              </div>
            </div>
          </div>
          {/* ====================Planning-User======================== */}
          <div className={styles.planning_wraper}>
            <div className={styles.planning_box}>
              <h2>Planning Users & Empathizing</h2>
              <p>Before jumping to the users it was important to understand the state of Blockchain. It’s a new technology with completely new mechanisms.<br />Understanding and empathizing with users having tech background lay the foundation for crafting an inclusive and user-centric design. This strategic approach not only ensures a seamless experience for technical users but also sets the stage for Opus to extend its narrative to a broader audience.</p>
            </div>
          </div>
          {/* ======================factors=================== */}
          <h3 className={styles.ux_wraper_heading} style={{ textAlign: "center", marginTop: "3.081vw" }}>Factors for user restriction:</h3>
          <div className={styles.card_wraper}>
            <div className={styles.card_container}>
              <div className={styles.card_img}>
                <img src='/images/casestudy_6/factor1.svg' alt='' width={"100%"} height={"100%"} />
              </div>
              <p style={{ width: "8.802vw" }}>Blockchain Complexity & New Concepts</p>
            </div>
            {vaultExtensionCard.map((data) => (
              <div className={styles.card_container}>
                <div className={styles.card_img}>
                  <img src={data.image} alt='' width={"100%"} height={"100%"} />
                </div>
                <p>{data.card}</p>
              </div>
            ))}
          </div>

          {/* ================InterViews================== */}
          <div className={styles.interViews}>
            <div>
              <h3 className={styles.ux_wraper_heading}>Interviewees: </h3>
              <div className={styles.expert_data} style={{ gap: "1.88vw" }}>
                <div className={styles.expert_img}>
                  <img src="/images/casestudy_6/layla.png" alt="" />
                </div>
                <div style={{ marginTop: "0.1vw" }}>

                  <h5>Layla Kaleem</h5>
                  <ul style={{ paddingLeft: "1.6vw", marginTop: "0" }}>
                    <li className={styles.expert_data_text}>Marketing & Content Strategist</li>
                    <li className={styles.expert_data_text}>Had written content related to<br />blockchain in gaming sector.</li>
                  </ul>
                  {/* <h6 >Embeded Engineer<br/>Technologist since 1995</h6> */}
                  {/* padding-left: 1.6vw; */}
                  {/* margin-top: 0; */}
                </div>
              </div>
              <div className={styles.expert_data} style={{ gap: "1.88vw" }}>
                <div className={styles.expert_img}>
                  <img src="/images/casestudy_6/mehmood.png" alt="" />
                </div>
                <div style={{ marginTop: "0.1vw" }}>

                  <h5>Mahmood Sadiq</h5>
                  <ul style={{ paddingLeft: "1.6vw", marginTop: "0" }}>
                    <li className={styles.expert_data_text}>Network Administrator</li>
                    <li className={styles.expert_data_text}>No knowledge about blockchain,<br />knows about investment in crypto</li>
                  </ul>
                </div>
              </div>
              <div className={styles.expert_data} style={{ gap: "1.88vw" }}>
                <div className={styles.expert_img}>
                  <img src="/images/casestudy_6/zaid.png" alt="" />
                </div>
                <div style={{ marginTop: "0.1vw" }}>

                  <h5>Zaid Ahmad</h5>
                  <ul style={{ paddingLeft: "1.6vw", marginTop: "0" }}>
                    <li className={styles.expert_data_text}>Software Engineer</li>
                    <li className={styles.expert_data_text}>Has worked in solidity, a framework for<br />building blockchain.</li>
                  </ul>
                  {/* <h6 >Embeded Engineer<br/>Technologist since 1995</h6> */}
                  {/* padding-left: 1.6vw; */}
                  {/* margin-top: 0; */}
                </div>
              </div>
              <div className={styles.expert_data} style={{ gap: "1.88vw" }}>
                <div className={styles.expert_img}>
                  <img src="/images/casestudy_6/hamza.png" alt="" />
                </div>
                <div style={{ marginTop: "0.1vw" }}>

                  <h5>M. Hamza Malilk</h5>
                  <ul style={{ paddingLeft: "1.6vw", marginTop: "0" }}>
                    <li className={styles.expert_data_text}>Machine Learning Engineer & UE Developer</li>
                    <li className={styles.expert_data_text}>A knowledgeable person in the field of<br />engineering.</li>
                  </ul>
                  {/* <h6 >Embeded Engineer<br/>Technologist since 1995</h6> */}
                  {/* padding-left: 1.6vw; */}
                  {/* margin-top: 0; */}
                </div>
              </div>
            </div>
            <div>
              <h3 className={styles.ux_wraper_heading} style={{ marginBottom: "2.083vw" }}>Questions: </h3>
              {viewsQuestion.map((data) => (
                <div style={{ width: "30.76vw" }}>
                  <p className={styles.interview_questions}>{data.heading}</p>
                  <ol style={{ paddingLeft: "1.3vw", marginTop: "0.521vw", marginBottom: "0" }}>
                    <li className={styles.expert_data_text} style={{ fontSize: '0.99vw' }}>{data.q1}</li>
                    <li className={styles.expert_data_text} style={{ fontSize: '0.99vw' }}>{data.q2}</li>
                  </ol>
                </div>
              ))}
            </div>
          </div>
          {/* =====================Empaty-Mapping==================== */}
          <h3 className={styles.ux_wraper_heading} style={{ marginLeft: "14.583vw", marginTop: "9.74vw" }}>Empathy Mapping:</h3>
          <div className={styles.mapping_wraper}>
            <div className={styles.map1}>
              <img src="\images\casestudy_6\map1.svg" alt="" />
            </div>
            <div className={styles.map1}>
              <img src="\images\casestudy_6\map2.svg" alt="" />
            </div>
            <div className={styles.map1}>
              <img src="\images\casestudy_6\map3.svg" alt="" />
            </div>
            <div className={styles.map1}>
              <img src="\images\casestudy_6\map4.svg" alt="" />
            </div>
          </div>
          {/* ===========================Feelings==================== */}
          <div className={styles.feeling_wraper}>
            <img src="\images\casestudy_6\feelings.svg" alt="" />
          </div>
        <div className={styles.main_div}>
          
            {/* ==============Address-points================== */}
            <div className={styles.solution_wraper}>
              <h1 className={styles.ux_wraper_heading} style={{ textAlign: "left" }}>Addressing Pain Points:</h1>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div>
                  <div className={styles.solution_box} style={{ borderRight: "none", textAlign: "center", marginTop: "2.65vw" }}>
                    <p>Lack of clarity/ Understanding</p>
                  </div>
                  <div className={styles.solution_box_data}>
                    {pinPoints1.map((data) => (
                      <div className={styles.box_data_wraper}>
                        <p>{data}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <div className={styles.solution_box} style={{ borderRight: "none", textAlign: "center", marginTop: "2.65vw" }}>
                    <p>Ambiguous Trust and lack of authenticity.</p>
                  </div>
                  <div className={styles.solution_box_data}>
                    {pinPoints2.map((data) => (
                      <div className={styles.box_data_wraper}>
                        <p>{data}</p>
                      </div>
                    ))}
                  </div>
                  <div className={styles.solution_box_data}>
                    <div className={styles.box_data_wraper}>
                      <p>Enhance UX with Clear Security Indicators throughout the platform.</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.solution_box} style={{ marginTop: "2.654vw" }}>
                    <p>Hesitancy to try due to a lack of real-world experiences</p>
                  </div>
                  <div className={styles.solution_box_data}>
                    {pinPoints3.map((data) => (
                      <div className={styles.box_data_wraper}>
                        <p>{data}</p>
                      </div>
                    ))}
                  </div>
                  <div className={styles.solution_box_data}>
                    <div className={styles.box_data_wraper}>
                      <p>Establish a user forum for sharing experiences and insights.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ================Leaving-points================== */}
            <div className={styles.solution_wraper}>
              <h1 className={styles.ux_wraper_heading} style={{ textAlign: "left" }}>Leveraging Plus Points:</h1>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div>
                  <div className={styles.solution_box} style={{ borderRight: "none", textAlign: "center", marginTop: "2.65vw" }}>
                    <p>Curiosity</p>
                  </div>
                  <div className={styles.solution_box_data}>
                    {plusPoints1.map((data) => (
                      <div className={styles.box_data_wraper}>
                        <p>{data}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <div className={styles.solution_box} style={{ borderRight: "none", textAlign: "center", marginTop: "2.65vw" }}>
                    <p>Excited about the development of new possibilities</p>
                  </div>
                  <div className={styles.solution_box_data}>
                    {plusPoints2.map((data) => (
                      <div className={styles.box_data_wraper}>
                        <p>{data}</p>
                      </div>
                    ))}
                  </div>
                  <div className={styles.solution_box_data}>
                    <div className={styles.box_data_wraper}>
                      <p>Establish a Trend Watch section highlighting latest innovations.</p>
                    </div>
                  </div>

                </div>
                <div>
                  <div className={styles.solution_box}>
                    <p style={{ textAlign: 'center', marginTop: '2.5vw' }}>Potential of making a revenue stream</p>
                  </div>
                  <div className={styles.solution_box_data}>
                    {plusPoints2.map((data) => (
                      <div className={styles.box_data_wraper}>
                        <p>{data}</p>
                      </div>
                    ))}
                  </div>
                  <div className={styles.solution_box_data}>
                    <div className={styles.box_data_wraper}>
                      <p>Integrate a Virtual Marketplace for buying and selling assets.</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            {/* ==============Solutions=============== */}
            <div className={styles.solution_wraper}>
              <h1 className={styles.stages_wraper_heading} style={{ textAlign: "left" }}>Solutions:</h1>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div className={styles.solution_box} style={{ borderRight: "none" }}>
                  <p>UX Writing: Using Layman Terms to create a layer on blockchain Jargons.</p>
                </div>
                <div className={styles.solution_box} style={{ borderRight: "none" }}>
                  <p>Extension: Making users comfortable with the platform, with filtered features and then funneling them.</p>
                </div>
                <div className={styles.solution_box}>
                  <p>Reward System: Users will be rewarded for engaging in opus ecosystem leading to reinforcement learning.</p>
                </div>
                <div className={styles.solution_box} style={{ marginTop: "0", borderTop: "none" }}>
                  <p>RPassports NFTs: An onboarding playful initiative, acting as access to personas..</p>
                </div>
                <div className={styles.solution_box} style={{ marginTop: "0", borderTop: "none", borderLeft: "none" }}>
                  <p style={{ margin: "2.888vw auto 0 auto", textAlign: "center" }}>Strong Visual Communication</p>
                </div>
              </div>
            </div>
            {/* ====================UX- writing============== */}
            <div className={styles.ux_wraper}>
              <h3 className={styles.ux_wraper_heading}>UX Writing :</h3>
              <p>Important Highlights,</p>
              <div className={styles.ux_data}>
                <div className={styles.ux_data1} style={{ background: '#202023' }}>
                  <h2>Layman Terms</h2>
                  {uxData.map((data) => (
                    <p>{data}</p>
                  ))}
                </div>
                <div className={styles.ux_data2} style={{ background: '#202023' }}>
                  <h2>Brief</h2>
                  {uxData2.map((data) => (
                    <p>{data}</p>
                  ))}
                </div>
              </div>
            </div>
            {/* ==============Grid-Cards=============== */}
            <div className={styles.grid_cards}>
              <img src="\images\casestudy_6\gridCards.png" alt="" />
            </div>
            {/* =================NFTs=============== */}
            <div className={styles.nft_wraper}>
              <h3>Passport NFTs:</h3>
              <div className={styles.nft_text_wraper}>
                <p>Go through my Cat NFT Case Study</p>
                <div className={styles.nft_img}>
                  <img src="\images\casestudy_6\arrow.svg" alt="" />
                </div>
              </div>
              <h3 style={{ marginTop: "1.719vw" }}>Extension:</h3>
              <div className={styles.nft_text_wraper}>
                <p>Go through my ComnVault Extension Case Study</p>
                <div className={styles.nft_img}>
                  <img src="\images\casestudy_6\arrow.svg" alt="" />
                </div>
              </div>
              <h3 style={{ marginTop: "1.719vw" }}>Decentralized Mobile Application:</h3>
            </div>
          {/* ===================Videos=================== */}
          <div className={styles.main_div}>
            <h3 className={styles.slider_heading} style={{ marginLeft: "16.979vw", marginTop: "9.01vw" }}>Onboarding -</h3>
            <div className={styles.vidoes_container} style={{ width: "81.25vw", margin: "3.125vw auto 0 auto" }}>
              <video autoPlay loop muted playsInline preload='none' poster='' width={'100%'} height={'100%'}>
                <source src={cloudVideos.TA_V1_Onboarding} type="video/mp4"></source>
              </video>
            </div>
            <div className={styles.main_div}>
              <div className={styles.vidoes_wraper}>
                {videos.map((data) => (
                  <>
                    <h3 className={styles.slider_heading} style={{ marginLeft: "7.979vw" }}>{data.heading}</h3>
                    <div className={styles.vidoes_container}>
                      <video autoPlay loop muted playsInline preload='none' poster='' width={'100%'} height={'100%'}>
                        <source src={data.link} type="video/mp4"></source>
                      </video>
                    </div>
                  </>

                ))}
              </div>

              {/* ========================Slider================== */}
              <h3 className={styles.slider_heading}>Design and Feel -</h3>
              <p className={styles.slider_para}>The design language of the application centers on combining mechanical and futuristic elements while maintaining a minimalist approach. This choice aims to achieve a user interface that is visually appealing, highly efficient, easy to navigate and also aligns with company’s message. Elements like clean lines, simple color schemes, and intuitive layouts contribute to a sleek and modern feel, aiding in user’s understanding and learning curve.</p>
              <div className={styles.main_div}>
                <div className={styles.slider_wrapper}>
                  <div className={styles.slider}>
                    {images.map((image, index) => (
                      <img className={styles.slider_img} key={index} src={image} alt="" />
                    ))}
                  </div>
                </div>
                {/* ======================Footer================= */}
                <div className={styles.footer}>
                  <div className={styles.footer_section1}>
                    <h1>Challenges and Learnings</h1>
                    <div className={styles.footer_section1_data}>
                      <div className={styles.footer_section1_data1}>
                        <div className={styles.footer_section1_img}>
                          <img src="\images\casestudy_6\wavesIcon.svg" alt="" />
                        </div>
                        <h3>Maintain a flexible mindset</h3>
                        <p>Navigating through an unfamiliar landscape and to bring out novel ideas  in short period of time was extremely taxing and daunting, it forced me to be flexible, to take steps that can yield the highest output.</p>
                      </div>
                      <div className={styles.footer_section1_data1}>
                        <div className={styles.footer_section1_img}>
                          <img src="\images\casestudy_6\megaphone.svg" alt="" />
                        </div>
                        <h3>Overcame biased opinions</h3>
                        <p>Although I initially shared the same level of knowledge as users, The preconceived notions I built up while learning about the technology proved to be somewhat different after engaging with users. This emphasized the importance of embracing diverse perspectives, even when concepts seem straightforward.</p>
                      </div>
                    </div>
                  </div>
                  <div className={styles.footer_data}>
                    <div className={styles.content1}>
                      <h1 className={styles.content1_text}>Thank You</h1>
                    </div>
                    <div className={styles.content2}>
                      <p>I owe a lot to this startup for being the launchpad for my design journey, and for being the launchpad for so many opportunities I would later encounter. Thank you to my Co-founders, and Managers</p>
                    </div>
                  </div>
                </div>
                <ComnFooter userData={userData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComnVault;
