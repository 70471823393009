import { useNavigate } from 'react-router-dom';
import styles from './landingPage.module.css';
import Nameloading from '../comp/nameloading/nameLoading.js';
import Navbar from './navbarDash.js';
import { cloudVideos } from '../../videos';
import { useEffect, useState } from 'react';


function LandingPage() {
    const navigate = useNavigate();
    const [showNavbar, setShowNavbar] = useState(false);

    useEffect(() => {
        function onPageLoad() {
            // Autoplay videos after preload
            const videoElements = document.querySelectorAll('video');
            videoElements.forEach(video => {
                video.play().catch((error) => {
                    console.error("Error trying to play video:", error);
                });
            });

            let loadBgTimeID = setTimeout(() => {
                document.getElementById("main-wraper").scrollTo(relativePxValue(690), relativePxValue(620));
                clearTimeout(loadBgTimeID);
            }, 10);

            let timeID = setTimeout(() => {
                clearTimeout(timeID);
                document.getElementById("loading").style.display = 'none';
                document.getElementById("main-wraper").scrollTo(relativePxValue(690), relativePxValue(620));
                timeID = setTimeout(() => {
                    clearTimeout(timeID);
                    document.getElementById("main").style.scale = 1;
                }, 1000);
            }, 1000);
        }

        if (document.readyState === "complete") {
            onPageLoad();
        } else {
            window.addEventListener("load", onPageLoad);
        }

        return () => window.removeEventListener("load", onPageLoad);
    }, []);

    function relativePxValue(value) {
        return (value * window.innerWidth) / 390;
    }
    return <div className={styles.landing_main_wrapper} style={{ overflow: showNavbar ? 'hidden' : 'scroll' }} id='main-wraper'>
        <div className={styles.loading} id='loading'
            style={{ position: 'fixed', top: '0', zIndex: '10', display: 'block' }}>
            <Nameloading />
        </div>
        <div className={styles.landing_main} id='main' style={{ scale: '0.5', transitionDuration: '1s' }}>
            <div className={styles.back_img_wrapper}>
                <img className={styles.back_img} src='images/mobile/Landing/back.svg' alt=''></img>
                <img className={styles.top_left} src='images/mobile/Landing/topLeft.png' alt=''></img>
                <img className={styles.top_right} src='images/mobile/Landing/topRight.png' alt=''></img>
                <img className={styles.bottom_left} src='images/mobile/Landing/bottomLeft.png' alt=''></img>
                <img className={styles.bottom_right} src='images/mobile/Landing/bottomRight.png' alt=''></img>
            </div>
            <div className={styles.comn}>
                <img className={styles.comn_rect} src='images/mobile/Landing/comnRect.svg' alt=''></img>
                <h4>ComnVault Extension</h4>
                <div className={styles.comn_video_wrapper}>
                    <img className={styles.comn_img} src='images/mobile/Landing/comn.png' alt=''></img>
                </div>
                <p onClick={() => { navigate('/casestudy_4') }}>View Case Study</p>
            </div>
            <div className={styles.producer}>
                <img className={styles.producer_rect} src='images/mobile/Landing/producerRect.svg' alt=''></img>
                <h4>Producer Application</h4>
                <div className={styles.producer_video_wrapper}>
                    <video poster='images/mobile/Landing/producer.png'
                        loop muted playsInline preload='none' width={'100%'} height={'100%'}>
                        <source src={cloudVideos.ProducerAppHome} type='video/mp4' />
                    </video>
                </div>
                <p onClick={() => { navigate('/casestudy_5') }}>View Case Study</p>
            </div>
            <div className={styles.cat}>
                <img className={styles.cat_rect} src='images/mobile/Landing/catRect.svg' alt=''></img>
                <h4>Cat Nft Passports</h4>
                <div className={styles.cat_video_wrapper}>
                    <img className={styles.cat_img} src='images/mobile/Landing/cat.png' alt='' />
                    <img className={styles.cat_vid} alt=''
                        src={cloudVideos.CatGlasses}
                        autoPlay loop muted playsInline preload='none' width={'100%'} height={'100%'} />
                </div>
                <p onClick={() => { navigate('/casestudy_1') }}>View Case Study</p>
            </div>
            <div className={styles.vault}>
                <img className={styles.vault_rect} src='images/mobile/Landing/vaultRect.svg' alt=''></img>
                <h4>ComnVault Dapp</h4>
                <div className={styles.vault_video_wrapper}>
                    <img className={styles.vault_img} src='images/mobile/Landing/vault.png' alt=''></img>
                </div>
                <p onClick={() => { navigate('/casestudy_6') }}>View Case Study</p>
            </div>
            <div className={styles.token}>
                <img className={styles.token_rect} src='images/mobile/Landing/tokenRect.svg' alt=''></img>
                <h4>Token Sale's Page</h4>
                <div className={styles.token_video_wrapper}>
                    <video poster='images/mobile/Landing/token.png'
                        loop muted playsInline preload='none' width={'100%'} height={'100%'}>
                        <source src={cloudVideos.TokenHome} type='video/mp4' />
                    </video>
                </div>
                <p onClick={() => { navigate('/casestudy_8') }}>View Case Study</p>
            </div>
            <div className={styles.yip}>
                <img className={styles.yip_rect} src='images/mobile/Landing/yipRect.svg' alt=''></img>
                <h4>YIP Website’s Revamp</h4>
                <div className={styles.yip_video_wrapper}>
                    <video poster='images/mobile/Landing/yip.png'
                        loop muted playsInline preload='none' width={'100%'} height={'100%'}>
                        <source src={cloudVideos.YIPHome} type='video/mp4' />
                    </video>
                </div>
                <p onClick={() => { navigate('/casestudy_7') }}>View Case Study</p>
            </div>
            <div className={styles.opus}>
                <img className={styles.opus_rect} src='images/casestudy_3/home_header.png' alt=''></img>
                <h4>i2c Design System Portal</h4>
                <div className={styles.opus_video_wrapper}>
                    <video poster='images/casestudy_3/vid_2.png'
                        loop muted playsInline preload='none' width={'100%'} height={'100%'}>
                        <source src={cloudVideos.Design_Thumbnail} type='video/mp4' />
                    </video>
                </div>
                <p onClick={() => { navigate('/casestudy_3') }} >View Case Study</p>
            </div>
            <div className={styles.text_box1}>
                <p>“I am an engineer who aspired to be a designer and a designer who aspired to be an engineer”</p>
            </div>
            <div className={styles.text_box2}>
                <p>“I am an eternal optimist - there’s always a better, more beautiful way and i enjoy looking for it”</p>
            </div>
            <div className={styles.text_box3}>
                <p>“My job is to define the important aspects of the brand, demonstrating character and emotions”.</p>
            </div>
            <div className={styles.text_box4}>
                <p>“I create a digital experience that borders on efficiency, aesthetics and functionality. For me, projects have a soul”</p>
            </div>
            <div className={styles.text_box5}>
                <p>“I merge aesthetics with business goals”</p>
            </div>
            <div className={styles.text_detail_box1}>
                <div className={styles.top1}>
                    <p>gamer</p>
                    <p>-</p>
                    <p>artist</p>
                    <p>-</p>
                    <p>anime fan</p>
                </div>
                <div className={styles.top2}>
                    <p>athietic</p>
                    <p>-</p>
                    <p>devout muslim</p>
                </div>
                <h1>From Lahore, Pakistan.</h1>
                <p>Robotics and embedded engineering are two of my passion, In long term I envision a journey that culminates precisely at the crossroads of these two domains—where technology and human experience harmonize seamlessly.</p>
                <div className={styles.btn} onClick={() => { navigate('/aboutUs') }}>More About Me</div>
            </div>
            <div className={styles.text_detail_box2}>
                <div className={styles.top1}>
                    <p>divergent</p>
                    <p>-</p>
                    <p>quick</p>
                </div>
                <div className={styles.top2}>
                    <p>professional</p>
                    <p>-</p>
                    <p>non conventional</p>
                </div>
                <h1>My Design Philosophy</h1>
                <p>With standard UX design process as a solid foundation, ensuring attention to user needs and iterative refinement. What sets my approach apart is my artistic perspective that breathes life into every project. In the era of emerging technologies like AI and Blockchain, I break free from conventional design norms, adopting a holistic mindset. Enabling me to navigate the evolving landscape. </p>
                {/* <div className={styles.btn}>My Working Process</div> */}
            </div>
            <div className={styles.center} style={{ transitionDuration: '1s' }}>
                <div className={styles.navbar}><Navbar props={{ background: 'transparent' }} showNavbar={showNavbar} setShowNavbar={setShowNavbar} /></div>
                <div className={styles.tags}>
                    product designer<span>&</span>deep learning engineer
                </div>
                <h1>Digital designer helping Future Techs to make their impact and &nbsp; drop those generic looks.</h1>
                <p>Currently working in Fintech, B2B, SaaS.
                </p>
                <p style={{ marginTop: "4vw" }}>
                    I am both, Artsy + Technical. If you're pushing boundaries and seeking a designer passionate about impact and creativity, let's connect.
                </p>
            </div>
        </div>
    </div>
}
export default LandingPage;