import React from 'react';
import { useNavigate } from 'react-router-dom';
// import Header from '../../comp/header';
import style from './comnExtension.module.css';
import { extensionCard, extensionData } from '../../mblData';
import Footer from '../../comp/footerMbl';
import Navbar from '../../comp/navbar/navbar';
import Slider from '../../comp/slider.js';

function Extension() {
  const navigate = useNavigate();
  // const headerData = {
  //   title: "Design Walkthrough",
  //   heading: "ComnVault",
  //   heading2: "Extension",
  //   duration: "1 week",
  //   image: "/imagesVersion2/mobile/extension/extension.png",
  //   detail: "Getting things out and rolling",
  //   margin: "49.744vw 0.769vw 35.897vw 0.769vw",
  //   title1: "OVERVIEW",
  //   description: "ComnVault Extension is one of the solution that I came up with when building opus’s decentralized platform.",
  //   description1: "Its objective is to enhance user comfort, foster trust, and guide them toward a more sophisticated and feature-rich platform—the ComnVault decentralized application (dApp), without overwhelming them.",
  //   role: "Role",
  //   roleDetail: "UX/UI Designer, Strategist",
  //   width: "98.5%",
  //   height: "100%",
  //   detailSize: '3.846vw',
  //   descriptionSize: '3.067vw'
  // };

  const slider = [
    '/imagesVersion2/mobile/extension/slider1.png',
    '/imagesVersion2/mobile/extension/slider2.png',
    '/imagesVersion2/mobile/extension/slider3.png',
    '/imagesVersion2/mobile/extension/slider4.png',
    '/imagesVersion2/mobile/extension/slider5.png',
    '/imagesVersion2/mobile/extension/slider6.png'
  ];
  const sliderDimensions = {
    height: '82.051vw',
  }

  const autoSlider = [
    '/imagesVersion2/mobile/extension/autoSlider1.png',
    '/imagesVersion2/mobile/extension/autoSlider2.png',
    '/imagesVersion2/mobile/extension/autoSlider3.png',
    '/imagesVersion2/mobile/extension/autoSlider4.png',
    '/imagesVersion2/mobile/extension/autoSlider5.png',
    '/imagesVersion2/mobile/extension/autoSlider6.png',
    '/imagesVersion2/mobile/extension/autoSlider7.png',
    '/imagesVersion2/mobile/extension/autoSlider8.png',
  ];

  return (
    <div className={style.maindiv}>
      {/* ===================Navbar==================== */}
      <div className={style.nav_container}>
        <Navbar />
      </div>
      {/* =======================header=========================== */}
      <div className={style.comn_header}>
        {/* <Header userData={headerData} /> */}
        <h1 className={style.heading}>ComnVault Extension</h1>
      <p className={style.text_design} style={{ paddingTop: "14.615vw"}}>OVERVIEW</p>
      <p className={style.content}>ComnVault Extension is one of the solution that I came up with when 
        building opus’s decentralized platform.<br/><br/>
        Its objective is to enhance user comfort, foster trust, and guide them toward a more sophisticated
        and feature-rich platform—the ComnVault decentralized application (dApp), without overwhelming them.</p>
        <p className={style.text_design} style={{paddingTop: "9.128vw"}}>Fall 2023 - 1 week</p>
        <p className={style.Illustrat}>UX/UI Designer, Strategist</p>
      </div>
      <div className={style.header_img}>
        <img src="/imagesVersion2/mobile/extension/extensionHead.svg" alt=""/>
      </div>
      {/* ===========Research================ */}
      <div className={`${style.container} ${style.research}`}>
        <p className={style.head}>Research</p>
        <div className={style.wraper}>
          <h5>01 THE STUDENT EXPERIENCE</h5>
          <div className={style.heading_wraper}>
            <h1  onClick={() => { navigate('/casestudy_6') }}>Read the ComnVault Case Study</h1>
            <div className={style.arrow}  onClick={() => { navigate('/casestudy_6') }}>
              <img src='/imagesVersion2/mobile/extension/arrow.png' alt='' width={"100%"} height={"100%"} />
            </div>
          </div>
          <p>Extension was designed to onboard non-technical people on Opus’s decentralized platform without overwhelming them. It was kept simple and to the point to get the users comfortable. Once they enter Opus’s ecosystem, they will be introduced with more features, and will be reinforced through rewards to educate themselves and engage further.</p>
        </div>
        <h1 className={style.card_heading}>{extensionData[0].heading}</h1>
        <p className={style.card_detail}>{extensionData[0].detail}</p>
      </div>
      {/* ================cards=================== */}
      <div className={style.card_wraper}>
        {extensionCard.map((data) => (
          <div className={style.card_container} key={data.card}>
            <div className={style.card_img}>
              <img src={data.image} alt='' width={"100%"} height={"100%"} />
            </div>
            <p>{data.card}</p>
          </div>
        ))}
      </div>
      {/* ===========================slider-buttons================ */}
      <div className={style.container}>
        <h1 className={style.card_heading} style={{ padding: '0 7.692vw 0 7.692vw' }}>{extensionData[1].heading}</h1>
        <p className={style.card_detail} style={{ padding: '0 7.692vw 0 7.692vw' }}>{extensionData[1].detail}</p>
        <div className={style.slider_container}>
        <Slider slider={slider} dimensions={sliderDimensions}/>
        </div>
        {/* slider */}
        <h1 className={style.card_heading} style={{ marginLeft: "36.923vw" }}>Filtered Options</h1>
        <p className={style.card_detail} style={{ width: '72.282vw', marginLeft: '15.385vw' }}>To get users comfortable and build connection with the platform total number of options were reduced to just 30% in comparison to ComnVault Mobile Application.</p>
        <p className={style.card_detail} style={{ width: '71.282vw', marginLeft: '15.385vw' }}>Once the user starts feeling a sense of attachment they can be funneled to the application which provides more functionalities that further deepen users connection within opus ecosystem. </p>
      </div>
      <div className={style.detail}>
        <img src='/imagesVersion2/mobile/extension/detail.svg' alt='' width={"100%"} height={"100%"} />
      </div>
      <div className={style.chart}>
        <img src='/imagesVersion2/mobile/extension/chart.svg' alt='' width={"100%"} height={"100%"} />
      </div>
      <h6 className={style.design_text}>Home and the Fundamental do ables -</h6>
      <div className={style.frame_wraper}>
        <div className={style.frame}>
          <img src='/imagesVersion2/mobile/extension/frame1.svg' alt='' width={"100%"} height={"100%"} />
        </div>
        <div className={style.frame}>
          <img src='/imagesVersion2/mobile/extension/locker.svg' alt='' width={"100%"} height={"100%"} />
        </div>
      </div>
      <div className={style.frame_wraper} style={{ marginTop: "1.538vw", paddingBottom: "33.077vw" }}>
        <div className={style.frame}>
          <img src='/imagesVersion2/mobile/extension/frame3.svg' alt='' width={"100%"} height={"100%"} />
        </div>
        <div className={style.frame}>
          <img src='/imagesVersion2/mobile/extension/frame4.svg' alt='' width={"100%"} height={"100%"} />
        </div>
      </div>
      {/* ========================Slider================== */}
      <h6 className={style.design_text} style={{ paddingBottom: "0", paddingTop:'0' }}>Nitty Gritties -</h6>
      <div className={style.slider_wraper}>
        <div className={style.slider}>  
          {autoSlider.map((image, index) => (
            <img className={style.slider_img} key={index} src={image} alt="" />
          ))}
        </div>
      </div>
      <div className={style.extension_footer}>
        <div className={style.next}>
          <img src='/imagesVersion2/mobile/extension/next.svg' alt='' width={"100%"} height={"100%"} />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Extension;