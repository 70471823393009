import React, { useState } from 'react'
import style from '../contactUsButton/contactUs.module.css';
function ContactUs() {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isMouseClicked, setIsMouseClicked] = useState(false);
  const [emailCopied, setEmailCopied] = useState(false);

  let emailTimeout = () => {
    setTimeout(() => { setEmailCopied(false) }, 2000)
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
    setIsMouseClicked(false);
  };
  const hanldeMouseClicked = () => {
    setIsMouseClicked(true);
  }
  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };
  return (

    <div className={isMouseClicked ? style.mainDiv_clicked : style.mainDiv}
      onMouseEnter={handleMouseEnter}
      onClick={hanldeMouseClicked}
      onMouseLeave={handleMouseLeave}
      style={{ backgroundColor: isMouseOver ? isMouseClicked ? "transparent" : "rgba(242, 117, 26, 1)" : "white" }}
    >
      <div style={{ display: isMouseClicked ? 'none' : 'block' }}>
        <div className={style.movingImage}>
          <svg width="8.946vw" height="8.418vw" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M67.3819 118.809C68.2398 118.809 68.9786 119.012 69.5982 119.417C70.2178 119.822 70.6945 120.38 71.0281 121.091C71.3617 121.802 71.5286 122.614 71.5286 123.528C71.5286 124.457 71.3578 125.278 71.0162 125.989C70.6706 126.703 70.19 127.262 69.5744 127.663C68.9548 128.068 68.2319 128.27 67.4057 128.27C66.7623 128.27 66.1824 128.151 65.666 127.913C65.1497 127.675 64.7267 127.341 64.397 126.912C64.0674 126.483 63.8628 125.983 63.7834 125.411H65.1894C65.2967 125.828 65.535 126.197 65.9044 126.519C66.2698 126.844 66.7623 127.007 67.3819 127.007C67.93 127.007 68.4106 126.864 68.8237 126.578C69.2328 126.296 69.5525 125.897 69.7829 125.381C70.0093 124.868 70.1225 124.267 70.1225 123.576C70.1225 122.869 70.0113 122.253 69.7889 121.729C69.5625 121.204 69.2447 120.797 68.8356 120.507C68.4225 120.217 67.938 120.072 67.3819 120.072C67.0165 120.072 66.6848 120.136 66.3869 120.263C66.089 120.39 65.8368 120.573 65.6303 120.811C65.4238 121.049 65.2768 121.335 65.1894 121.669H63.7834C63.8628 121.129 64.0594 120.642 64.3732 120.209C64.683 119.78 65.0941 119.439 65.6065 119.185C66.1149 118.934 66.7067 118.809 67.3819 118.809Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M60.2689 119.232C61.0858 119.355 61.7733 119.658 62.3314 120.14C62.8855 120.621 63.2828 121.238 63.5232 121.989C63.7597 122.74 63.8077 123.582 63.667 124.517C63.5252 125.46 63.2306 126.257 62.7831 126.908C62.3317 127.559 61.7705 128.033 61.0993 128.329C60.4242 128.625 59.6782 128.711 58.8613 128.588C58.0444 128.464 57.3589 128.162 56.8047 127.681C56.2466 127.199 55.8496 126.58 55.6137 125.826C55.3739 125.07 55.3249 124.222 55.4667 123.279C55.6073 122.344 55.903 121.553 56.3538 120.906C56.8007 120.259 57.3617 119.787 58.0368 119.491C58.7079 119.195 59.452 119.109 60.2689 119.232ZM60.081 120.481C59.4604 120.387 58.9259 120.469 58.4775 120.727C58.029 120.984 57.6696 121.358 57.3991 121.847C57.1287 122.337 56.948 122.884 56.857 123.489C56.766 124.094 56.7774 124.672 56.8912 125.223C57.0051 125.775 57.2381 126.242 57.5902 126.624C57.9424 127.007 58.4287 127.245 59.0492 127.339C59.6697 127.432 60.2045 127.348 60.6536 127.087C61.1026 126.825 61.4627 126.448 61.7337 125.954C62.0047 125.461 62.1857 124.912 62.2767 124.307C62.3677 123.702 62.356 123.126 62.2416 122.579C62.1271 122.031 61.8936 121.568 61.5408 121.189C61.1881 120.811 60.7015 120.575 60.081 120.481Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M53.1762 123.601L54.7962 118.34L56.1395 118.755L53.4467 127.501L52.1488 127.1L52.5696 125.734L52.4557 125.698C52.1141 126.079 51.693 126.34 51.1926 126.48C50.691 126.625 50.1101 126.595 49.4498 126.391C48.8578 126.208 48.3772 125.926 48.008 125.546C47.6377 125.17 47.4045 124.699 47.3086 124.133C47.2116 123.571 47.2776 122.918 47.5067 122.174L49.2178 116.616L50.5611 117.031L48.8781 122.498C48.6665 123.185 48.6801 123.775 48.9188 124.269C49.1563 124.766 49.5862 125.111 50.2085 125.303C50.6374 125.436 51.0493 125.461 51.4443 125.38C51.8355 125.297 52.1828 125.107 52.4862 124.81C52.7896 124.513 53.0196 124.11 53.1762 123.601Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M39.2433 122.006L39.7595 120.932L44.0308 122.993L43.5146 124.067L39.2433 122.006ZM41.3198 125.442L40.0535 124.831L43.8323 116.97C44.0044 116.612 44.0816 116.319 44.0639 116.09C44.0408 115.863 43.9617 115.679 43.8264 115.539C43.6858 115.4 43.5225 115.286 43.3365 115.196C43.1969 115.129 43.079 115.081 42.9827 115.052C42.8847 115.027 42.8062 115.007 42.7474 114.992L43.0371 113.729C43.1384 113.738 43.2737 113.764 43.443 113.806C43.6141 113.844 43.8176 113.92 44.0537 114.034C44.4115 114.207 44.7251 114.453 44.9945 114.773C45.2603 115.09 45.4302 115.461 45.5042 115.885C45.5745 116.307 45.4944 116.758 45.2639 117.238L41.3198 125.442Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M39.2528 111.044C39.7317 111.371 40.1048 111.759 40.3722 112.206C40.6374 112.656 40.7674 113.132 40.7624 113.634C40.7551 114.139 40.5839 114.637 40.2487 115.13C39.9537 115.563 39.6293 115.857 39.2755 116.009C38.9195 116.165 38.5463 116.223 38.1559 116.183C37.7655 116.142 37.3725 116.042 36.9767 115.882C36.5754 115.723 36.1836 115.547 35.8014 115.354C35.302 115.099 34.8996 114.889 34.5941 114.724C34.2832 114.559 34.0364 114.47 33.8538 114.456C33.6679 114.44 33.5113 114.525 33.3839 114.712L33.3571 114.752C33.0264 115.238 32.9022 115.706 32.9847 116.157C33.0638 116.605 33.3707 117.012 33.9053 117.377C34.4596 117.756 34.9768 117.931 35.4571 117.903C35.9373 117.875 36.3352 117.767 36.6508 117.579L37.4847 118.725C36.9751 119.051 36.4691 119.229 35.9668 119.262C35.459 119.295 34.9753 119.229 34.5158 119.064C34.0507 118.9 33.6279 118.689 33.2474 118.429C33.0047 118.263 32.7461 118.043 32.4714 117.769C32.1913 117.496 31.9639 117.17 31.7894 116.791C31.6116 116.409 31.5461 115.973 31.5929 115.48C31.6397 114.988 31.8676 114.441 32.2765 113.841L35.6687 108.856L36.8297 109.649L36.1325 110.673L36.1916 110.714C36.382 110.603 36.6328 110.517 36.9438 110.456C37.2549 110.394 37.6076 110.402 38.002 110.479C38.3964 110.556 38.8134 110.744 39.2528 111.044ZM38.3651 111.968C37.9059 111.654 37.4575 111.48 37.0197 111.445C36.5787 111.409 36.1806 111.478 35.8254 111.654C35.467 111.828 35.1806 112.072 34.9661 112.388L34.242 113.451C34.3314 113.426 34.4766 113.446 34.6773 113.511C34.8726 113.577 35.0908 113.666 35.3318 113.777C35.5674 113.89 35.7957 114.003 36.017 114.115C36.2327 114.229 36.4084 114.32 36.5442 114.389C36.8728 114.556 37.1984 114.684 37.5212 114.775C37.8384 114.867 38.1366 114.88 38.4157 114.816C38.6893 114.753 38.9267 114.573 39.1278 114.278C39.4027 113.874 39.4613 113.467 39.3036 113.056C39.1404 112.646 38.8276 112.283 38.3651 111.968Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M32.3666 105.561C32.995 106.146 33.3984 106.797 33.577 107.516C33.7555 108.235 33.7254 108.969 33.4867 109.718C33.2479 110.467 32.8182 111.176 32.1974 111.847C31.5659 112.529 30.8835 113.014 30.1502 113.303C29.4113 113.592 28.6801 113.675 27.9566 113.55C27.2275 113.425 26.5604 113.082 25.9553 112.519C25.484 112.081 25.1402 111.599 24.924 111.072C24.7077 110.546 24.6246 110.013 24.6746 109.474C24.7246 108.935 24.9149 108.428 25.2453 107.955L26.2752 108.912C26.0703 109.291 25.9939 109.724 26.0458 110.212C26.0922 110.699 26.3422 111.154 26.7961 111.576C27.1975 111.949 27.6467 112.172 28.1436 112.243C28.6348 112.315 29.1402 112.24 29.6598 112.018C30.1738 111.796 30.6656 111.431 31.1352 110.924C31.6156 110.406 31.9525 109.878 32.1458 109.342C32.3363 108.803 32.3802 108.288 32.2776 107.797C32.172 107.303 31.9156 106.867 31.5084 106.488C31.2407 106.239 30.9546 106.06 30.6501 105.951C30.3455 105.841 30.0366 105.803 29.7234 105.838C29.4102 105.872 29.1083 105.982 28.8176 106.167L27.7877 105.21C28.213 104.867 28.6876 104.644 29.2116 104.54C29.73 104.436 30.2632 104.466 30.8112 104.628C31.3536 104.791 31.8721 105.102 32.3666 105.561Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M18.7012 104.166L19.6322 103.422L22.5857 107.133L21.6548 107.876L18.7012 104.166ZM19.081 108.163L18.2053 107.063L25.0198 101.619C25.3301 101.371 25.527 101.14 25.6104 100.926C25.6883 100.711 25.6967 100.511 25.6356 100.326C25.569 100.141 25.4714 99.9669 25.3428 99.8053C25.2463 99.6841 25.1609 99.5897 25.0867 99.5219C25.0093 99.4566 24.9474 99.4044 24.901 99.3652L25.7097 98.353C25.797 98.4053 25.9078 98.4871 26.0421 98.5984C26.1795 98.7072 26.3298 98.8642 26.4931 99.0693C26.7405 99.3801 26.9162 99.7379 27.0202 100.143C27.1218 100.545 27.1139 100.953 26.9967 101.367C26.877 101.777 26.6092 102.149 26.1934 102.481L19.081 108.163Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M10.1785 69.6599L11.4856 69.5618L11.8837 74.8852L10.5767 74.9833L10.1785 69.6599Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M23.5114 41.584C23.083 42.3274 22.5388 42.866 21.8787 43.2C21.2187 43.534 20.4976 43.6676 19.7156 43.6007C18.9336 43.5338 18.1472 43.2716 17.3564 42.8142C16.5518 42.3489 15.9271 41.7903 15.4823 41.1383C15.0359 40.481 14.7928 39.7852 14.753 39.0509C14.7117 38.3112 14.8973 37.5835 15.3098 36.8677C15.631 36.3102 16.0237 35.8674 16.4879 35.5393C16.952 35.2113 17.452 35.0118 17.9879 34.941C18.5238 34.8701 19.0592 34.9435 19.5939 35.161L18.8919 36.3792C18.4773 36.2633 18.0386 36.2849 17.5757 36.4438C17.1113 36.5974 16.7244 36.9426 16.415 37.4794C16.1413 37.9543 16.0251 38.4423 16.0664 38.9434C16.1063 39.439 16.2922 39.9159 16.6241 40.3741C16.9546 40.8268 17.4189 41.2261 18.0172 41.5721C18.6292 41.9261 19.2176 42.138 19.7825 42.2078C20.3494 42.2742 20.8605 42.2027 21.3157 41.9934C21.773 41.7807 22.1404 41.4334 22.4181 40.9516C22.6005 40.635 22.7111 40.3159 22.7498 39.9941C22.7885 39.6724 22.7563 39.3624 22.6532 39.0641C22.55 38.7658 22.3758 38.4953 22.1307 38.2526L22.8327 37.0343C23.2606 37.3736 23.5836 37.7876 23.8017 38.2762C24.0184 38.7594 24.1088 39.2866 24.073 39.8578C24.0358 40.4236 23.8486 40.999 23.5114 41.584Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M27.5544 35.7069C27.0399 36.3533 26.4348 36.7977 25.7393 37.0401C25.0462 37.2795 24.3146 37.3152 23.5446 37.1474C22.777 36.9764 22.0239 36.5959 21.2854 36.0059C20.5406 35.4109 19.9981 34.7563 19.6579 34.0422C19.3201 33.3249 19.1906 32.6012 19.2692 31.871C19.3504 31.1377 19.6482 30.4478 20.1628 29.8014C20.6773 29.1551 21.2811 28.7122 21.9742 28.4729C22.6698 28.2304 23.4029 28.1959 24.1735 28.3693C24.9467 28.5396 25.7056 28.9223 26.4504 29.5173C27.1889 30.1074 27.7255 30.7598 28.0602 31.4745C28.3974 32.1862 28.5254 32.9087 28.4442 33.642C28.3655 34.3722 28.0689 35.0605 27.5544 35.7069ZM26.5676 34.9185C26.9585 34.4275 27.1544 33.9231 27.1553 33.4053C27.1563 32.8876 27.0126 32.3889 26.7241 31.9094C26.4357 31.4298 26.0526 30.9992 25.5747 30.6174C25.0968 30.2356 24.5909 29.956 24.057 29.7785C23.5231 29.601 23.0027 29.5691 22.496 29.6828C21.9892 29.7965 21.5404 30.0988 21.1495 30.5898C20.7587 31.0808 20.5644 31.5864 20.5665 32.1067C20.5687 32.627 20.7155 33.1281 21.007 33.6101C21.2985 34.0921 21.6832 34.524 22.1611 34.9058C22.639 35.2876 23.1433 35.566 23.6741 35.741C24.205 35.916 24.7222 35.9454 25.2259 35.8292C25.7295 35.7131 26.1768 35.4095 26.5676 34.9185Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M27.3277 27.3609L31.0684 31.3998L30.0386 32.3571L23.8203 25.643L24.8152 24.7182L25.7868 25.7673L25.8741 25.6861C25.7154 25.1992 25.7003 24.7035 25.8287 24.1991C25.9544 23.6918 26.2703 23.2029 26.7765 22.7324C27.2303 22.3105 27.7138 22.0347 28.2269 21.9048C28.7373 21.772 29.2611 21.8056 29.7983 22.0058C30.3329 22.203 30.8647 22.5872 31.3937 23.1583L35.3449 27.4245L34.315 28.3818L30.4286 24.1856C29.9401 23.6581 29.4228 23.3743 28.8768 23.3342C28.328 23.2912 27.8151 23.4914 27.338 23.9349C27.0093 24.2405 26.7816 24.585 26.6549 24.9685C26.5312 25.3492 26.5219 25.7455 26.6273 26.1574C26.7326 26.5692 26.9661 26.9704 27.3277 27.3609Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M35.6568 16.0636L36.3272 17.0487L32.4111 19.7236L31.7407 18.7385L35.6568 16.0636ZM31.6486 16.1463L32.8096 15.3533L37.7169 22.5641C37.9404 22.8925 38.1555 23.1063 38.3624 23.2055C38.5703 23.2992 38.7687 23.3224 38.9575 23.2752C39.1474 23.2224 39.3277 23.1378 39.4982 23.0213C39.6261 22.934 39.7266 22.8557 39.7997 22.7866C39.8705 22.7141 39.9271 22.6562 39.9696 22.6127L40.9164 23.4956C40.8578 23.579 40.768 23.6836 40.6471 23.8094C40.5284 23.9386 40.3608 24.0771 40.1444 24.225C39.8164 24.449 39.4469 24.5979 39.036 24.6718C38.6283 24.7434 38.2228 24.7053 37.8196 24.5575C37.4196 24.4075 37.0698 24.1124 36.7704 23.6724L31.6486 16.1463Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M45.1323 21.5454C44.61 21.7974 44.0887 21.9277 43.5683 21.9362C43.0463 21.9411 42.5697 21.816 42.1386 21.5609C41.7058 21.3023 41.3603 20.9045 41.1022 20.3675C40.875 19.895 40.7839 19.4671 40.8288 19.0838C40.872 18.6969 41.0088 18.3443 41.2392 18.0259C41.4695 17.7075 41.7527 17.4165 42.0888 17.1529C42.4266 16.8841 42.7749 16.6323 43.1336 16.3975C43.6035 16.0914 43.9863 15.8472 44.282 15.6648C44.5796 15.4771 44.7802 15.3076 44.8837 15.1562C44.9908 15.0031 44.9953 14.8246 44.8972 14.6205L44.8765 14.5776C44.6219 14.0478 44.2791 13.706 43.8482 13.5522C43.4209 13.3968 42.9157 13.4597 42.3326 13.741C41.7281 14.0327 41.3177 14.3938 41.1017 14.8244C40.8856 15.255 40.78 15.6543 40.7851 16.0223L39.3766 16.1727C39.3503 15.5679 39.449 15.0397 39.6725 14.5879C39.8978 14.1307 40.1967 13.744 40.5691 13.4276C40.9433 13.106 41.3379 12.845 41.7528 12.6448C42.0175 12.5171 42.3371 12.4026 42.7115 12.3014C43.0878 12.1948 43.4833 12.1605 43.8982 12.1985C44.3167 12.2348 44.727 12.3962 45.1292 12.6829C45.5314 12.9695 45.8899 13.4404 46.2048 14.0955L48.817 19.5296L47.5506 20.1406L47.0137 19.0237L46.9493 19.0547C46.9495 19.2751 46.8985 19.5357 46.7962 19.8364C46.694 20.1371 46.5109 20.4393 46.2471 20.743C45.9832 21.0467 45.6116 21.3142 45.1323 21.5454ZM44.7782 20.3139C45.2791 20.0722 45.6539 19.7701 45.9026 19.4076C46.155 19.0433 46.294 18.6631 46.3195 18.2671C46.3487 17.8694 46.2807 17.4987 46.1155 17.155L45.558 15.9952C45.5353 16.0855 45.4456 16.2015 45.289 16.3432C45.1342 16.4796 44.9482 16.6245 44.7311 16.7778C44.5157 16.9258 44.3041 17.0676 44.096 17.2032C43.8899 17.3336 43.7231 17.4405 43.5958 17.5239C43.2873 17.7257 43.0132 17.9439 42.7735 18.1786C42.5356 18.4081 42.3747 18.6599 42.2906 18.9342C42.2083 19.2032 42.2447 19.4988 42.3995 19.821C42.6112 20.2613 42.934 20.5157 43.3679 20.5842C43.8037 20.6473 44.2738 20.5572 44.7782 20.3139Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M53.3048 18.3194C52.4851 18.5727 51.7197 18.5972 51.0085 18.3929C50.2972 18.1887 49.6777 17.796 49.1497 17.2151C48.6217 16.6341 48.2233 15.907 47.9545 15.034C47.681 14.1457 47.6028 13.3114 47.72 12.531C47.8397 11.7457 48.1347 11.0705 48.6048 10.5053C49.0776 9.93518 49.7087 9.52815 50.498 9.28423C51.1128 9.09426 51.7019 9.03692 52.2653 9.11223C52.8288 9.18755 53.3311 9.38152 53.7723 9.69416C54.2135 10.0068 54.5562 10.4247 54.8004 10.9479L53.457 11.363C53.2318 10.9961 52.8955 10.7134 52.4479 10.515C52.0029 10.3116 51.4844 10.3014 50.8924 10.4843C50.3687 10.6462 49.9516 10.9247 49.6411 11.32C49.3333 11.7103 49.1452 12.1862 49.0771 12.7477C49.0115 13.3042 49.0805 13.9128 49.2838 14.5733C49.4919 15.249 49.7793 15.8045 50.1461 16.2399C50.5167 16.6742 50.94 16.9694 51.4162 17.1258C51.8962 17.2809 52.4018 17.2764 52.9331 17.1122C53.2822 17.0043 53.5804 16.8457 53.8276 16.6362C54.0748 16.4268 54.262 16.1777 54.3892 15.889C54.5164 15.6002 54.5727 15.2835 54.558 14.9389L55.9014 14.5237C55.9844 15.0635 55.9398 15.5865 55.7674 16.0929C55.5976 16.5944 55.3054 17.0422 54.8906 17.4364C54.4785 17.8257 53.9499 18.12 53.3048 18.3194Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M61.3579 7.15629L61.5352 8.33459L56.8459 9.04269L56.6686 7.86438L61.3579 7.15629ZM57.7091 5.48992L59.0994 5.27998L60.3971 13.9052C60.4562 14.298 60.5574 14.5839 60.7009 14.7631C60.8477 14.9378 61.0164 15.0449 61.2071 15.0843C61.4011 15.1193 61.6003 15.1214 61.8045 15.0905C61.9577 15.0674 62.0822 15.0406 62.178 15.01C62.2732 14.9756 62.3494 14.948 62.4065 14.9273L62.8772 16.1336C62.7883 16.1832 62.662 16.2384 62.4985 16.2993C62.3355 16.3641 62.1244 16.416 61.8652 16.4552C61.4725 16.5145 61.0749 16.4881 60.6724 16.3762C60.2739 16.2636 59.925 16.0532 59.6255 15.7449C59.33 15.4361 59.1427 15.0185 59.0635 14.4922L57.7091 5.48992Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M95.4528 17.0102L94.8849 18.1915L90.077 15.8719L90.6449 14.6906L95.4528 17.0102Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M113.044 42.6289C112.615 41.8856 112.422 41.144 112.463 40.4044C112.504 39.6647 112.75 38.9723 113.198 38.3272C113.647 37.6822 114.267 37.131 115.058 36.6736C115.862 36.2082 116.658 35.9456 117.444 35.8855C118.235 35.827 118.958 35.964 119.613 36.2965C120.273 36.6305 120.809 37.1554 121.222 37.8712C121.543 38.4287 121.729 38.9908 121.781 39.5575C121.832 40.1242 121.755 40.6577 121.548 41.1581C121.341 41.6586 121.01 42.0864 120.555 42.4416L119.853 41.2233C120.16 40.9216 120.361 40.5302 120.455 40.049C120.554 39.5694 120.45 39.0611 120.14 38.5243C119.866 38.0494 119.503 37.7045 119.049 37.4898C118.601 37.2766 118.095 37.1994 117.533 37.2583C116.977 37.3187 116.399 37.5219 115.801 37.868C115.189 38.2219 114.712 38.6265 114.369 39.0818C114.028 39.5404 113.834 40.0196 113.788 40.5192C113.743 41.0223 113.859 41.5147 114.137 41.9965C114.32 42.3131 114.54 42.5686 114.799 42.7631C115.058 42.9575 115.342 43.0846 115.651 43.1442C115.961 43.2039 116.282 43.188 116.614 43.0967L117.316 44.3149C116.809 44.5165 116.289 44.5898 115.758 44.5347C115.232 44.481 114.731 44.2959 114.255 43.9792C113.785 43.664 113.381 43.2139 113.044 42.6289Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M116.089 49.0774C115.788 48.3081 115.706 47.5613 115.845 46.8367C115.985 46.1159 116.32 45.4635 116.85 44.8793C117.382 44.2989 118.088 43.8357 118.968 43.4898C119.855 43.1409 120.692 42.9973 121.48 43.0589C122.269 43.1242 122.96 43.3734 123.552 43.8064C124.145 44.2431 124.592 44.8461 124.894 45.6154C125.195 46.3846 125.276 47.1296 125.136 47.8504C124.997 48.5749 124.66 49.2281 124.125 49.81C123.591 50.3956 122.88 50.8629 121.993 51.2117C121.113 51.5576 120.28 51.6972 119.495 51.6304C118.711 51.5674 118.022 51.3175 117.429 50.8808C116.837 50.4477 116.39 49.8466 116.089 49.0774ZM117.264 48.6152C117.493 49.1995 117.831 49.6214 118.279 49.8809C118.726 50.1404 119.229 50.2648 119.788 50.2542C120.347 50.2435 120.911 50.1263 121.48 49.9025C122.049 49.6787 122.544 49.3795 122.965 49.005C123.385 48.6305 123.673 48.195 123.829 47.6986C123.984 47.2022 123.947 46.6619 123.718 46.0775C123.489 45.4932 123.15 45.072 122.699 44.814C122.248 44.556 121.741 44.433 121.178 44.4451C120.616 44.4572 120.05 44.5751 119.481 44.7989C118.911 45.0228 118.418 45.3212 118.001 45.6943C117.584 46.0673 117.3 46.5013 117.148 46.9963C116.997 47.4912 117.035 48.0309 117.264 48.6152Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M123.424 53.0492L118.058 54.2763L117.745 52.9054L126.666 50.8655L126.968 52.19L125.574 52.5087L125.601 52.6249C126.101 52.7304 126.538 52.9647 126.909 53.3279C127.285 53.6901 127.55 54.2082 127.704 54.882C127.841 55.4861 127.838 56.0431 127.693 56.5529C127.552 57.0617 127.261 57.4991 126.819 57.8651C126.381 58.2301 125.782 58.4994 125.023 58.6729L119.355 59.9691L119.042 58.5982L124.618 57.3233C125.319 57.163 125.823 56.8562 126.131 56.4027C126.443 55.9484 126.527 55.4037 126.382 54.7685C126.283 54.3309 126.099 53.9615 125.83 53.6602C125.563 53.3628 125.225 53.1569 124.816 53.0427C124.407 52.9284 123.943 52.9306 123.424 53.0492Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M129.02 65.9253L127.831 66.0145L127.478 61.2853L128.666 61.1961L129.02 65.9253ZM130.955 62.4103L131.06 63.8124L122.362 64.4654C121.966 64.4952 121.674 64.5749 121.484 64.7046C121.299 64.838 121.18 64.9984 121.126 65.1856C121.076 65.3765 121.059 65.575 121.075 65.7809C121.086 65.9354 121.104 66.0616 121.127 66.1594C121.154 66.2569 121.176 66.335 121.192 66.3935L119.954 66.7732C119.911 66.6808 119.866 66.5508 119.817 66.3832C119.765 66.2158 119.729 66.0015 119.71 65.74C119.68 65.344 119.736 64.9494 119.878 64.5564C120.02 64.1673 120.256 63.8349 120.586 63.5592C120.916 63.2875 121.347 63.1317 121.877 63.0919L130.955 62.4103Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M119.53 71.4024C119.574 70.8241 119.722 70.3074 119.975 69.8525C120.232 69.3978 120.579 69.0474 121.015 68.8014C121.456 68.5556 121.973 68.4551 122.568 68.4997C123.09 68.5389 123.506 68.6737 123.816 68.9041C124.129 69.1347 124.366 69.4293 124.526 69.7879C124.687 70.1465 124.797 70.5371 124.857 70.9598C124.921 71.3868 124.964 71.8143 124.988 72.2423C125.018 72.8021 125.038 73.2557 125.048 73.603C125.061 73.9545 125.107 74.2129 125.187 74.3782C125.266 74.5474 125.418 74.6405 125.644 74.6574L125.691 74.661C126.277 74.705 126.745 74.5788 127.094 74.2824C127.442 73.9899 127.641 73.5208 127.689 72.8752C127.739 72.2058 127.632 71.67 127.367 71.2678C127.102 70.8655 126.81 70.5747 126.488 70.3952L127.063 69.1001C127.6 69.3794 128.008 69.7286 128.287 70.1479C128.57 70.5715 128.755 71.0235 128.843 71.5041C128.934 71.9889 128.963 72.461 128.928 72.9205C128.906 73.2136 128.845 73.5476 128.746 73.9224C128.65 74.3016 128.481 74.6614 128.241 75.0018C128 75.3462 127.655 75.621 127.205 75.8262C126.756 76.0314 126.169 76.1069 125.444 76.0524L119.431 75.601L119.536 74.1989L120.772 74.2917L120.777 74.2204C120.586 74.1105 120.386 73.9361 120.177 73.6974C119.968 73.4586 119.798 73.1491 119.667 72.7689C119.536 72.3886 119.491 71.9331 119.53 71.4024ZM120.774 71.7108C120.732 72.2653 120.806 72.7409 120.996 73.1375C121.185 73.538 121.445 73.8483 121.775 74.0682C122.104 74.2921 122.459 74.4184 122.84 74.4469L124.123 74.5433C124.056 74.4785 124 74.3429 123.956 74.1364C123.915 73.9342 123.883 73.7008 123.859 73.4361C123.839 73.1757 123.822 72.9215 123.809 72.6735C123.799 72.4298 123.79 72.232 123.781 72.08C123.761 71.712 123.709 71.3656 123.626 71.0407C123.547 70.7201 123.409 70.4548 123.214 70.245C123.022 70.0394 122.748 69.9232 122.391 69.8965C121.904 69.8599 121.522 70.0124 121.246 70.3541C120.973 70.7001 120.816 71.1523 120.774 71.7108Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M118.233 80.1049C118.424 79.2684 118.785 78.5933 119.318 78.0794C119.851 77.5656 120.501 77.2253 121.268 77.0585C122.035 76.8916 122.864 76.9101 123.754 77.1137C124.66 77.3209 125.422 77.6702 126.039 78.1617C126.659 78.658 127.097 79.251 127.351 79.9407C127.608 80.6352 127.645 81.3851 127.462 82.1906C127.319 82.818 127.074 83.3569 126.727 83.8072C126.38 84.2575 125.96 84.5956 125.469 84.8214C124.978 85.0472 124.444 85.1351 123.869 85.085L124.182 83.7141C124.612 83.7025 125.025 83.5525 125.421 83.2641C125.819 82.9804 126.087 82.5365 126.225 81.9323C126.347 81.3979 126.314 80.8974 126.127 80.4309C125.943 79.9692 125.625 79.5685 125.173 79.2287C124.724 78.8938 124.162 78.6493 123.488 78.4952C122.799 78.3376 122.174 78.3088 121.614 78.4088C121.053 78.5127 120.585 78.7318 120.212 79.066C119.837 79.4042 119.588 79.8443 119.465 80.3865C119.383 80.7428 119.372 81.0803 119.429 81.3991C119.487 81.7179 119.609 82.0045 119.796 82.259C119.982 82.5135 120.228 82.7205 120.534 82.8801L120.222 84.251C119.713 84.0532 119.282 83.753 118.93 83.3506C118.581 82.9529 118.339 82.4759 118.205 81.9197C118.074 81.3682 118.083 80.7633 118.233 80.1049Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M123.877 92.6641L122.768 92.2281L124.498 87.8124L125.607 88.2484L123.877 92.6641ZM127.143 90.3377L126.63 91.6468L118.513 88.4552C118.143 88.3098 117.845 88.2544 117.618 88.289C117.394 88.3287 117.217 88.4213 117.087 88.5667C116.96 88.7173 116.859 88.8887 116.783 89.081C116.727 89.2253 116.688 89.3465 116.666 89.4448C116.649 89.5445 116.634 89.6243 116.624 89.6841L115.344 89.4882C115.346 89.3864 115.361 89.2494 115.39 89.0773C115.415 88.9037 115.476 88.6949 115.571 88.4508C115.716 88.081 115.938 87.7498 116.236 87.4573C116.532 87.1685 116.889 86.9715 117.305 86.8664C117.72 86.765 118.176 86.8117 118.671 87.0065L127.143 90.3377Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
            <path d="M98.3206 117.33L97.5832 116.247L101.991 113.236L102.729 114.319L98.3206 117.33Z"
              fill={isMouseOver ? "white" : "rgba(92, 112, 131, 1)"} />
          </svg>

        </div>
        <div
          className={style.textDiv}
        >
          <div
            className={isMouseOver ? style.imagePosition3 : style.imagePosition1}
          >
            <img
              alt=""
              src=".\images\contactusButton\arrow.svg"
              width={"100%"}
              height={"100%"}
            />
          </div>



          <div
            className={isMouseOver ? style.imagePosition1 : style.imagePosition2}
          >
            <img
              alt=""
              src=".\images\contactusButton\arrowWhite.svg"
              width={"100%"}
              height={"100%"}
            />
          </div>

        </div>
      </div>
      <div className={`${style.contact_menu} ${isMouseClicked ? style.contact_menu_active : ''}`}
        onClick={() => {
          navigator.clipboard.writeText('abdullahqamar600@gmail.com')
          setEmailCopied(true);
          emailTimeout();
        }}>
        <p>{emailCopied ? 'Email Copied' : 'abdullahqamar600@gmail.com'}</p>
      </div>
      <a href="https://www.linkedin.com/in/abdullah-qamar-618057206/" target="#">
        <div className={`${style.contact_menu} ${isMouseClicked ? style.contact_menu_active1 : ''}`}>
          <p>LinkedIn</p>
        </div>
      </a>
      <a href="https://www.instagram.com/nerdy4202?igsh=dG90d251d3J5YXdh&utm_source=qr" target="#">
        <div className={`${style.contact_menu} ${isMouseClicked ? style.contact_menu_active2 : ''}`}>
          <p>Instagram</p>
        </div>
      </a>
    </div>

  )
}

export default ContactUs
