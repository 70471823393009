import React, { useState } from 'react';
import style from './producerSlider.module.css';

const ProducerSlider = ({ sliderImages, sliderNum }) => {
  const [currentIndex, setCurrentIndex] = useState(sliderNum === 1 ? 3 : 0);
  const maxIndex = sliderImages.length - 1;

  const translateValue = (index) => {
    if (index === 0) {
      return 0
    } else if (index === maxIndex) {
      if (sliderNum === 1) {
        return 17.4383 + (index - 1) * 53.386
      }
      return 36.51 + (index - 1) * 62.031
    } else {
      if (sliderNum === 1) {
        return 35.4325 + (index - 1) * 53.386
      }
      return 48 + (index - 1) * 62.031
    }
  }

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < maxIndex) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <div className={style.slider_main_div}>
      <div className={style.slider_wraper}>
        <div className={style.slider_scroller}
          style={{ transform: `translateX(-${translateValue(currentIndex)}vw)` }}>
          {sliderImages.map((data, id) => (
            <div
              key={id}
              className={style.slider_img}
              style={{ width: `${data.width}`, height: `${data.height}` }}
            >
              <img src={data.img} alt="" width={data.width} height={"100%"} />
            </div>
          ))}
        </div>
      </div>
      <div className={style.button}>
        <button
          className={style.btn1}
          onClick={handlePrevClick}
          disabled={currentIndex === 0}
          style={{ backgroundColor: currentIndex === 0 ? 'rgba(0, 0, 0, 0.3)' : 'black' }}
        >
          <svg width="28" height="28" viewBox="0 0 28 28" fill="white" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.75">
              <path d="M7.41917 18.0595L3.3125 13.9995L7.3725 9.93945" stroke="white" strokeWidth="1.86667" />
              <path d="M24.6888 14H3.96875" stroke="white" strokeWidth="1.86667" />
            </g>
          </svg>
        </button>
        <button
          className={style.btn2}
          onClick={handleNextClick}
          disabled={currentIndex === maxIndex}
          style={{ backgroundColor: currentIndex === maxIndex ? 'rgba(0, 0, 0, 0.3)' : 'black' }}
        >
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.75">
              <path d="M20.5781 9.93945L24.6381 13.9995L20.5781 18.0595" stroke="white" strokeWidth="1.86667" />
              <path d="M3.3125 14H24.0325" opacity="0.75" stroke="white" strokeWidth="1.86667" />
            </g>
          </svg>

        </button>
      </div>
    </div>
  );
};

export default ProducerSlider;
