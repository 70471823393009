import styles from "./opusPassport.module.css";
import Navbar from "../../components/navbar/navbar";
import {cloudVideos} from "../../videos";
import ComnFooter from "../../components/caseStudiesFooter/comnFooter";

function OpusPassport() {
  const userData = {
    text_width: "22.813vw",
    text: "Navigating the NFT cosmos? Reach out; I'll paint your digital masterpiece."
  };

  return (
    <div className={styles.opus_passport_main_div}>
      <div className={styles.navbar}>
        <Navbar/>
      </div>
      <div className={styles.section2_passport_detail}>
        <h3>Opus Passports</h3>
        {/* <div className={styles.detail}>
          <h5>Design Walkthrough</h5>
          <p>Unlocking Playful Possibilities: Cat NFTs</p>
          <h6>1 week</h6>
        </div> */}
        <div className={styles.overview}>
          <h4>OVERVIEW</h4>
          <p>The primary goal of the Opus Passport project was to develop a secure and engaging login mechanism for Opus AI's text-to-video games platform. By using blockchain technology and Non-Fungible Tokens (NFTs), we sought to create a system where users could "own" their login credentials in the form of unique Cat NFTs</p>
        </div>
        <div className={styles.role}>
          <h4>1 month</h4>
          <p>Ux & Illustrator</p>
        </div>
      </div>
      <div className={styles.section1_cat}>
        <img src="images/desktop/opus_passport/cat.png" alt="cat"></img>
        <img  className={styles.cat_vid} alt='' 
        src={cloudVideos.CatGlasses}
          autoPlay loop muted width={'100%'} height={'100%'} />
      </div>
      <div className={styles.section3_user_experience}>
        <div className={styles.section3_inner_div}>
          <div className={styles.title}>User Experience</div>
          <div className={styles.lower_box}>
            <div className={styles.box_1}>
              <img className={styles.vector} src="images/desktop/opus_passport/experience_vec1.svg" alt="gamification"></img>
              <h4>Gamification</h4>
              <p>The playful cat theme adds a fun and gamified element to the login process, encouraging users to collect different Cat NFTs for variety and rewards.</p>
            </div>
            <div className={styles.box_2}>
              <img className={styles.vector} src="images/desktop/opus_passport/experience_vec2.svg" alt="gamification"></img>
              <h4>Seamless Access</h4>
              <p>Owning an Opus Passport Gives you access with no need for traditional passwords or email verification.</p>
            </div>
            <div className={styles.box_3}>
              <img className={styles.vector} src="images/desktop/opus_passport/experience_vec3.svg" alt="gamification"></img>
              <h4>Ownership and Exclusivity</h4>
              <p>Owning an Opus Passport provides a sense of exclusivity and ownership, reinforcing the user's connection to the Opus AI platform.</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.section4_fan}>
        <div className={styles.boxes}>
          <div className={styles.box1}>
            <img src="images/desktop/opus_passport/passport1.png" alt="passport1"></img>
          </div>
          <div className={styles.box2}>
            <h3>Token Utility Beyond Login:</h3>
            <p>Cat NFTs obtained through Opus Passports extend their utility beyond authentication. Users can leverage these tokens within the Opus ecosystem, unlocking additional features, personalized content, and potential rewards, enhancing the overall value proposition.</p>
          </div>
          <div className={styles.box3}>
            <img src="images/desktop/opus_passport/passport2.png" alt="passport2"></img>
          </div>
          <div className={styles.box4}>
            <img src="images/desktop/opus_passport/passport3.png" alt="passport3"></img>
          </div>
        </div>
        <div className={styles.base}>Numa Collection ( Fan )  -  Total Passports Created: 3125</div>
      </div>
      <div className={styles.opus_passport_main_div}>
      <div className={styles.section5_player}>
        <div className={styles.top_text}>Eight distinct collections were designed to align with various bundles and packages, each offering its unique set of perks and benefits.</div>
        <div className={styles.boxes}>
          <div className={styles.box1}>
            <img src="images/desktop/opus_passport/passport4.png" alt="passport4"></img>
          </div>
          <div className={styles.box2}>
            <img src="images/desktop/opus_passport/passport5.png" alt="passport5"></img>
          </div>
          <div className={styles.box3}>
            <img src="images/desktop/opus_passport/passport6.png" alt="passport6"></img>
          </div>
          <div className={styles.box4}>
            <img src="images/desktop/opus_passport/passport7.png" alt="passport7"></img>
          </div>
        </div>
        <div className={styles.base}>Apawllo Collection ( Player + Player X )  -  Total Passports Created: 3125</div>
      </div>
      <div className={styles.section6_producer}>
        <div className={styles.boxes}>
          <div className={styles.box1}>
            <h3>Educational Outreach</h3>
            <p>Beyond functionality, Opus Passports contribute to educational outreach. The novel approach of combining blockchain and NFTs serves as an educational tool, introducing users to decentralized concepts in an accessible and practical manner.</p>
          </div>
          <div className={styles.box2}>
            <img src="images/desktop/opus_passport/passport8.png" alt="passport8"></img>
          </div>
          <div className={styles.box3}>
            <img src="images/desktop/opus_passport/passport9.png" alt="passport9"></img>
          </div>
          <div className={styles.box4}>
            <img src="images/desktop/opus_passport/passport10.png" alt="passport10"></img>
          </div>
        </div>
        <div className={styles.base}>Chubz Collection ( Producer )  -  Total Passports Created: 3125</div>
      </div>
      <div className={styles.section6_producer_2}>
        <div className={styles.boxes}>
          <div className={styles.box1}>
            <img src="images/desktop/opus_passport/passport11.png" alt="passport11"></img>
          </div>
          <div className={styles.box2}>
            <img src="images/desktop/opus_passport/passport12.png" alt="passport12"></img>
          </div>
        </div>
        <div className={styles.base}>Shayru Collection ( Producer )  -  Total Passports Created: 3125</div>
      </div>
      <div className={styles.opus_passport_main_div}>
      <div className={styles.section7_security}>
        <div className={styles.text}>
          <h2>Data Security Reinforcement</h2>
          <p>The integration of blockchain and NFTs reinforces data security. The immutable nature of blockchain ensures the integrity of user credentials, offering a secure and trustworthy environment for Opus Passport holders.</p>
        </div>
        <div className={styles.passport_box}>
          <div className={styles.images}>
            <img src="images/desktop/opus_passport/passport13.png" alt="passport13"></img>
            <img src="images/desktop/opus_passport/passport14.png" alt="passport14"></img>
          </div>
          <div className={styles.base}>Akbar Collection ( Producer )  -  Total Passports Created: 3125</div>
        </div>
        <div className={styles.passport_box}>
          <div className={styles.images}>
            <img src="images/desktop/opus_passport/passport15.png" alt="passport15"></img>
            <img src="images/desktop/opus_passport/passport16.png" alt="passport16"></img>
          </div>
          <div className={styles.base}>Klawss Collection ( Producer X )  -  Total Passports Created: 3125</div>
        </div>
        <div className={styles.passport_box}>
          <div className={styles.images}>
            <img src="images/desktop/opus_passport/passport17.png" alt="passport17"></img>
            <img src="images/desktop/opus_passport/passport18.png" alt="passport18"></img>
          </div>
          <div className={styles.base}>Terraformer Moon Collection  -  Total Passports Created: 2750</div>
        </div>
        <div className={styles.passport_box}>
          <div className={styles.images}>
            <img src="images/desktop/opus_passport/passport19.png" alt="passport19"></img>
            <img src="images/desktop/opus_passport/passport20.png" alt="passport20"></img>
          </div>
          <div className={styles.base}>Terraformer Planet Collection -  Total Passports Created: 550</div>
        </div>
      </div>
      </div>
      <div className={styles.opus_passport_main_div}>
      <div className={styles.footer}>
        <p>Passports stand as a key component within opus decentralized system, it solves multiple problems like security, making the process friendly, reduces frustration and provides education.</p>
      </div>
      <ComnFooter userData={userData}/>
      </div>
      </div>
    </div>
  );
}

export default OpusPassport;
