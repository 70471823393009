import React, { useState } from 'react';
import style from './slider.module.css';

const YourComponent = ({slider, dimensions}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const {height} = dimensions;

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < slider.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <div>
      <div className={style.slider_wraper} style={{height: height}}>
        {slider.map((data, id) => (
          <div
            key={id}
            className={style.slider_img}
            style={{ left: `${id * 100}vw`, transform: `translateX(-${currentIndex * 100}vw)`, height: height}}
          >
            <img src={data} alt="" width={"100%"} height={"100%"} />
          </div>
        ))}
      </div>
      <div className={style.button}>
        <button
          className={style.btn1}
          onClick={handlePrevClick}
          disabled={currentIndex === 0}
          style={{backgroundColor: currentIndex === 0 ? 'rgba(0, 0, 0, 0.3)' : 'black'}}
        >
          <svg width="28" height="28" viewBox="0 0 28 28" fill="white" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.75">
              <path d="M7.41917 18.0595L3.3125 13.9995L7.3725 9.93945" stroke="white" strokeWidth="1.86667" />
              <path d="M24.6888 14H3.96875" stroke="white" strokeWidth="1.86667" />
            </g>
          </svg>
        </button>
        <button
          className={style.btn1}
          onClick={handleNextClick}
          disabled={currentIndex === slider.length - 1}
          style={{backgroundColor: currentIndex === slider.length - 1 ? 'rgba(0, 0, 0, 0.3)' : 'black'}}
        >
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.75">
              <path d="M20.5781 9.93945L24.6381 13.9995L20.5781 18.0595" stroke="white" strokeWidth="1.86667" />
              <path d="M3.3125 14H24.0325" opacity="0.75" stroke="white" strokeWidth="1.86667" />
            </g>
          </svg>

        </button>
      </div>
    </div>
  );
};

export default YourComponent;
