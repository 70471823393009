import styles from "./comnFooter.module.css";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
function ComnFooter(prop) {
  const navigate = useNavigate();
  const { text_width, text } = prop.userData;
  const location = useLocation();
  const currentPathname = location.pathname;
  const [emailCopied, setEmailCopied] = useState(false);
  let emailTimeout = () => {
    setTimeout(() => { setEmailCopied(false) }, 2000)
  };

  return (
    <div className={styles.comn_footer_main_div}>
      <div className={styles.footer_container}>
        {/* ==============Left-Box====================== */}
        <div className={styles.footer_content_box}>
          <p className={styles.footer_content_text} style={{ margin: "5.788vw 0 0 0", width: text_width }}>{text}</p>
        </div>
        {/* ==================Right-Box======================= */}
        <div className={styles.footer_navbar_box}>
          <div className={styles.footer_case}>
            <h4 style={{ marginLeft: "4.896vw" }}>email</h4>
            <p className={styles.box} onClick={() => {
              navigator.clipboard.writeText('abdullahqamar600@gmail.com')
              setEmailCopied(true);
              emailTimeout();
            }}
            ><span
            >{emailCopied ? 'Email Copied' : 'abdullahqamar600@gmail.com'}</span></p>
          </div>
          <div className={styles.footer_case}>
            <h4>case studies</h4>
            <p className={styles.box} style={{
              borderTop: "none",
              background: currentPathname === '/casestudy_3' ? '#F2751A' : '',
              color: currentPathname === '/casestudy_3' ? '#ffff' : ''
            }}
              onClick={() => {
                navigate('/casestudy_3')
              }}><span>DESIGN SYSTEM MANAGEMENT PORTAL</span></p>
          </div>
          <div className={styles.footer_case}>
            <p className={styles.box} style={{
              borderTop: "none", marginLeft: "9.65vw",
              background: currentPathname === '/casestudy_6' ? '#F2751A' : '',
              color: currentPathname === '/casestudy_6' ? '#ffff' : ''
            }}
              onClick={() => {
                navigate('/casestudy_6')
              }}><span>COMNVALUT DAPP</span></p>
          </div>
          <div className={styles.footer_case}>
            <p className={styles.box} style={{
              borderTop: "none", marginLeft: "9.65vw",
              background: currentPathname === '/casestudy_4' ? '#F2751A' : '',
              color: currentPathname === '/casestudy_4' ? '#ffff' : ''
            }}
              onClick={() => {
                navigate('/casestudy_4')
              }}><span>COMNVALUT EXTENSION</span></p>
          </div>
          <div className={styles.footer_case}>
            <p className={styles.box} style={{
              borderTop: "none", marginLeft: "9.65vw",
              background: currentPathname === '/casestudy_1' ? '#F2751A' : '',
              color: currentPathname === '/casestudy_1' ? '#ffff' : ''
            }}
              onClick={() => {
                navigate('/casestudy_1')
              }}><span>OPUS PASSPORT</span></p>
          </div>
          <div className={styles.footer_case}>
            <h4 className={styles.footer_web_heading}>web interfaces</h4>
            <p className={styles.box} style={{
              borderTop: "none", marginLeft: "9.6vw",
              background: currentPathname === '/casestudy_2' ? '#F2751A' : '',
              color: currentPathname === '/casestudy_2' ? '#ffff' : ''
            }}
              onClick={() => {
                navigate('/casestudy_5')
              }}><span>PRODUCER APP</span></p>
          </div>
          <div className={styles.footer_case}>
            <p className={styles.box} style={{
              borderTop: "none", marginLeft: "9.65vw",
              background: currentPathname === '/casestudy_5' ? '#F2751A' : '',
              color: currentPathname === '/casestudy_5' ? '#ffff' : ''
            }}
              onClick={() => {
                navigate('/casestudy_7')
              }}><span>YIP WEBSITE</span></p>
          </div>
          <div className={styles.footer_case}>
            <p className={styles.box} style={{
              borderTop: "none", marginLeft: "9.65vw",
              background: currentPathname === '/casestudy_8' ? '#F2751A' : '',
              color: currentPathname === '/casestudy_8' ? '#ffff' : ''
            }}
              onClick={() => {
                navigate('/casestudy_8')
              }}><span>TOKEN SALES PAGE</span></p>
          </div>
          <div className={styles.footer_case}>
            <h4 style={{ marginLeft: "3.85vw" }}>socials</h4>
            <a href="https://www.linkedin.com/in/abdullah-qamar-618057206/" target="blank">
              <p className={styles.box} style={{ borderTop: "none" }}><span>LINKEDIN</span></p>
            </a>
          </div>
          <div className={styles.footer_case}>
            <a href="https://www.instagram.com/nerdy4202?igsh=dG90d251d3J5YXdh&utm_source=qr" target="blank">
              <p className={styles.box} style={{ borderTop: "none", marginLeft: "9.65vw" }}><span>INSTAGRAM</span></p>
            </a>
          </div>
          <div className={styles.footer_case}>
            <h4 style={{
              marginLeft: "4.35vw",
            }}
            >about</h4>
            <p className={styles.box} style={{
              borderTop: "none",
              background: currentPathname === '/aboutus' ? '#F2751A' : '',
              color: currentPathname === '/aboutus' ? '#fff' : ''
            }}
              onClick={() => {
                navigate('/aboutus')
              }}
            ><span>I HAVE COMPLEX PERSONALITY DISORDER.<span style={{ color: currentPathname === '/aboutus' ? '#E7E7E7' : '#939393' }}> JUST KIDDING</span></span></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComnFooter;
