import style from './yipWebsite.module.css';
import Footer from '../../comp/footerMbl';
import Navbar from '../../comp/navbar/navbar';
import {cloudVideos} from '../../../videos';
function Yip() {
  const cardData = [
    "Cluttered User Interface",
    "Dull and Bad Visual Language",
    "Confusing Hierarchy",
    "Competitive Disadvantage",
    "Hassel of Searching and Comparing",
    "Too Much Text",
    "Tech-Centric Messaging"
  ];
  const card2 = [
    "Strong Point",
    "Existing User Base",
    "Information Availability",
  ];
  const card3 = [
    "Build User Trust",
    "Establish Identity",
    "Increase in inbound calls",
  ];
  const card4 = [
    "Promised Deliverables, and Quality Serving",
    "Simplified Process",
    "Desire for personalized guidance",
  ];
  const card5 = [
    "Cluttered Experience, Leading to Lack of Trust",
    "Strong competition from established players",
  ];
  const card6 = [
    {
      data: "No. of Users",
      time: "236",
    },
    {
      data: "Average Session Time",
      time: "1:52 mins",
    },
    {
      data: "Calls Generated",
      time: <time style={{ color: "red" }}>67</time>,
    },
  ];
  const card7 = [
    {
      data: "No. of Users",
      time: "147",
    },
    {
      data: "Average Session Time",
      time: "4:34 mins",
    },
    {
      data: "Calls Generated",
      time: <time style={{ color: "green" }}>98</time>,
    },
  ];
  const redesignCards = [
    {
      image: "/imagesVersion2/mobile/yip/reCard1.svg",
      title: "Shift to Consultative Model",
      content: "emphasizing expert guidance through personalized phone consultations and built competitive differentiation.",
    },
    {
      image: "/imagesVersion2/mobile/yip/reCard2.svg",
      title: "Strong Identity",
      content: 'from "Search & Compare" to "Consult Us," clearly communicating YIP commitment to problem-solving via phone.',
    },
    {
      image: "/imagesVersion2/mobile/yip/reCard3.svg",
      title: "Clear User Journey",
      content: ' with strategic messaging and a well-defined funnel, guiding users to a primary call-to-action—contact YIP.',
    },
    {
      image: "/imagesVersion2/mobile/yip/reCard4.svg",
      title: "Visual Language",
      content: 'imprinting tech experts through motion design rather than extensive design.',
    },
  ];
  return (
    <div className={style.maindiv} id='scroll'>
      <div className={style.nav_container}>
        <Navbar />
      </div>
      <div>
      <div className={style.header}>
        {/* <Header userData={headerData} /> */}
        <h1 className={style.heading_main}>YIP Website Revamp</h1>
      <p className={style.design_text} style={{ paddingTop: "14.615vw"}}>OVERVIEW</p>
      <p className={style.content}>YIP’s (YoutInternetProvider) is a telecommunication service provider 
        who sells 3rd party internet services through telecommunication. It’s redesign journey aimed to 
        redefine direction, user experience and seo to generate traffic.</p>
        <p className={style.design_text} style={{paddingTop: "5.128vw"}}>Fall 2022- 2 months</p>
        <p className={style.Illustrat}>UX/UI Designer, Brand Identity Designer</p>
      </div>
      </div>

      {/* <p className={style.heading}>YIP Website</p>
      <p className={style.heading} style={{ textAlign: "end", width: "86vw" }}>Revamp</p> */}
      <div className={style.header_video}>
        <video autoPlay loop muted playsInline preload='none' poster ='' width={"100%"} height={"100%"}>
          <source src={cloudVideos.Yip_V1} type="video/mp4"/>
        </video>
      </div>
      <div className={style.data_wraper}>
        <p className={style.text_design}>Evolution of User’s Journey: YIP's Consultancy</p>
        <p className={style.view}>OVERVIEW</p>
        <p className={style.para}>YIP’s (YoutInternetProvider) is a telecommunication service provider wo sells 3rd party internet services through telecommunication. It’s redesign journey aimed to redefine direction, user experience and seo to generate traffic.</p>
        <p className={style.view}>Role</p>
        <p className={style.para}>UX/UI Designer</p>
      </div>
      <div className={style.header_video}>
      <video autoPlay loop muted playsInline preload='none' poster ='' width={"100%"} height={"100%"}>
          <source src={cloudVideos.Yip_V2} type="video/mp4"/>
        </video>
      </div>
      <div className={style.old_data_wraper}>
        <p className={style.view}>Old Design :</p>
        <div className={style.card}>
          <p className={style.card_heading}>Issues</p>
          <div className={style.card_text_wraper}>
            {cardData.map((data) => (
              <p className={style.card_text}>{data}</p>
            ))}
          </div>
        </div>
        <div className={style.card} style={{ marginTop: "0.769vw" }}>
          <p className={style.card_heading}>Strong Point</p>
          <div className={style.card_text_wraper}>
            {card2.map((data) => (
              <p className={style.card_text}>{data}</p>
            ))}
          </div>
        </div>

        <div className={style.img_wraper}>
          <img src='\imagesVersion2\mobile\yip\heading3.svg' alt='' width={"100%"} height={"100%"} />
        </div>
        <p className={style.card_text_design}>User Interactions</p>
        <div className={style.text_container}>
          <p className={style.text_desiging}>Significant Metric</p>
          <p className={style.text_desiging1}>(using Google Analytics)</p>
        </div>
        <div className={style.card}>
          <p className={style.card_heading}>Before Redesign</p>
          <p className={style.text_desiging1}>(last 3 month’s statistics)</p>
          <div className={style.card_text_wraper}>
            {card6.map((data, i) => (
              <div className={style.data_container} style={{ borderBottom: " 0.256vw solid #DCDCDC" }}>
                <p className={style.card_text} style={{ textAlign: "left", borderBottom: "0", width: i === 1 ? "27vw" : "39.487vw" }}>{data.data}</p>
                <p className={`${style.card_text} ${style.card_time}`}  style={{fontSize: '4.615vw'}}>{data.time}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={style.card} style={{ marginTop: "0.769vw" }}>
          <p className={style.card_heading}>After Redesign</p>
          <p className={style.text_desiging1}>(1st month’s statistics)</p>
          <div className={style.card_text_wraper}>
            {card7.map((data, i) => (
              <div className={style.data_container} style={{ borderBottom: " 0.256vw solid #DCDCDC" }}>
                <p className={style.card_text} style={{ textAlign: "left", borderBottom: "0", width: i === 1 ? "27vw" : "39.487vw" }}>{data.data}</p>
                <p className={`${style.card_text} ${style.card_time}`} style={{fontSize: '4.615vw'}}>{data.time}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={style.img_wraper}>
          <img src='\imagesVersion2\mobile\yip\heading4.svg' alt='' width={"100%"} height={"100%"} />
        </div>
        <p className={style.card_text_design} style={{ marginBottom: '17.949vw' }}>Competitors</p>
        <div className={style.card} style={{ padding: "0", border: "none" }}>
          <img src='\imagesVersion2\mobile\yip\markCards.svg' alt='' width={"100%"} height={"100%"} />

        </div>
        <div className={style.swot_analysis_box}>
          <p className={style.card_text_design} style={{ marginBottom: '17.949vw' }}>SWOT Analysis:</p>
          <div className={style.card}>
            <p className={style.card_heading} >Strengths</p>
            <div className={style.card_text_wraper}>
              {/* {card5.map((data)=>( */}
              <>
                <p className={style.card_text}>Telecom expertise</p>
                <p className={style.card_text} style={{ color: "red" }}>No External Strength</p>
              </>
              {/* ))}  */}
            </div>
          </div>
          <div className={style.card} style={{ marginTop: "0.769vw" }}>
            <p className={style.card_heading}>Weaknesses</p>
            <div className={style.card_text_wraper}>
              {card5.map((data) => (
                <p className={style.card_text}>{data}</p>
              ))}
            </div>
          </div>
          <div className={style.card} style={{ marginTop: "0.769vw" }}>
            <p className={style.card_heading}>Opportunities</p>
            <div className={style.card_text_wraper}>
              {/* {card5.map((data)=>( */}
              <>
                <p className={style.card_text} style={{ color: "green" }}>Customer Experience</p>
                <p className={style.card_text} style={{ color: "green" }}>Consultative Service</p>
              </>
              {/* ))}  */}
            </div>
          </div>
          <div className={style.card} style={{ marginTop: "0.769vw" }}>
            <p className={style.card_heading}>Threats</p>
            <div className={style.card_text_wraper}>
              {/* {card5.map((data)=>( */}
              <>
                <p className={style.card_text}>Customer Trust</p>
                <p className={style.card_text} style={{ color: "red" }}>Experienced competitors</p>
              </>
              {/* ))}  */}
            </div>
          </div>
        </div>
        <div className={style.business_goals}>
          <img src='\imagesVersion2\mobile\yip\heading1.svg' alt='' width={"100%"} height={"100%"} />
        </div>
        <div className={style.card}>
          <p className={style.card_heading} style={{ textAlign: "center" }}>Measurable Objectives:</p>
          <div className={style.card_text_wraper}>
            {card3.map((data) => (
              <p className={style.card_text}>{data}</p>
            ))}
          </div>
        </div>
        <div className={style.card} style={{ marginTop: "0.769vw" }}>
          <p className={style.card_heading} style={{ textAlign: "center" }}>User Need Alignment</p>
          <div className={style.card_text_wraper}>
            {card4.map((data) => (
              <p className={style.card_text}>{data}</p>
            ))}
          </div>
        </div>
      </div>
      <div className={style.dark_box}>
        <div className={style.dark_img1}>
          <img src='\imagesVersion2\mobile\yip\darkimg1.svg' alt='' width={"100%"} height={"100%"} />
        </div>
        <h5>Customers/Users Demograph:</h5>
        <div className={style.dark_box2}>
          <h6>Shying</h6>
          <h1>Adults & The Responsible Ones</h1>
          <p>Through previous call’s data I got to know about the audience who are primarily individuals aged 30 to 70 who  shy away from the word of “tech” and value simplicity, personalized assistance, a straightforward internet setup process and those who .</p>
        </div>
        <h6 className={style.flush}>FLUSHING OUT IDEAS</h6>
        <div className={style.dark_img2} style={{ marginTop: "12.308vw" }}>
          <img src='\imagesVersion2\mobile\yip\darkimg2.svg' alt='' width={"100%"} height={"100%"} />
        </div>
        <h5>Brain Storming</h5>
        <div className={style.dark_box2} style={{ paddingTop: "14.615vw", paddingRight: '11vw' }} >
          <h1>Proposed Strategy</h1>
          <p>Proposed shift from self-service to a consultative model, changing its messaging from "Search & Compare" to "Consult Us." Leveraging its telecommunications expertise, I made it evident that the platform is committed to delivering personalized guidance.</p>
        </div>
        <div className={style.dark_box2} style={{ paddingTop: "14.615vw", marginTop: "2.564vw" }}>
          <h1>Key Decisions</h1>
          <p>The central decision was transitioning users from an online self-service model to a phone-based consultancy approach. Users were encouraged to reach out to YIP via a phone call to receive expert guidance and connect with service providers.</p>
        </div>
      </div>
      <div className={style.redesign_wraper}>
        <div className={style.redesign_text}>
          <div className={style.redesign_img}>
            <img src='\imagesVersion2\mobile\yip\redesignlogo.svg' alt='' width={"100%"} height={"100%"} />
          </div>
          <p>Redesign</p>
        </div>
        <h6>These solutions were crafted in response to the issues identified during user interviews/ through on-call customer surveys.</h6>
      </div>
      <div className={style.redesign_cards}>
        {redesignCards.map((card) => (
          <div className={style.redesign_card}>
            <div className={style.re_card_img}>
              <img src={card.image} alt='' width={"100%"} height={"100%"} />
            </div>
            <h6>{card.title}</h6>
            <p>{card.content}</p>
          </div>
        ))}
      </div>
      <div className={style.last_img}>
        <div className={style.last_img_wraper}>
          <img src='\imagesVersion2\mobile\yip\img1.png' alt='' width={"100%"} height={"100%"} />
        </div>
        <div className={style.last_img_wraper}>
          <img src='\imagesVersion2\mobile\yip\img2.png' alt='' width={"100%"} height={"100%"} />
        </div>
        <div className={style.last_img_wraper}>
          <img src='\imagesVersion2\mobile\yip\img3.png' alt='' width={"100%"} height={"100%"} />
        </div>
        <div className={style.last_img_wraper}>
          <img src='\imagesVersion2\mobile\yip\img4.png' alt='' width={"100%"} height={"100%"} />
        </div>
        <div className={style.last_img_wraper}>
          <img src='\imagesVersion2\mobile\yip\img5.png' alt='' width={"100%"} height={"100%"} />
        </div>
        <div className={style.last_img_wraper}>
          <img src='\imagesVersion2\mobile\yip\img6.png' alt='' width={"100%"} height={"100%"} />
        </div>
        <div className={style.last_img_wraper}>
          <img src='\imagesVersion2\mobile\yip\img7.png' alt='' width={"100%"} height={"100%"} />
        </div>
        <div className={style.last_img_wraper}>
          <img src='\imagesVersion2\mobile\yip\img8.png' alt='' width={"100%"} height={"100%"} />
        </div>
        <div className={style.last_img_wraper} style={{height: '36.154vw',width: '97.949vw' ,margin:'auto'}}>
          <img src='\imagesVersion2\mobile\yip\img9.png' alt='' width={"100%"} height={"100%"} />
        </div>
        <div className={style.last_img_wraper} style={{height: '34.359vw',width: '97.949vw' ,margin: '5.128vw auto'}}>
          <img src='\imagesVersion2\mobile\yip\img10.png' alt='' width={"100%"} height={"100%"} />
        </div>
        <div className={style.last_img_wraper}>
          <img src='\imagesVersion2\mobile\yip\img11.png' alt='' width={"100%"} height={"100%"} />
        </div>
        <div className={style.img_cards}>
          <div className={style.last_img_wraper} style={{ height: "103.218vw" }}>
            <img src='\imagesVersion2\mobile\yip\img12.png' alt='' width={"100%"} height={"100%"} />
          </div>
          <div className={style.last_img_wraper} style={{ height: "103.218vw" }}>
            <img src='\imagesVersion2\mobile\yip\img13.png' alt='' width={"100%"} height={"100%"} />
          </div>
        </div>
        <div className={style.img_cards}>
          <div className={style.last_img_wraper} style={{ height: "103.218vw" }}>
            <img src='\imagesVersion2\mobile\yip\img14.png' alt='' width={"100%"} height={"100%"} />
          </div>
          <div className={style.last_img_wraper} style={{ height: "103.218vw" }}>
            <img src='\imagesVersion2\mobile\yip\img15.png' alt='' width={"100%"} height={"100%"} />
          </div>
        </div>
        <div className={style.img_cards}>
          <div className={style.last_img_wraper} style={{ height: "103.218vw" }}>
            <img src='\imagesVersion2\mobile\yip\img16.png' alt='' width={"100%"} height={"100%"} />
          </div>
          <div className={style.last_img_wraper} style={{ height: "103.218vw" }}>
            <img src='\imagesVersion2\mobile\yip\img17.png' alt='' width={"100%"} height={"100%"} />
          </div>
        </div>
        <div className={style.img_cards}>
          <div className={style.last_img_wraper} style={{ height: "103.218vw" }}>
            <img src='\imagesVersion2\mobile\yip\img18.png' alt='' width={"100%"} height={"100%"} />
          </div>
          <div className={style.last_img_wraper} style={{ height: "103.218vw" }}>
            <img src='\imagesVersion2\mobile\yip\img19.png' alt='' width={"100%"} height={"100%"} />
          </div>
        </div>

      </div>
      <div className={style.footer}>
        <Footer />
      </div>
    </div>
  );
}

export default Yip;