import { useState } from 'react';
import Navbar from "../../components/navbar/navbar";
import {cloudVideos} from "../../videos";
import styles from "./opusWebsite.module.css";
import ComnFooter from "../../components/caseStudiesFooter/comnFooter";

function OpusWebsite() {
  const [webDataSelected , setWebDataSelected] = useState(null);
  const userData = {
    text_width: "22.813vw",
    text: "Exploring the decentralized frontier? Let's build the future of dApps. "
  };
  const videoData = [
    {
      videoLink: cloudVideos.Opus_V2_Gamer,
      text: "Gamer Persona - Opus's technology transforms narratives into vibrant, ever-evolving worlds that dynamically shift alongside the story's progression.",
      paraWidth: '48.125vw', 
    },
    {
      videoLink: cloudVideos.Opus_V3_Visual_Artist,
      text: "Artists & Musicians Persona - Through Opus Technology Artists & Musician can give birth to imaginary characters, fantastical environments and bring entire worlds into existence. They can make fantasy real.",
      paraWidth: '51.354vw',
    },
    {
      videoLink: cloudVideos.Opus_V4_Story_Teller,
      text: "Storytellers Persona - Opus is building the future where storytellers no longer are a passive wordslinger, rather an active orchestrator interacting with a live 3D canvas which turns their words into a living breathing world in the blink of an eye.",
      paraWidth: '51.354vw',
    },
    {
      videoLink: cloudVideos.Opus_V5_Performer,
      text: "Performers Persona - Using performance capture app (another app ) Performers can immortalize their likeness as it is today and retain it digitally forever. The likeness can be endlessly used by a producer (in producer app) and you make money whenever it does.",
      paraWidth: '57.5vw',
    },
  ];

  const objectives = [
    {
      heading: "Empowering Personas",
      data:"Targeting distinct personas. Gamers, Performers, Storytellers, and Artists/Musicians, the design aimed to cater to their unique needs, sparking their interest in joining the Opus AI revolution.",
    },
    {
      heading: "Fostering Global Community",
      data:"The design sought to foster a strong and vibrant global community by encouraging user participation, collaboration, and contribution to the ecosystem. This was achieved by clearly communicating through audience categorization on the home page.",
    },
    {
      heading: "Communicate the AIM",
      data:"The website needed to effectively communicate Opus AI's breakthrough technology that turns text into interactive video games, positioning the platform as an industry leader.",
    },
  ];

  return (
    <div className={styles.main_div}>
      <div className={styles.navbar}>
        <Navbar/>
      </div>
            <div className={styles.section2_passport_detail}>
        <h3>Opus’s Website</h3>
        {/* <div className={styles.detail}>
          <h5>Design Walkthrough</h5>
          <p>Revolutionizing Entertainment and Gaming</p>
          <h6>1 month</h6>
        </div> */}
        <div className={styles.overview}>
          <h4>OVERVIEW</h4>
          <p>Opus Ai is a narrative ai, it is a pioneering platform that transforms text into captivating video games. With a mission to shape the future of entertainment, Opus AI is focusing to cultivate a self-sustaining global community that immerses audiences in unparalleled levels of storytelling and engagement.</p>
        </div>
        <div className={styles.role}>
          <h4>Start of 2023 - 2 months</h4>
          <p>Visual & Interaction Designer</p>
        </div>
        {/* <div className={styles.role_detail}>
          <p className={styles.role_detail_para}>Please be patient with loading this page, as the MP4s may take a moment to awaken depending on your internet speed. Thanks for your understanding!</p>
        </div> */}
      </div>
      <div className={styles.header_img}>
        <img src="\images\opuswebsite_2\opusillustration.png" alt="" width={"100%"} height={"100%"}/>
      </div>
      {/* ================video1====================== */}
      <div className={styles.section4} style={{gap: "5.208vw"}}>
        <div className={styles.section4_videos}>
           <video autoPlay loop muted playsInline preload='none' poster ='/images/opuswebsite_2/web1.PNG' width={'100%'} height={'100%'}>
               <source src={cloudVideos.OpusHome} type="video/mp4"></source>
            </video>
        </div>
      </div>
      {/* ====================Objectives========================== */}
      <div className={styles.section3}>
        <div className={styles.section3_inside}>
          <div className={styles.section3_data}>
            <h5>Target</h5>
            <h2 style={{marginTop: "0.853vw"}}>Design Objectives</h2>
            {objectives.map((data, i)=>(
              <div className={styles.object_data_wraper}>
                <div className={styles.object_data}  key={data.heading}
                onClick={()=>{
                  if(webDataSelected !== i) {
                    setWebDataSelected(i)
                  } else {
                    setWebDataSelected(null);
                  }
                  }}
                >
                  <div className={styles.object_icon}>
                    <img src="\images\opuswebsite_2\plusIcon.svg" alt="" width={"100%"} height={"100%"}/>
                  </div>
                  <h6 className={styles.object_heading}>{data.heading}</h6>
                </div>
                  <p className={styles.object_para} style={{height: webDataSelected === i? 'fit-content':'0', overflow:'hidden'}}>{data.data}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
     {/* ================Other videoes====================== */}
     <div className={styles.main_div}>
      <div className={styles.section4} style={{gap: "9.375vw"}}>
        {videoData.map((data)=>(
          <>            
            <p className={styles.section4_video_detail} style={{width: data.paraWidth}}>{data.text}</p>
            <div className={styles.section4_videos}>
              <video autoPlay loop muted playsInline preload='none' poster ='/images/opuswebsite_2/web3.PNG' width={'100%'} height={'100%'}>
               <source src={data.videoLink} type="video/mp4"></source>
              </video>
            </div>
          </>
        ))}
        <p className={styles.section4_video_detail}>More than 12 pages designed - The Design Language used feels broken. The theme breaks at some points while works exceptional at other points, which was intentionally put together. to convey the idea of “different, unique, and change”.</p>
        <div className={styles.section4_videos} style={{height: "63.542vw"}}>
           <img src="\images\opuswebsite_2\pages.png" alt="" width={'100%'} height={'100%'}/>
        </div>
      </div>
      {/* ===========================footer========================== */}
      <div className={styles.footer}>
      <div className={styles.footer_data}>
        <div className={styles.content1}>
          <div className={styles.footer_img}>
            <img src="\images\tokenSale\footer.svg" alt="" width={'100%'} height={'100%'}/>
          </div>
          <h1 className={styles.content1_text}>Let’s Befriend</h1>
        </div>
          <div className={styles.content2}>
           <p>I'm super friendly! Feel free to drop me an email, send a cheerful hello, or connect with me on Insta. Looking forward to hearing from you!</p>
          </div>
          </div>
      </div>
      <ComnFooter userData={userData}/>
    </div>
    </div>
  );
}

export default OpusWebsite;
