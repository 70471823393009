import React from "react";
import styles from "./opusPassport.module.css";
import { opusData, opusPassportMbl } from "../../../casestudies/data";
import Navbar from "../../comp/navbar/navbar";
import {cloudVideos} from "../../../videos";
function OpusPassport() {
  return (
    <div className={styles.main_div}>
      <div className={styles.nav_container}>
        <Navbar/>
      </div>
      <h1 className={styles.heading}>Opus Passports</h1>
      <p className={styles.design_text} style={{ paddingTop: "14.615vw"}}>OVERVIEW</p>
      <p className={styles.content}>The primary goal of the Opus Passport project was to develop a
        secure and engaging login mechanism for Opus AI's text-to-video games platform. By using
        blockchain technology and Non-Fungible Tokens (NFTs), we sought to create a system where users could
        "own" their login credentials in the form of unique Cat NFTs</p>
        <p className={styles.design_text} style={{paddingTop: "5.128vw"}}>1 WEEK</p>
        <p className={styles.Illustrat}>Illustrator</p>
      <div className={styles.header_img}>
        <img src="imagesVersion2/mobile/opusPassport/cat.png" alt=""/>
        <img  className={styles.cat_vid} alt='' src={cloudVideos.CatGlasses} 
          autoPlay loop muted width={'100%'} height={'100%'} />
      </div>
      {/* <p className={styles.text}>Unlocking Playful Possibilities: Cat NFTs</p>
      <div className={styles.design}>
        <p className={styles.design_text}>Design Walkthrough</p>
      </div>
      <p className={styles.role}>Role</p> */}
      <div className={styles.experience}>
        <div className={styles.container}>
          <p className={styles.heading_1} style={{color: "#fff"}}>User Experience</p>
          {opusPassportMbl.map((data) => (
        <div className={styles.box} key={data.title}>
          <div className={styles.image}>
            <img src={data.img} alt="" width={"100%"} height={"100%"}/>
          </div>
          <p className={styles.box_text} style={{color: "#fff"}}>{data.title}</p>
          <p className={styles.box_detail}>{data.detail}</p>
        </div>
      ))}
        </div>
      </div>
      <div className={styles.cat_1}>
                <img
                    alt="" 
                    src=".\imagesVersion2\mobile\opusPassport\cat1.svg"
                    width={"100%"}
                    height={"100%"}
                    />
      </div>
      <h1 className={styles.token}>{opusData[0].heading}</h1>
      <p className={`${styles.token_para} ${styles.token_design}`}>{opusData[0].detail}</p>
      <div className={styles.cat_1} style={{marginTop: "18.974vw"}}>
                <img
                    alt="" 
                    src=".\imagesVersion2\mobile\opusPassport\cat2.svg"
                    width={"100%"}
                    height={"100%"}
                    />
      </div>
      <div className={styles.cat_1} style={{marginTop: "1.282vw"}}>
                <img
                    alt="" 
                    src=".\imagesVersion2\mobile\opusPassport\cat3.svg"
                    width={"100%"}
                    height={"100%"}
                    />
      </div>
      <p className={styles.img_text}>Numa Collection ( Fan ) - Total Passports Created: 3125</p>
      <p className={`${styles.token_para2} ${styles.token_design}`}>{opusData[1].detail}</p>
      {/* 4-images */}
      <div className={styles.img_container}>
            <div className={styles.img_box}>        
                <img
                alt="" 
                src=".\imagesVersion2\mobile\opusPassport\cat8.svg"
                width={"100%"}
                height={"100%"}
                />
            </div>
            <div className={styles.img_box}>        
                <img
                alt="" 
                src=".\imagesVersion2\mobile\opusPassport\cat6.svg"
                width={"100%"}
                height={"100%"}
                />
            </div>
            <div className={styles.img_box}>        
                <img
                alt="" 
                src=".\imagesVersion2\mobile\opusPassport\cat9.svg"
                width={"100%"}
                height={"100%"}
                />
            </div>
            <div className={styles.img_box}>        
                <img
                alt="" 
                src=".\imagesVersion2\mobile\opusPassport\cat7.svg"
                width={"100%"}
                height={"100%"}
                />
            </div>
        </div>
        <p className={`${styles.img_text} ${styles.img_text1}`}>Apawllo Collection ( Player + Player X ) - Total Passports Created: 3125</p>
        <h1 className={styles.token} style={{marginLeft: "11.538vw"}}>{opusData[2].heading}</h1>
      <p className={`${styles.education_para} ${styles.token_design}`}>{opusData[2].detail}</p>
      <div className={styles.cat_1} style={{marginTop: "17.949vw"}}>
                <img
                    alt="" 
                    src=".\imagesVersion2\mobile\opusPassport\supercat.svg"
                    width={"100%"}
                    height={"100%"}
                    />
      </div>
      <div className={styles.img_wraper} style={{marginTop: "0.513vw"}}>
            <div className={styles.img}>        
                <img
                alt="" 
                src=".\imagesVersion2\mobile\opusPassport\king.svg"
                width={"100%"}
                height={"100%"}
                />
            </div>
            <div className={styles.img}>        
                <img
                alt="" 
                src=".\imagesVersion2\mobile\opusPassport\cat10.svg"
                width={"100%"}
                height={"100%"}
                />
            </div>
        </div>
        <p className={styles.img_text}>Chubz Collection ( Producer ) - Total Passports Created: 3125</p>
        {/* 4-images */}
        <div className={styles.img_wraper}>
            <div className={styles.img}>        
                <img
                alt="" 
                src=".\imagesVersion2\mobile\opusPassport\cat4.svg"
                width={"100%"}
                height={"100%"}
                />
            </div>
            <div className={styles.img}>        
                <img
                alt="" 
                src=".\imagesVersion2\mobile\opusPassport\cat5.svg"
                width={"100%"}
                height={"100%"}
                />
            </div>
        </div>
        <p className={styles.img_text}>Shayru Collection ( Producer ) - Total Passports Created: 3125</p>
        <h1 className={styles.token} style={{marginLeft: "11.538vw"}}>{opusData[3].heading}</h1>
      <p className={`${styles.data_para} ${styles.token_design}`}>{opusData[3].detail}</p>
        <div className={styles.cat_1} style={{marginTop: "18.974vw"}}>
                <img
                    alt="" 
                    src=".\imagesVersion2\mobile\opusPassport\member4.svg"
                    width={"100%"}
                    height={"100%"}
                    />
      </div>
      <div className={styles.cat_1} style={{marginTop: "1.282vw"}}>
                <img
                    alt="" 
                    src=".\imagesVersion2\mobile\opusPassport\member3.svg"
                    width={"100%"}
                    height={"100%"}
                    />
      </div>
      <p className={styles.img_text}>Akbar Collection ( Producer ) - Total Passports Created: 3125</p>
      <div className={styles.img_wraper}>
            <div className={styles.img}>        
                <img
                alt="" 
                src=".\imagesVersion2\mobile\opusPassport\member2.svg"
                width={"100%"}
                height={"100%"}
                />
            </div>
            <div className={styles.img}>        
                <img
                alt="" 
                src=".\imagesVersion2\mobile\opusPassport\member1.svg"
                width={"100%"}
                height={"100%"}
                />
            </div>
        </div>
        <p className={styles.img_text}>Klawss Collection ( Producer X )  -  Total Passports Created: 3125</p>
      <div className={styles.img_wraper}>
            <div className={styles.img}>        
                <img
                alt="" 
                src=".\imagesVersion2\mobile\opusPassport\img3.svg"
                width={"100%"}
                height={"100%"}
                />
            </div>
            <div className={styles.img}>        
                <img
                alt="" 
                src=".\imagesVersion2\mobile\opusPassport\win.svg"
                width={"100%"}
                height={"100%"}
                />
            </div>
        </div>
        <p className={styles.img_text}>Terraformer Moon Collection  -  Total Passports Created: 2750</p>
      <div className={styles.img_wraper}>
            <div className={styles.img}>        
                <img
                alt="" 
                src=".\imagesVersion2\mobile\opusPassport\img1.svg"
                width={"100%"}
                height={"100%"}
                />
            </div>
            <div className={styles.img}>        
                <img
                alt="" 
                src=".\imagesVersion2\mobile\opusPassport\img2.svg"
                width={"100%"}
                height={"100%"}
                />
            </div>
        </div>
        <p className={styles.img_text}>Terraformer Planet Collection - Total Passports Created: 550</p>
      <div className={styles.footer}>
        <p>Passports stand as a key component within opus decentralized system, it solves multiple
           problems like security, making the process friendly, reduces frustration and provides education.</p>
      </div>
    </div>
  );
}

export default OpusPassport;
