import styles from "./producerApp.module.css";
import Navbar from "../../components/navbar/navbar";
import ProducerSlider from "./producerSlider";
import { cloudVideos } from "../../videos";
import ComnFooter from "../../components/caseStudiesFooter/comnFooter";

function ProducerApp() {
    const userData = {
        text_width: "20.208vw",
        text: "Craving software sorcery? Drop me a line, let's craft the recipe"
    };
    const redesignCards = [
        {
            image: "/images/casestudy_5/card1.png",
            title: "Conceptualizing",
            content: "Big vision, mapping the biz and user terrain—like treasure hunting with flair.",
        },
        {
            image: "/images/casestudy_5/card2.png",
            title: "First Iteration",
            content: 'Sketching dreams, crafting trials, and setting sail on the sea of testing mischief.',
        },
        {
            image: "/images/casestudy_5/card3.png",
            title: "User Testing",
            content: 'Observing the Grandmas, they think LOL means Lots of Love',
        },
        {
            image: "/images/casestudy_5/card4.png",
            title: "Refinement",
            content: 'Giving our AI a final makeover. Empathizing with no technical. After all it’s their product.',
        },
        {
            image: "/images/casestudy_5/card5.png",
            title: "2nd Iteration",
            content: "Grandmas, spill the tea on the time you went slim mode. We'll watch!",
        },
    ];
    const visionCards = [
        {
            image: "/images/casestudy_5/glassess.png",
            heading: "Grandmas weak eyesight require more visual based data",
            text: "We understood that they are more of visual navigator, reading a lot of text frustrates them.",
        },
        {
            image: "/images/casestudy_5/face.png",
            heading: "Hard time grasping community mode.",
            text: "Non-technical people require gradual learning, one at a time!",
        },
    ];
    const sliderImages = [
        {
            img: 'images/casestudy_5/slider1.png',
            width: '52.865vw',
            height: '31.615vw',
        },
        {
            img: 'images/casestudy_5/slider2.png',
            width: '52.865vw',
            height: '34.688vw',
        },
        {
            img: 'images/casestudy_5/slider3.png',
            width: '52.865vw',
            height: '33.125vw',
        },
        {
            img: 'images/casestudy_5/slider4.png',
            width: '52.865vw',
            height: '33.125vw',
        },
        {
            img: 'images/casestudy_5/slider5.png',
            width: '52.865vw',
            height: '33.125vw',
        },

        {
            img: 'images/casestudy_5/slider6.png',
            width: '52.865vw',
            height: '31.563vw',
        },
        {
            img: 'images/casestudy_5/slider7.png',
            width: '52.865vw',
            height: '33.125vw',
        },
        {
            img: 'images/casestudy_5/slider8.png',
            width: '52.865vw',
            height: '33.125vw',
        },
        {
            img: 'images/casestudy_5/slider9.png',
            width: '52.865vw',
            height: '33.125vw',
        }
    ];
    const slider2Images = [
        {
            img: 'images/casestudy_5/slider2_1.png',
            width: '61.51vw',
            height: '34.583vw',
        },
        {
            img: 'images/casestudy_5/slider2_2.png',
            width: '61.51vw',
            height: '34.583vw',
        },
        {
            img: 'images/casestudy_5/slider2_3.png',
            width: '61.51vw',
            height: '34.583vw',
        },
        {
            img: 'images/casestudy_5/slider2_4.png',
            width: '61.51vw',
            height: '34.583vw',
        },
        {
            img: 'images/casestudy_5/slider2_5.png',
            width: '61.51vw',
            height: '34.583vw',
        },
        {
            img: 'images/casestudy_5/slider2_6.png',
            width: '61.51vw',
            height: '34.583vw',
        },
        {
            img: 'images/casestudy_5/slider2_7.png',
            width: '61.51vw',
            height: '34.583vw',
        },
        {
            img: 'images/casestudy_5/slider2_8.png',
            width: '61.51vw',
            height: '34.583vw',
        },
        {
            img: 'images/casestudy_5/slider2_9.png',
            width: '61.51vw',
            height: '34.583vw',
        },
        {
            img: 'images/casestudy_5/slider2_10.png',
            width: '61.51vw',
            height: '34.583vw',
        },
        {
            img: 'images/casestudy_5/slider2_11.png',
            width: '61.51vw',
            height: '34.583vw',
        },
        {
            img: 'images/casestudy_5/slider2_12.png',
            width: '61.51vw',
            height: '34.583vw',
        },
        {
            img: 'images/casestudy_5/slider2_13.png',
            width: '61.51vw',
            height: '34.583vw',
        },
        {
            img: 'images/casestudy_5/slider2_14.png',
            width: '61.51vw',
            height: '34.583vw',
        },
        {
            img: 'images/casestudy_5/slider2_15.png',
            width: '61.51vw',
            height: '34.583vw',
        },
        {
            img: 'images/casestudy_5/slider2_16.png',
            width: '61.51vw',
            height: '34.583vw',
        },
        {
            img: 'images/casestudy_5/slider2_17.png',
            width: '61.51vw',
            height: '34.583vw',
        },
        {
            img: 'images/casestudy_5/slider2_18.png',
            width: '61.51vw',
            height: '34.583vw',
        }
    ];

    return (
        <div className={styles.producer_main_div_wraper} id="scroll">
            <div className={styles.navbar}>
                <Navbar />
            </div>
            {/* ============Header================= */}
            <div className={styles.section1_passport_detail}>
                <h3>Producer Application</h3>
                <div className={styles.divdier}>
                    <img src="/images/casestudy_5/divider.png" alt="" />
                </div>
                <div className={styles.detail}>
                    {/* <h5>CASE STUDY</h5> */}
                    <h4>OVERVIEW</h4>
                    {/* <h6>Fall 2023</h6> */}
                </div>
                <div className={styles.overview}>
                    <p>Producer Persona, an AI desktop software, turns text into interactive videos. What sets it apart? This AI has a fervor for stories, fiction, and the limitless world of imagination.</p>
                    <h4>Fall 2023 - 3 months</h4>
                </div>
                <div className={styles.role_detail}>
                    <p className={styles.role_detail_para}>NOTE: DEPENDING ON YOUR INTERNET SPEED, THIS PAGE MAY NEED TIME TO LOAD. THE MP4s TAKE A MOMENT TO COME OUT OF HIBERNATION ;)</p>
                </div>
            </div>
            <div className={styles.section3_user_experience}>
                <div className={styles.section3_inner_div}>
                    <div className={styles.title}>Making storytelling fun and engaging for all</div>
                    <p className={styles.content}>simplest interface possible designed to cater for Grandmas,those without technical expertise now will have the power to generate the unthinkable just using literary text.</p>
                    <div className={styles.header_text_img}>
                        <img src="/images/casestudy_5/frame1.png" alt="" />
                    </div>
                    <div className={styles.header_text_img} style={{ left: "67.055vw", top: "-6.907vw" }}>
                        <img src="/images/casestudy_5/frame2.png" alt="" />
                    </div>
                    <div className={styles.header_text_img} style={{ left: "77.894vw", top: "7.4vw" }}>
                        <img src="/images/casestudy_5/frame3.png" alt="" />
                    </div>
                    <div className={styles.header_text_img} style={{ left: "63.98vw", top: "18.5vw" }}>
                        <img src="/images/casestudy_5/frame4.png" alt="" />
                    </div>
                    <div className={styles.header_text_img} style={{ left: "13.532vw", top: "14.141vw" }}>
                        <img src="/images/casestudy_5/frame5.png" alt="" />
                    </div>

                </div>
            </div>
            {/* <div className={styles.section4_content}>
                <p className={styles.section4_content_para}>Opus’s virtuosos are stretching the AI’s limited capabilities for entertainment that’s typically done in more powerful apps.</p>
                <p className={styles.section4_content_detail} style={{ width: "41.602vw", margin: "1.563vw auto 0 auto" }}>Taking charge in 3d and gaming, incentivizing users to write and foster a decentralized entertainment ecosystem leveraging Blockchain & AI</p>
                <div className={styles.section4_content2}>
                    <p className={styles.section4_content_detail} style={{ lineHeight: "1.563vw", width: "20.365vw", textAlign: "unset", marginTop: "2.552vw" }}>The ideas are going to be easier to express than ever. Regardless of the sector and industry, humans are going to express their imagination that will open up new door. </p>
                    <div className={styles.section4_content_box2}>
                        <p className={styles.section4_content_detail} style={{ textAlign: "unset" }}>“In the tapestry of humanity, each individual is a unique thread, and storytelling is the art of weaving those threads into a masterpiece of collective experience."</p>
                    </div>
                </div>
            </div> */}
            <div className={styles.divdier} style={{ marginTop: "8.229vw" }}>
                <img src="/images/casestudy_5/divider2.png" alt="" />
            </div>
            <div className={styles.section1_passport_detail} style={{ paddingTop: "7.324vw" }}>
                {/* <div className={styles.detail}>
                    <h5>START OF JOURNEY</h5>
                    <h4 style={{ marginRight: "42.496vw" }}>GOALS</h4>
                    <h6>2 months</h6>
                </div> */}
                <div className={styles.overview}>
                    <h4>Goals</h4>
                    <p>To make storytelling fun and accessible to all.<br /><br /> Foster a governance system, and build initial components of a self sustaining system that gets it rolling</p>
                </div>
                <div className={styles.detail} style={{ marginTop: "1.302vw" }}>
                    {/* <h5>START OF JOURNEY</h5> */}
                    <h4>ROLE - TEAM OF 2 DESIGNER </h4>
                    {/* <h6>2 months</h6> */}
                </div>
                <div className={styles.overview}>
                    <p>Product Designer involving skills:<br />Product Thinking, User Research, Interaction Design, Visual Design</p>
                </div>
            </div>

            <div className={styles.section5_card_wraper}>
                <h4>Product Journey</h4>
                <h1>Think AI, think simplicity – it’s practically its middle name.</h1>
                <div className={styles.card_wraper}>
                    {redesignCards.map((data) => (
                        <div className={styles.card_container}>
                            <div className={styles.card_img}>
                                <img src={data.image} alt='' width={"100%"} height={"100%"} />
                            </div>
                            <p>{data.content}</p>
                        </div>
                    ))}
                </div>
                <div className={styles.section5_card_wraper_img}>
                    <img src="/images/casestudy_5/card1.png" alt="" />
                </div>
                {/* <h4>Product Journey</h4> */}
                <h1 style={{ marginTop: "1.563vw", width: "49.167vw" }}>A lot to conceptualize on, Company’s Vision, Expectations, User’s Preferences & Flows</h1>
                <h6>Vision was to create Text-to-video interface, Governance & Self Sustaining System, Sale Book NFTs, and Gamify it.</h6>
            </div>
            <div className={styles.ground_work}>
                <div>
                    <div className={styles.pencil_img}>
                        <img src="/images/casestudy_5/card2.png" alt="" />
                    </div>
                    <h1 className={styles.ground_work_heading}>Laying the groundwork</h1>
                    <p className={styles.ground_work_para}>First Iteration had two modes. In Editor mode, users crafted stories; Community mode housed governance, counseling, and the marketplace. The aim was to distinctively organize and delineate features.</p>
                </div>
                <div className={styles.chart_img2}>
                    <img src="/images/casestudy_5/chart2.png" alt="" />
                </div>
            </div>
            {/* ================Slider============= */}
            <div className={styles.slider1_wraper}>
                <ProducerSlider sliderImages={sliderImages} sliderNum={1} />
            </div>
            {/* ================Slider============= */}
            <div className={styles.producer_main_div}>
                <div className={styles.section5_card_wraper} style={{ marginTop: "0", paddingTop: "8.854vw", width: "46.667vw" }}>
                    <h1 style={{ marginTop: "1.563vw", textAlign: "left" }}>Prototype en route to Grandmas: because if they get it, everyone will!</h1>
                    <h6 style={{ textAlign: "left", margin: "1.927vw 0 0 0", width: "100%" }}>We took 'working from home' to the next level by turning it into 'testing from home.' Because let's face it, the best insights come when you're in your PJs..</h6>
                </div>
            </div>
            <div className={styles.producer_main_div}>
                <div className={styles.user_wraper}>
                    <div className={styles.pencil_img}>
                        <img src="/images/casestudy_5/card3.png" alt="" />
                    </div>
                    <p className={styles.user_sub_heading}>Key Insights from Testings</p>
                    <p className={styles.user_sub_heading}></p>
                    <h1 className={styles.ground_work_heading} style={{ marginTop: "1.089vw" }}>Understanding Users</h1>
                    <p className={styles.ground_work_para} style={{ width: "100%" }}>My teammate had a cozy testing session with two grandmas and two middle-aged uncles, letting them dive into the prototype from the comfort of their homes—where they'll likely use the product the most. After dissecting their questions, concerns, moods, and actions, we pinpointed the following pain points.</p>
                    <div className={styles.section3_cards_wraper}>
                        {visionCards.map((data) => (
                            <div className={styles.section3_cards}>
                                <div className={styles.section3_card_img}>
                                    <img src={data.image} alt='' />
                                </div>
                                <h1 className={styles.section3_card_heading}>{data.heading}</h1>
                                <p className={styles.section3_card_text}>{data.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
                {/* ===================Refinement==================== */}
                <div className={styles.section3_user_experience}>
                    <div className={styles.section3_inner_div}>
                        <div className={styles.title}>Refinements</div>
                        <div className={styles.lower_box}>
                            <div className={styles.box_1}>
                                <img className={styles.vector} src="/images/casestudy_5/renfi1.svg" alt="gamification"></img>
                                <h4>Visual improvement</h4>
                                <p>Added project thumbnail previews, icons, reduced text and clutter</p>
                            </div>
                            <div className={styles.box_2}>
                                <img className={styles.vector} src="/images/casestudy_5/renfi2.svg" alt="gamification"></img>
                                <h4>Removed community mode</h4>
                                <p>Removed Governance, Counsel and Marketplace, will be introduced in the future.</p>
                            </div>
                            <div className={styles.box_3}>
                                <img className={styles.vector} src="/images/casestudy_5/renfi3.svg" alt="gamification"></img>
                                <h4>Added whiteboard</h4>
                                <p>To flush out ideas, scenes, & create mood boards. the design was kept old school & organic</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.divdier} style={{ marginTop: "16.698vw" }}>
                    <img src="/images/casestudy_5/divider3.png" alt="" />
                </div>
                {/* ======================Narrative-Ai==================== */}
                <div className={styles.narrative_wraper}>
                    <div className={styles.star_img1}>
                        <img src="/images/casestudy_5/star1.svg" alt="" />
                    </div>
                    <h2 className={styles.narrative_sub_heading}>Introducing</h2>
                    <h1 className={styles.narrative_heading}>Narrative AI</h1>
                    <p className={styles.narrative_para}>A place for people to visualize their imagination,<br />building the future of entertainment</p>
                    <div className={styles.star_img2}>
                        <img src="/images/casestudy_5/star2.svg" alt="" />
                    </div>
                    <video style={{ marginTop: "7.482vw" }} autoPlay loop muted playsInline preload='none'
                        poster='/images/casestudy_5/producer1.PNG' width={'100%'} height={'100%'}>
                        <source src={cloudVideos.PA_V1_NarrativeAI} type="video/mp4"></source>
                    </video>
                </div>
                {/* ====================Slider-2===================== */}
                <h3 className={styles.slider2_heading}>Onboarding the decentralized</h3>
                <div className={styles.slider2_wraper}>
                    <ProducerSlider sliderImages={slider2Images} sliderNum={2} />
                </div>
                {/* ====================Slider-2===================== */}
                {/* ==============Opus-Suite=================== */}
                <div className={styles.producer_main_div}>
                    <div className={styles.suite_wraper}>
                        <h1 className={styles.suite_heading}>Welcome to Opus Suite</h1>
                        <div className={styles.suite_data_wraper}>
                            <div className={styles.suite_data}>
                                <h1 className={styles.suite_heading} style={{ fontSize: "2.334vw", width: "22.031vw", marginLeft: "0", textAlign: "left" }}>The Anatomy of Dashboard</h1>
                                <p className={styles.suite_text}>Community mode was removed and was converted<br /> to opus suite, they will be introduced one by one:<br />
                                    (a) Writer (text-to-video) <br />
                                    (b) Marketplace<br />
                                    (c) Governance<br />
                                    (d) Wallet<br />
                                </p>
                            </div>
                            <div className={styles.suite_img}>
                                <img src="/images/casestudy_5/dashboard.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.suite_data_wraper}>
                            <div className={styles.suite_data}>
                                {/* <div className={styles.line}></div> */}
                                <div className={styles.dash_img}>
                                    <img src="/images/casestudy_5/dash1.png" alt="" />
                                </div>
                                <h1 className={styles.suite_heading_dash}>Writer Home First Interaction</h1>
                                <p className={styles.suite_text} style={{ marginTop: "2.5vw", color: "#000000AB" }}>After onboarding you will get a quick start<br /> guide, with two clear and distinct call to<br /> actions.</p>
                            </div>
                            <div className={styles.suite_img}>
                                <video autoPlay loop muted playsInline preload='none'
                                    poster='/images/casestudy_5/producer2.PNG' width={'100%'} height={'100%'}>
                                    <source src={cloudVideos.ProducerAppHome} type="video/mp4"></source>
                                </video>
                            </div>
                        </div>
                        {/* === */}
                        <div className={styles.suite_data_wraper}>
                            <div className={styles.suite_data}>
                                {/* <div className={styles.line}></div> */}
                                <div className={styles.dash_img}>
                                    <img src="/images/casestudy_5/dash2.png" alt="" />
                                </div>
                                <h1 className={styles.suite_heading_dash}>Home with Projects</h1>
                                <p className={styles.suite_text} style={{ marginTop: "2.5vw", color: "#000000AB" }}>After using the application and crafting your<br /> projects, your recent will be displayed with a<br /> thumbnail. Hovering over each thumbnail treats<br /> you to a mini preview of the project's video.</p>
                            </div>
                            <div className={styles.suite_img}>
                                <video autoPlay loop muted playsInline preload='none'
                                    poster='/images/casestudy_5/producer3.PNG' width={'100%'} height={'100%'}>
                                    <source src={cloudVideos.PA_V3_Projects} type="video/mp4"></source>
                                </video>
                            </div>
                        </div>
                        {/* === */}
                        <div className={styles.suite_data_wraper}>
                            <div className={styles.suite_data}>
                                {/* <div className={styles.line}></div> */}
                                <div className={styles.dash_img}>
                                    <img src="/images/casestudy_5/dash3.png" alt="" />
                                </div>
                                <h1 className={styles.suite_heading_dash}>Project Library</h1>
                                <p className={styles.suite_text} style={{ marginTop: "2.5vw", color: "#000000AB" }}>Library is user’s collection of books/stories,<br /> consisting of their own projects, bought from<br /> market, derivative of originals and their<br /> recordings. </p>
                            </div>
                            <div className={styles.suite_img}>
                                <video autoPlay loop muted playsInline preload='none'
                                    poster='/images/casestudy_5/producer4.PNG' width={'100%'} height={'100%'}>
                                    <source src={cloudVideos.PA_V4_Library} type="video/mp4"></source>
                                </video>
                            </div>
                        </div>
                    </div>
                    {/* =========================Editor-Window========================= */}
                    <div className={styles.section5_card_wraper} style={{ marginTop: "13.125vw", position: "relative" }}>
                        <p className={styles.flush_data}>Editor</p>
                        <h1 style={{ marginTop: "1.563vw", width: "49.167vw" }}>To the Sweet Part</h1>
                        <h1 style={{ marginTop: "0", width: "49.167vw", position: "relative", zIndex: "1" }}>Making Imagination come to Life</h1>
                        <div className={styles.circle}>
                            <img src="/images/casestudy_5/cirle.svg" alt="" />
                        </div>
                    </div>
                    <div className={styles.text_view_wraper}>
                        <div className={styles.text_view_wraper_img}>
                            <img src="/images/casestudy_5/textView1.png" alt="" />
                        </div>
                        <div style={{ marginTop: "8.49vw" }}>
                            <h1 className={styles.suite_heading_dash}>Editor Window</h1>
                            <p className={styles.suite_text} style={{ marginTop: "2.5vw", color: "#000000AB" }}>Dive into the simplicity – just write, let your<br /> imagination soar. Picture this: a starting window <br />with a liner, revealing the magic of how it works and looks.</p>
                        </div>
                    </div>
                    <div className={styles.text_view_wraper} style={{ marginTop: "1.406vw" }}>
                        <div className={styles.text_view_wraper_img} style={{ width: "49.21vw" }}>
                            <img src="/images/casestudy_5/textView2.png" alt="" />
                        </div>
                        <div style={{ marginTop: "8.49vw" }}>
                            <h1 className={styles.suite_heading_dash}>Writing Book.</h1>
                            <p className={styles.suite_text} style={{ marginTop: "2.5vw", color: "#000000AB" }}>The Editor is designed to maintain a focus on text,<br /> while also incorporating book-building features like<br /> chapters and page management.</p>
                        </div>
                    </div>
                    {/* ========================Anatomy========================== */}
                    <div className={styles.section5_card_wraper} style={{ marginTop: "10.032vw" }}>
                        <p className={styles.flush_data}>The work window</p>
                        <h1 style={{ marginTop: "1.563vw", width: "49.167vw" }}>Anatomy of the text Panel</h1>
                        <p className={styles.suite_text} style={{ marginTop: "1.405vw", color: "#000000AB", textAlign: "center" }}>
                            Comprised of 3 parts:<br />
                            (a) Header <br />
                            (b) Text Field<br />
                            (c) Bottom Bar
                        </p>
                    </div>
                    {/* ============================Editor-Frame========================= */}
                    <div className={styles.producer_main_div}>
                        <div className={styles.frame_wraper}>
                            <div className={styles.blank}>
                                <p className={styles.blank_text}>Header</p>
                                <div className={styles.blank_line}></div>
                            </div>
                            <div className={styles.blank} style={{ top: "5.844vw" }}>
                                <p className={styles.blank_text}>Text Field</p>
                                <div className={styles.blank_line}></div>
                            </div>
                            <div className={styles.blank} style={{ top: "35.867vw" }}>
                                <p className={styles.blank_text}>Bottom Tool Bar</p>
                                <div className={styles.blank_line}></div>
                            </div>
                            <div className={styles.frame_img}>
                                <img src="/images/casestudy_5/comp1.png" alt="" />
                            </div>
                            <div style={{ marginTop: "5.208vw" }}>
                                <h3 className={styles.frame_heading}>Header and Text Fieldt</h3>
                                <p className={styles.frame_para}>
                                    The header proudly wears the project's title and a nifty search bar.<br /><br />
                                    The text field effortlessly keeps you in the narrative loop, displaying your
                                    current chapter prominently at the top, and sneakily slipping the page number
                                    to the bottom right – because even chapters need their spotlight moments!
                                </p>
                            </div>
                        </div>
                        <div className={styles.frame_wraper} style={{ marginTop: "2.396vw" }}>
                            <div className={styles.frame_img}>
                                <video autoPlay loop muted playsInline preload='none'
                                    poster='/images/casestudy_5/comp2.png' width={'100%'} height={'100%'}>
                                    <source src={cloudVideos.PA_Search} type="video/mp4"></source>
                                </video>
                            </div>
                            <div style={{ marginTop: "5.208vw" }}>
                                <h3 className={styles.frame_heading}>Search Field</h3>
                                <p className={styles.frame_para}>
                                    Simply type in your desired words, and let the search option
                                    unveil a treasure trove of relevant results, complete with chapter
                                    and page references.
                                </p>
                            </div>
                        </div>
                        <div className={styles.frame_wraper} style={{ marginTop: "2.396vw" }}>
                            <div className={styles.frame_img}>
                                <video autoPlay loop muted playsInline preload='none'
                                    poster='/images/casestudy_5/comp3.png' width={'100%'} height={'100%'}>
                                    <source src={cloudVideos.PA_Prologue} type="video/mp4"></source>
                                </video>
                            </div>
                            <div style={{ marginTop: "5.208vw" }}>
                                <h3 className={styles.frame_heading}>Customizing text & font</h3>
                                <p className={styles.frame_para}>
                                    Highlighting the text, allows you to personalize it with the font
                                    and style that perfectly matches your taste.
                                </p>
                            </div>
                        </div>
                        <div className={styles.frame_wraper} style={{ marginTop: "2.396vw" }}>
                            <div className={styles.frame_img}>
                                <video autoPlay loop muted playsInline preload='none'
                                    poster='/images/casestudy_5/comp4.png' width={'100%'} height={'100%'}>
                                    <source src={cloudVideos.PA_Story} type="video/mp4"></source>
                                </video>
                            </div>
                            <div style={{ marginTop: "2.031vw" }}>
                                <div className={styles.add_btn}>
                                    <img src="/images/casestudy_5/addbtn.svg" alt="" />
                                </div>
                                <div className={styles.add_btn} style={{ marginTop: "1.042vw" }}>
                                    <img src="/images/casestudy_5/pagebtn.svg" alt="" />
                                </div>
                                <h3 className={styles.frame_heading} style={{ marginTop: "1.042vw" }}>Bottom bar</h3>
                                <p className={styles.frame_para}>
                                    The bottom bar is like a command center with four options –
                                    effortlessly create chapters and pages. Plus, the left menu option
                                    is your backstage pass to manage them all. Oh, and there's a play
                                    button too, dive into the action right from your current page!"
                                </p>
                            </div>
                        </div>
                        <div className={styles.frame_wraper} style={{ marginTop: "2.396vw" }}>
                            <div className={styles.blank} style={{ top: "30.867vw" }}>
                                <p className={styles.blank_text}>Page Break</p>
                                <div className={styles.blank_line}></div>
                            </div>
                            <div className={styles.frame_img}>
                                <img src="/images/casestudy_5/comp5.png" alt="" />
                            </div>
                        </div>
                        {/* =================Flush===================== */}

                        <div className={styles.section5_card_wraper}>
                            <p className={styles.flush_data}>HYPER CUSTOMIZED</p>
                            <h1 style={{ marginTop: "1.563vw", width: "49.167vw" }}>Whiteboard, Flush out ideas</h1>
                            <div className={styles.heading_border_img}>
                                <img src="/images/casestudy_5/border.svg" alt="" />
                            </div>
                            <h6 style={{ width: "42.813vw" }}>WB was created to mimic old school exploration of ideas, so users can write stories without the need for GPU muscle or enduring the agony of old hardware.</h6>
                            <div className={styles.editor_wraper}>
                                <div className={styles.editor_box}>
                                    <h6 className={styles.flush_data} style={{ width: "unset", color: "#FFFFFF47", textAlign: "left" }}>Inital View</h6>
                                    <h5 className={styles.notes_wraper_sub_heading} style={{ color: "#FFF", marginTop: "1.062vw" }}>Blank Page</h5>
                                    <p className={styles.editor_box_para}>As you kick off a project, you face a blank page. Start jotting down whatever comes to mind. Let your thoughts flow and get the creative wheels turning.</p>
                                </div>
                                <div className={styles.editor_img_wraper}>
                                    <img src="/images/casestudy_5/editor1.png" alt="" />
                                </div>
                            </div>
                            <div className={styles.editor_wraper} style={{ marginTop: "0.885vw" }}>
                                <div className={styles.editor_box}>
                                    <h6 className={styles.flush_data} style={{ width: "unset", color: "#FFFFFF47", textAlign: "left" }}>Start Of Something Great</h6>
                                    <h5 className={styles.notes_wraper_sub_heading} style={{ color: "#FFF", marginTop: "1.062vw" }}>How it looks with your ideas</h5>
                                    <p className={styles.editor_box_para}>In addition to text, feel free to include reference images for a clearer understanding and visualization, like a simple rough mockup.</p>
                                </div>
                                <div className={styles.editor_img_wraper}>
                                    <img src="/images/casestudy_5/editor2.png" alt="" />
                                </div>
                            </div>
                            <div className={styles.editor_wraper} style={{ marginTop: "0.885vw" }}>
                                <div className={styles.editor_box}>
                                    <h6 className={styles.flush_data} style={{ width: "unset", color: "#FFFFFF47", textAlign: "left" }}>Clean Slate Dashboard</h6>
                                    <h5 className={styles.notes_wraper_sub_heading} style={{ color: "#FFF", marginTop: "1.062vw" }}>Whiteboards</h5>
                                    <p className={styles.editor_box_para}>Just simple, plain showcase.<span style={{ color: "#FFFFFF6B" }}> #here are your pages</span></p>
                                </div>
                                <div className={styles.editor_img_wraper}>
                                    <img src="/images/casestudy_5/editor3.png" alt="" />
                                </div>
                            </div>
                        </div>
                        {/* ================Whats-Next=========================== */}
                        <div className={styles.next_wraper}>
                            <h1 className={styles.notes_wraper_heading}>What's next?</h1>
                            <p className={styles.next_wraper_sub_heading} style={{ marginTop: "3.041vw" }}>ONTO THE OTHER PLATFORM</p>
                            <p className={styles.notes_wraper_para} style={{ color: "#FFFFFF8F", fontSize: "1.021vw" }}>Plotting the course for Governance, Counsels, Marketplace, and Economy System – our next design adventure. Turning it into a game trhough user interviews and research, because who said building the future can't be a bit playful?</p>
                            <p className={styles.next_wraper_sub_heading} style={{ marginTop: "3.041vw" }}>HOPING FOR A BRIGHTER FUTURE</p>
                            <p className={styles.notes_wraper_para} style={{ color: "#FFFFFF8F", fontSize: "1.021vw", width: "43.906vw" }}>I envision a version where users get their hands on it, unleashing their inner goat mode of imagination. Now, toss in some VR, and voila! We'll be proudly proclaiming, 'The future has officially arrived!</p>
                        </div>
                        {/* ==============Final-Notes================== */}
                        <div>
                            <div className={styles.notes_wraper}>
                                <h1 className={styles.notes_wraper_heading}>Final Notes</h1>
                                <h6 className={styles.notes_wraper_sub_heading}>I love exploring new ideas, even if they’re a little risky.</h6>
                                <p className={styles.notes_wraper_para}>It’s important to know how to scale back, as design falters without the “how” of<br />
                                    implementation, but preemptively limiting your imagination is as equally a disservice as an<br />
                                    ungrounded concept. I believe that design thrives when it looks into the future, and my hope
                                    with this project is to showcase my love for vision-making.
                                </p>
                                <h6 className={styles.notes_wraper_sub_heading} style={{ marginTop: "3.798vw" }}>If you have questions on any part of this case study, I’m happy to share more about my design process. Feel free to reach out: I’d love to chat!</h6>
                                <p className={styles.notes_wraper_para}>Until then, thank you for reading!</p>
                                <p className={styles.notes_wraper_para}>abdullahqamar600@gmail.com</p>
                            </div>
                        </div>
                        <ComnFooter userData={userData} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProducerApp;