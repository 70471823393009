import ComnFooter from "../../components/caseStudiesFooter/comnFooter";
import Navbar from "../../components/navbar/navbar";
import {cloudVideos} from "../../videos";
import styles from "./tokenSale.module.css";

function TokenSale() {
  const userData = {
    text_width: "22.813vw",
    text: "In the land of playful websites your ideas are my game plan, Let's create!"
  };

  return (
    <div className={styles.main_div}>
      <div className={styles.navbar}>
        <Navbar />
      </div>
      <div className={styles.section2_passport_detail}>
        <h3>Token Sale’s Page</h3>
        {/* <div className={styles.detail}>
          <h5>Design Walkthrough</h5>
          <p>Investing in Innovation: The ComnCoin Token Sale</p>
          <h6>Fall 2023</h6>
        </div> */}
        <div className={styles.overview}>
          <h4>OVERVIEW</h4>
          <p>Comncoin, developed by Opus AI, is a cutting-edge cryptocurrency designed to power the Opus AI ecosystem. The CommonCoin token sale aims to fund the ongoing development of Opus AI and establish a thriving community of early adopters and supporters.</p>
        </div>
        <div className={styles.role}>
          <h4>Spring 2023 - 1 month</h4>
          <p>Ui Designer, 3D & Vector Illustrator</p>
        </div>
        {/* <div className={styles.role_detail}>
          <p className={styles.role_detail_para}>Please be patient with loading this page, as the MP4s may take a moment to awaken depending on your internet speed. Thanks for your understanding!</p>
        </div> */}
      </div>
      <div className={styles.header_img}>
        <img src="\images\tokenSale\token.svg" alt="" width={"100%"} height={"100%"} />
      </div>
      <div className={styles.section3}>
        <div className={styles.section3_inside}>
          <div className={styles.section3_data}>
            <h5>First Iteration</h5>
            <p>Initially, We focused on the coin's design and aesthetics. This approach stemmed from our vision for the coin to serve not only as an integral part of the Opus ecosystem but also as a versatile, everyday currency. </p>
          </div>
        </div>
      </div>
      <div className={styles.section4}>
        <div className={styles.section4_videos}>
          <video autoPlay loop muted playsInline preload='none' poster ='/images/tokenSale/token1.PNG' width={'100%'} height={'100%'}>
            <source src={cloudVideos.Token_V1_Detail} type="video/mp4"></source>
          </video>
        </div>
        <div className={styles.section4_videos}>
          <video autoPlay loop muted playsInline preload='none' poster ='/images/tokenSale/token2.PNG' width={'100%'} height={'100%'}>
            <source src={cloudVideos.Token_V2_signup} type="video/mp4"></source>
          </video>
        </div>
      </div>

      <div className={styles.section6}>
        <div className={styles.section6_inside}>
          <div className={styles.section6_data}>
            <h5>seconed Iteration</h5>
            <p>Feedback and insights prompted a shift.</p>
            <h6 className={styles.second_iteration}>Later on I was tasked with aligning the design with the Opus theme, seamlessly integrating it into the company's visual language, and moving away from the coin-centric imagery. We also changed the Coin Logo,<br /> to include a cat motif, in-line with the theme.</h6>
            <span className={styles.para2}>This 2nd approach helped in establishing a stronger brand identity. It is no longer about the coin but more about investing in the opus ecosystem.</span>
          </div>
        </div>
      </div>

      <div className={styles.section5}>
        <img src="\images\tokenSale\coin.png" alt="" width={"100%"} height={"100%"} />
      </div>
     <div className={styles.main_div}>
      <div className={styles.section4} style={{ gap: "5.208vw" }}>
        <div className={styles.section4_videos}>
          <video autoPlay loop muted playsInline preload='none' poster ='/images/tokenSale/token3.PNG' width={'100%'} height={'100%'}>
            <source src={cloudVideos.Token_V3_HomeScreen} type="video/mp4"></source>
          </video>
        </div>
        <div className={styles.section4_videos}>
          <video autoPlay loop muted playsInline preload='none' poster ='/images/tokenSale/token4.PNG' width={'100%'} height={'100%'}>
            <source src={cloudVideos.Token_V4_Payment} type="video/mp4"></source>
          </video>
        </div>
        {/* <div className={styles.section4_videos}>
          <img src="\images\tokenSale\catcards.png" alt="" width={'100%'} height={'100%'} />
        </div> */}
        <div className={styles.cat_imgs_wraper}>
          <div className={styles.cat_imgs}>
            <img src="\images\tokenSale\cat_1.png" alt="" width={'100%'} height={'100%'} />
          </div>
          <div className={styles.cat_imgs}>
            <img src="\images\tokenSale\cat_2.png" alt="" width={'100%'} height={'100%'} />
          </div>
          <div className={styles.cat_imgs}>
            <img src="\images\tokenSale\cat_3.png" alt="" width={'100%'} height={'100%'} />
          </div>
        </div>
        <div className={styles.section4_videos} style={{ width: "67.609vw", height: "25.879vw", margin: "auto" }}>
          <img src="\images\tokenSale\payment.png" alt="" width={'100%'} height={'100%'} />
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.footer_data}>
          <div className={styles.content1}>
            <div className={styles.footer_img}>
              <img src="\images\tokenSale\footer.svg" alt="" width={'100%'} height={'100%'} />
            </div>
            <h1 className={styles.content1_text}>Let’s Befriend</h1>
          </div>
          <div className={styles.content2}>
            <p>I'm super friendly! Feel free to drop me an email, send a cheerful hello, or connect with me on Insta. Looking forward to hearing from you!</p>
          </div>
        </div>
      </div>
      <ComnFooter userData={userData}/>
    </div>
    </div>
  );
}

export default TokenSale;
