const cloudinaryVideos = {
    ProducerAppHome: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/ProducerAppHome_whjtpg.mp4',
    CatGlasses: 'https://res.cloudinary.com/dxqnrntan/image/upload/AbdullahPortfolioVideos/CatGlasses_enq7pz.gif',
    OpusHome: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/OpusHome_iiq76o.mp4',
    YIPHome: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/YIPHome_jffgmp.mp4',
    TokenHome: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/TokenHome_igg63k.mp4',
    PA_V1_NarrativeAI: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/PA_V1_NarrativeAI_yuc6gl.mp4',
    PA_V3_Projects: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/PA_V3_Projects_zbytqn.mp4',
    PA_V4_Library: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/PA_V4_Library_jsruvs.mp4',
    PA_Search: 'https://res.cloudinary.com/dxqnrntan/video/upload/v1731026390/PA_Search_wnlpen.mp4',
    PA_Prologue: 'https://res.cloudinary.com/dxqnrntan/video/upload/v1731026393/PA_Prologue_fwbiia.mp4',
    PA_Story: 'https://res.cloudinary.com/dxqnrntan/video/upload/v1731026396/PA_Story_byn8cp.mp4',
    Yip_V1: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/Yip_V1_zpzwhk.mp4',
    Yip_V2: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/Yip_V2_mxf7ec.mp4',
    Token_V1_Detail: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/Token_V1_Detail_pmlanf.mp4',
    Token_V2_signup: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/Token_V2_signup_miws3p.mp4',
    Token_V3_HomeScreen: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/Token_V3_HomeScreen_mfcwnd.mp4',
    Token_V4_Payment: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/Token_V4_Payment_bs7vxo.mp4',
    Opus_V2_Gamer: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/Opus_V2_Gamer_z2or8v.mp4',
    Opus_V3_Visual_Artist: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/Opus_V3_Visual_Artist_wpaapp.mp4',
    Opus_V4_Story_Teller: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/Opus_V4_Story_Teller_l9ljdk.mp4',
    Opus_V5_Performer: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/Opus_V5_Performer_xjah9v.mp4',
    TA_V1_Onboarding: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/TA_V1_Onboarding_s2t1z5.mp4',
    TA_V2_Flows: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/TA_V2_Flows_ui5r51.mp4',
    TA_V3_Navigation_Center: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/TA_V3_Navigation_Center_snfz3k.mp4',
    TA_V4_Treasury: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/TA_V4_Treasury_jido8d.mp4',
    TA_V5_Send_Receive_Flow: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/TA_V5_Send_Receive_Flow_pjxej6.mp4',
    TA_V6_Minting: 'https://res.cloudinary.com/dxqnrntan/video/upload/AbdullahPortfolioVideos/TA_V6_Minting_os5cdi.mp4',
    Design_V1_Landing: 'https://res.cloudinary.com/dxqnrntan/video/upload/v1730648695/Design_V1_Landing_i56yik.mp4',
    Design_V2_Dashboard: 'https://res.cloudinary.com/dxqnrntan/video/upload/v1730648692/Design_V2_Dashboard_jmmojm.mp4',
    Design_Thumbnail: 'https://res.cloudinary.com/dxqnrntan/video/upload/v1731533512/Design_Thumbnail_w7bvr0.mp4'
};

const onedriveVideos = {
    ProducerAppHome: 'https://onedrive.live.com/download?resid=69560BF233C97EF3%21122&authkey=!AG5aUvOv6-xYeNc',
    CatGlasses: 'https://onedrive.live.com/download?resid=69560BF233C97EF3%21114&authkey=%21AIpqXtkGjwjp5yg',
    OpusHome: 'https://onedrive.live.com/download?resid=69560BF233C97EF3%21117&authkey=!AJ76ms6x-puJHWo',
    YIPHome: "https://onedrive.live.com/download?resid=69560BF233C97EF3%21135&authkey=!AJnz-KPfiYgPWFs",
    TokenHome: "https://onedrive.live.com/download?resid=69560BF233C97EF3%21131&authkey=!AElXFL8mji0abeY",
    PA_V1_NarrativeAI: 'https://onedrive.live.com/download?resid=69560BF233C97EF3%21119&authkey=!ADQQTAGbAxFRRsQ',
    PA_V3_Projects: 'https://onedrive.live.com/download?resid=69560BF233C97EF3%21120&authkey=!AGfy5Qbe0puBQ54',
    PA_V4_Library: 'https://onedrive.live.com/download?resid=69560BF233C97EF3%21121&authkey=!ALPdnDPJNEVbHTE',
    PA_Search: 'https://res.cloudinary.com/dxqnrntan/video/upload/v1731026390/PA_Search_wnlpen.mp4',
    PA_Prologue: 'https://res.cloudinary.com/dxqnrntan/video/upload/v1731026393/PA_Prologue_fwbiia.mp4',
    PA_Story: 'https://res.cloudinary.com/dxqnrntan/video/upload/v1731026396/PA_Story_byn8cp.mp4',
    Yip_V1: "https://onedrive.live.com/download?resid=69560BF233C97EF3%21137&authkey=!ALvPM72XWYCwdWA",
    Yip_V2: "https://onedrive.live.com/download?resid=69560BF233C97EF3%21136&authkey=!AL9bjwiIwHhSWCA",
    Token_V1_Detail: "https://onedrive.live.com/download?resid=69560BF233C97EF3%21132&authkey=!ANcqDB_vVTd2y_E",
    Token_V2_signup: "https://onedrive.live.com/download?resid=69560BF233C97EF3%21130&authkey=!AOM5ORFGG_r_hn8",
    Token_V3_HomeScreen: 'https://onedrive.live.com/download?resid=69560BF233C97EF3%21129&authkey=!AP1EGl7UjpBGYBc',
    Token_V4_Payment: "https://onedrive.live.com/download?resid=69560BF233C97EF3%21134&authkey=!AP9cZIVpyokLnqA",
    Opus_V2_Gamer: 'https://onedrive.live.com/download?resid=69560BF233C97EF3%21115&authkey=!ANNso1sEf2_wH6M',
    Opus_V3_Visual_Artist: 'https://onedrive.live.com/download?resid=69560BF233C97EF3%21118&authkey=!AAZ00yEdJOmUt-I',
    Opus_V4_Story_Teller: 'https://onedrive.live.com/download?resid=69560BF233C97EF3%21123&authkey=!ACr_ZTtZwqXVLu8',
    Opus_V5_Performer: 'https://onedrive.live.com/download?resid=69560BF233C97EF3%21116&authkey=!APBDXanaTwx6YrE',
    TA_V1_Onboarding: 'https://onedrive.live.com/download?resid=69560BF233C97EF3%21124&authkey=!APfoSuHRDsP77BQ',
    TA_V2_Flows: 'https://onedrive.live.com/download?resid=69560BF233C97EF3%21125&authkey=!ALWRQ0ej0hSjI3o',
    TA_V3_Navigation_Center: 'https://onedrive.live.com/download?resid=69560BF233C97EF3%21126&authkey=!ACVxe2zc_0c50Hw',
    TA_V4_Treasury: 'https://onedrive.live.com/download?resid=69560BF233C97EF3%21126&authkey=!ACVxe2zc_0c50Hw',
    TA_V5_Send_Receive_Flow: 'https://onedrive.live.com/download?resid=69560BF233C97EF3%21126&authkey=!ACVxe2zc_0c50Hw',
    TA_V6_Minting: "https://onedrive.live.com/download?resid=69560BF233C97EF3%21138&authkey=!ABGKV8--KPNs_yg",
    Design_V1_Landing: 'https://res.cloudinary.com/dxqnrntan/video/upload/v1730648695/Design_V1_Landing_i56yik.mp4',
    Design_V2_Dashboard: 'https://res.cloudinary.com/dxqnrntan/video/upload/v1730648692/Design_V2_Dashboard_jmmojm.mp4',
    Design_Thumbnail: 'https://res.cloudinary.com/dxqnrntan/video/upload/v1731533512/Design_Thumbnail_w7bvr0.mp4'
};

const localVideos = {
    ProducerAppHome: 'videos/ProducerAppHome.mp4',
    CatGlasses: 'videos/CatGlasses.gif',
    OpusHome: 'videos/OpusHome.mp4',
    YIPHome: 'videos/YIPHome.mp4',
    TokenHome: 'videos/TokenHome.mp4',
    PA_V1_NarrativeAI: 'videos/PA_V1_NarrativeAI.mp4',
    PA_V3_Projects: 'videos/PA_V3_Projects.mp4',
    PA_V4_Library: 'videos/PA_V4_Library.mp4',
    PA_Search: 'videos/PA_Search.mp4',
    PA_Prologue: 'videos/PA_Prologue.mp4',
    PA_Story: 'videos/PA_Story.mp4',
    Yip_V1: 'videos/Yip_V1.mp4',
    Yip_V2: 'videos/Yip_V2.mp4',
    Token_V1_Detail: 'videos/Token_V1_Detail.mp4',
    Token_V2_signup: 'videos/Token_V2_signup.mp4',
    Token_V3_HomeScreen: 'videos/Token_V3_HomeScreen.mp4',
    Token_V4_Payment: 'videos/Token_V4_Payment.mp4',
    Opus_V2_Gamer: 'videos/Opus_V2_Gamer.mp4',
    Opus_V3_Visual_Artist: 'videos/Opus_V3_Visual_Artist.mp4',
    Opus_V4_Story_Teller: 'videos/Opus_V4_Story_Teller.mp4',
    Opus_V5_Performer: 'videos/Opus_V5_Performer.mp4',
    TA_V1_Onboarding: 'videos/TA_V1_Onboarding.mp4',
    TA_V2_Flows: 'videos/TA_V2_Flows.mp4',
    TA_V3_Navigation_Center: 'videos/TA_V3_Navigation_Center.mp4',
    TA_V4_Treasury: 'videos/TA_V4_Treasury.mp4',
    TA_V5_Send_Receive_Flow: 'videos/TA_V5_Send_Receive_Flow.mp4',
    TA_V6_Minting: 'videos/TA_V6_Minting.mp4',
    Design_V1_Landing: 'videos/Design_V1_Landing.mp4',
    Design_V2_Dashboard: 'videos/Design_V2_Dashboard.mp4',
    Design_Thumbnail: 'videos/Design_Thumbnail.mp4'
};

let cloudVideos = localVideos;

if (process.env.REACT_APP_RELEASE === 'production') {
    cloudVideos = cloudinaryVideos;
}

module.exports = {cloudVideos, onedriveVideos};