import React, { useState } from 'react';
import style from './imageComponent.module.css'
import {cloudVideos} from '../../videos';

function ImageComponent(props) {
  const [isShown, setIsShown] = useState(false);
  const {
    mainDivWidth,
    mainDivHeight,
    cornerImage,
    cornerHeading,
    imageSorce,
    imageWidth,
    imageHeight,
    buttonColor,
    buttonText,
    marginBtn,
  } = props.userData || {};
  return (
    <div
      className={style.mainDiv}
      style={{ width: mainDivWidth || "10.658vw", height: mainDivHeight || "10.710vw" }}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <div className={style.cornerImage}>
        <img
          alt=''
          width={'100%'}
          height={'100%'}
          src={cornerImage || './images/videocomponent/comnvaultCornorImage.svg'}
        />
      </div>
      <div className={style.cornorText}>
        <p>
          {cornerHeading || 'ComnVault Extension'}
        </p>
      </div>



      <div className={style.pictureDiv}
        style={{ width: imageWidth || "8.873vw", height: imageHeight || "5.161vw",
          marginBottom: marginBtn,
         }}
      >
        {imageSorce === './images/LandingPage/cat.png' ? <div className={style.eyeGif} >
          <img alt='' autoPlay loop src={cloudVideos.CatGlasses} width={'100%'} height={'100%'} />  </div> : ''}
        <img
          width={'100%'}
          height={'100%'}
          alt=''
          src={imageSorce || './images/videocomponent/comnvault.png'}
        />
      </div>


      <div className={style.buttonMainDiv}
        style={{ width: mainDivWidth || "10.658vw"}}
      >
        <div className={style.buttonText}>
          <p>
            {buttonText || 'View Case Study'}
          </p>
        </div>
        <div className={style.moveableButtonDiv}
          style={{
            marginLeft: isShown ? "0vw" : "15vw",
            backgroundColor: buttonColor || 'rgba(0, 86, 86, 1)',
            width: mainDivWidth || "10.658vw"
          }}
        >
        </div>

      </div>

    </div>
  )
}

export default ImageComponent
