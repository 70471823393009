import { useState } from "react";
import styles from "./footer.module.css";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const path = window.location.pathname;
  const [emailCopied, setEmailCopied] = useState(false);
  const text = {
    '/aboutus': {
      title: 'Your ideas and my magic, Let’s connect.',
      width: '52.103vw'
    },
    '/casestudy_1': {
      title: "Navigating the NFT cosmos? Reach out; I'll paint your digital masterpiece.",
      width: '69.103vw'
    },
    '/casestudy_2': {
      title: "Your digital dreams are my canvas. Let’s paint the web in colors of innovation",
      width: '69.103vw'
    },
    '/casestudy_3': {
      title: 'Your ideas and my magic, Let’s connect.',
      width: '52.103vw'
    },
    '/casestudy_4': {
      title: "Exploring the decentralized frontier? Let's build the future of dApps.",
      width: '69.103vw'
    },
    '/casestudy_5': {
      title: "Craving software sorcery? Drop me a line, let's craft the recipe",
      width: '62.103vw'
    },
    '/casestudy_6': {
      title: "Diving into the crypto sea? Let's build decentralized wonders together.",
      width: '69.103vw'
    },
    '/casestudy_7': {
      title: "Require a data based Strategic Shift? Drop me a hellloooo.",
      width: '62.103vw'
    },
    '/casestudy_8': {
      title: "In the land of playful websites your ideas are my game plan, Let's create!",
      width: '69.103vw'
    },
  };

  let emailTimeout = () => {
    setTimeout(() => { setEmailCopied(false) }, 2000)
  };

  return (
    <>
      {text[path.toLowerCase()] && <div className={styles.comn_footer_main_div}>
        <div className={styles.footer_container}>
          {/* ==============Left-Box====================== */}
          <div className={styles.footer_content_box}>
            <p className={styles.footer_content_text} style={{ width: text[path]?.width }}>
              {text[path.toLowerCase()]?.title}
            </p>
          </div>
          <div className={styles.footer_navbar_box}>
            <div className={styles.footer_case}>
              <h4>email</h4>
              <p className={styles.box} onClick={() => {
                navigator.clipboard.writeText('abdullahqamar600@gmail.com')
                setEmailCopied(true);
                emailTimeout();
              }}>{emailCopied ? 'Email Copied' : 'abdullahqamar600@gmail.com'}</p>
            </div>
            <div className={styles.footer_case}>
              <h4>case studies</h4>
              <p className={styles.box} 
              style={{background:path==='/casestudy_3'?'#F2751A':'',
              color:path==='/casestudy_3'?'#ffff':''}}
              onClick={() => { navigate('/casestudy_3')
              }}>DESIGN SYSTEM MANAGEMENT PORTAL</p>
            </div>


            <div className={styles.footer_case}>
              <p className={styles.box}
              style={{background:path==='/casestudy_4'?'#F2751A':'',
              color:path==='/casestudy_4'?'#ffff':''}}
              onClick={() => { navigate('/casestudy_4')
              }}>COMNVALUT DAPP</p>
            </div>
            <div className={styles.footer_case}>
              <p className={styles.box} 
              style={{background:path==='/casestudy_6'?'#F2751A':'',
              color:path==='/casestudy_6'?'#ffff':''}}
              onClick={() => { navigate('/casestudy_6')
              }}>COMNVALUT EXTENSION</p>
            </div>
            <div className={styles.footer_case}>
              <p className={styles.box} 
              style={{background:path==='/casestudy_1'?'#F2751A':'',
              color:path==='/casestudy_1'?'#ffff':''}}
              onClick={() => { navigate('/casestudy_1')
              }}>OPUS PASSPORT</p>
            </div>
             <div className={styles.footer_case}>
              <h4>case studies</h4>
              <p className={styles.box} 
              style={{background:path==='/casestudy_5'?'#F2751A':'',
              color:path==='/casestudy_5'?'#ffff':''}}
              onClick={() => { navigate('/casestudy_5')
              }}>PRODUCER APP</p>
            </div>
            <div className={styles.footer_case}>
              <p className={styles.box}
              style={{background:path==='/casestudy_7'?'#F2751A':'',
              color:path==='/casestudy_7'?'#ffff':''}}
              onClick={() => { navigate('/casestudy_7')
              }}>YIP WEBSITE</p>
            </div>
            <div className={styles.footer_case}>
              <p className={styles.box} 
              style={{background:path==='/casestudy_8'?'#F2751A':'',
              color:path==='/casestudy_8'?'#ffff':''}}
              onClick={() => { navigate('/casestudy_8')
              }}>TOKEN SALES PAGE</p>
            </div>
            <div className={styles.footer_case}>
              <h4>socials</h4>
              <a href="https://www.linkedin.com/in/abdullah-qamar-618057206/" target="#">
                <p className={styles.box}>LINKEDIN</p>
              </a>
            </div>
            <div className={styles.footer_case}>
              <a href="https://www.instagram.com/nerdy4202?igsh=dG90d251d3J5YXdh&utm_source=qr" target="#">
                <p className={styles.box}>INSTAGRAM</p>
              </a>
            </div>
            <div className={styles.footer_case}>
              <h4
              style={{background:path==='/aboutus'?'#F2751A':'',
              color:path==='/aboutus'?'#ffff':'',
              paddingLeft: "2.3vw"
            }} 
              onClick={() => { navigate('/aboutus')
              }}>about</h4>
              <div className={styles.kidding}>I HAVE COMPLEX PERSONALITY DISORDER.
                <br />
                <span>Just Kidding</span>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
}

export default Footer;
