import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDocs, collection } from 'firebase/firestore';
import CryptoJS from 'crypto-js';
import { db } from '../firebase/index';
import NameLoading from '../components/nameloading/nameLoading';
import NameLoadingMbl from '../mobile/comp/nameloading/nameLoading';
import style from './login.module.css';

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectTo = searchParams.get('redirect');

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState(null);
  const [emailCopied, setEmailCopied] = useState(false);

  // Check if the user is already logged in
  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    if (loginData && Date.now() - loginData.timestamp < 86400000) { // 86400000ms = 1 day
      navigate(redirectTo ? redirectTo : '/');
    }
  }, [navigate, redirectTo]);

  const emailTimeout = () => {
    setTimeout(() => { setEmailCopied(false); }, 2000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const portfolioCollection = collection(db, 'Portfolio');

    try {
      const querySnapshot = await getDocs(portfolioCollection);
      let found = false;

      querySnapshot.forEach((doc) => {
        const userData = doc.data();

        if (userData.role === 'user') {
          const hashedInput = CryptoJS.SHA256(password).toString();
          if (userData.hashPassword === hashedInput) {
            found = true;
            // Save login timestamp to localStorage for 1 day
            localStorage.setItem(
              'loginData',
              JSON.stringify({ timestamp: Date.now() })
            );
            navigate(redirectTo ? redirectTo : '/');
          }
        }
      });

      if (!found) {
        setIsLoading(false);
        setMessage('Wrong Password');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      setMessage('Server Error');
    }
  };

  return (
    <div className={style.login_container}>
      <div style={{ position: 'fixed', top: '0', zIndex: '10', display: isLoading ? 'block' : 'none' }}>
        <div className={style.desktop}>
          <NameLoading />
        </div>
        <div className={style.mobile}>
          <NameLoadingMbl />
        </div>
      </div>
      <div className={style.logo} onClick={() => navigate('/')}>
        <img src='/imagesVersion2/mobile/navbar/navTitle.svg' alt='logo' />
      </div>
      <div className={style.login}>
        <form className={style.password} onSubmit={handleSubmit}>
          <input
            style={{ color: message ? 'red' : '' }}
            placeholder='Pass...'
            type={message ? 'text' : 'password'}
            required
            value={message ?? password}
            onFocus={() => setMessage(null)}
            onInput={() => setMessage(null)}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type='submit'>
            <img src='images/login/right_arrow.svg' alt='' />
          </button>
        </form>
        <div className={style.links}>
          <a href='https://dribbble.com/Abdullah_q' target='_blank' rel='noreferrer'>Dribble</a>
          <a href='https://www.linkedin.com/in/abdullah-qamar-618057206' target='_blank' rel='noreferrer'>Linkedin</a>
          <a href='https://www.instagram.com/nerdy4202?igsh=dG90d251d3J5YXdh&utm_source=qr' target='_blank' rel='noreferrer'>Instagram</a>
          <p onClick={() => {
            navigator.clipboard.writeText('abdullahqamar600@gmail.com')
            setEmailCopied(true);
            emailTimeout();
          }}>
            <span>{emailCopied ? 'Email Copied' : 'abdullahqamar600@gmail.com'}</span>
          </p>
        </div>
      </div>
    </div>
  );
}
