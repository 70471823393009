import React, { useState } from 'react';
import style from './responsiveSlider.module.css';

const ResponsiveSlider = ({ sliderImages }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const maxIndex = 3;

  // Each slide translates the entire viewport width (100vw) per slide
  const translateValue = (index) => index * 85.656;

  const handlePrevClick = () => {
    if (currentIndex > 0) setCurrentIndex(currentIndex - 1);
  };

  const handleNextClick = () => {
    if (currentIndex < maxIndex) setCurrentIndex(currentIndex + 1);
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.clientX);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const diff = e.clientX - startX;
      if (diff > 100 && currentIndex > 0) {  // Dragging right
        handlePrevClick();
        setIsDragging(false);
      } else if (diff < -100 && currentIndex < maxIndex) {  // Dragging left
        handleNextClick();
        setIsDragging(false);
      }
    }
  };
    // Touch event handlers for mobile
    const handleTouchStart = (e) => {
      setIsDragging(true);
      setStartX(e.touches[0].clientX);
    };
  
    const handleTouchMove = (e) => {
      if (isDragging) {
        const diff = e.touches[0].clientX - startX;
        if (diff > 100 && currentIndex > 0) {  // Swiping right
          handlePrevClick();
          setIsDragging(false);
        } else if (diff < -100 && currentIndex < maxIndex) {  // Swiping left
          handleNextClick();
          setIsDragging(false);
        }
      }
    };
  
    const handleTouchEnd = () => {
      setIsDragging(false);
    };

  return (
    <div className={style.slider_main_div}>
      <div
        className={style.slider_wraper}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div
          className={style.slider_scroller}
          style={{ transform: `translateX(-${translateValue(currentIndex)}vw)`, width: `${85.656 * 4}vw` }}
        >
          {sliderImages.map((data, id) => (
            <div
              key={id}
              className={style.slider_img}
              // style={{ width: "100vw" }}  // Each slide takes up 100vw width
            >
              <img src={data.img} alt="" width="100%" height="100%" />
            </div>
          ))}
        </div>
      </div>

      {/* Dots below the slider */}
      <div className={style.slider_dots}>
        {Array.from({ length: 4 }).map((_, index) => (
          <span
            key={index}
            className={`${style.dot} ${currentIndex === index ? style.active : ''}`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default ResponsiveSlider;
