import style from './comnValut.module.css';
import Navbar from '../../comp/navbar/navbar.js';
import { vaultAutoSlider, lastData, dApp, valutData, valutCards, circles, blackCard, valutProduct, question1, expertData, expertData1, viewsQuestion, viewerData } from '../../mblData';
import ValutSlider from './vaultSlider.js';
import { useNavigate } from 'react-router-dom';

function Valut() {
  const navigate = useNavigate();

  const footerData = {
    heading: "Thank You",
    content: "I owe a lot to this startup for being the launchpad for my design journey, and for being the launchpad for so many opportunities I would later encounter. Thank you to my Co-founders, and Managers",
    mail: "abdullahqamar600@gmail.com",
    linkedin: "https://www.linkedin.com/in/abdullah-qamar-618057206/",
    instagram: "https://www.instagram.com/nerdy4202?igsh=dG90d251d3J5YXdh&utm_source=qr",

  };
  const redesignCards = [
    {
      image: "/imagesVersion2/mobile/vault/cardf1.svg",
      // title: "Shift to Consultative Model",
      content: "Blockchain Complexity & New Concepts",
    },
    {
      image: "/imagesVersion2/mobile/vault/cardf2.svg",
      // title: "Strong Identity",
      content: 'Initial emphasis on technical users for smoother onboarding.',
    },
    {
      image: "/imagesVersion2/mobile/vault/cardf3.svg",
      // title: "Visual Language",
      content: 'So Early Technical Users can act as evangelists for others.',
    },
    {
      image: "/imagesVersion2/mobile/vault/cardf4.svg",
      // title: "Clear User Journey",
      content: 'Extending to broader audience in later stage.',
    },
  ];
  const interview = [
    "/imagesVersion2/mobile/vault/kaleem.svg",
    "/imagesVersion2/mobile/vault/mahmood.svg",
    "/imagesVersion2/mobile/vault/zaid.svg",
    "/imagesVersion2/mobile/vault/hamza.svg",
  ];
  const textSlider1 = [
    {
      link: 'imagesVersion2/mobile/vault/cardData1.png',
      height: '218.462vw',
    },
    {
      link: 'imagesVersion2/mobile/vault/cardData2.png',
      height: '218.462vw',
    },
    {
      link: 'imagesVersion2/mobile/vault/cardData3.png',
      height: '181.026vw',
    },
  ];
  const textSlider2 = [
    {
      link: 'imagesVersion2/mobile/vault/cardData4.png',
      height: '218.462vw',
    },
    {
      link: 'imagesVersion2/mobile/vault/cardData5.png',
      height: '181.795vw',
    },
    {
      link: 'imagesVersion2/mobile/vault/cardData6.png',
      height: '182.308vw',
    },
  ];

  return (
    <div className={style.maindiv}>
      {/* ===========================Navbar/Header=================== */}
      <div className={style.nav_container}>
        <Navbar />
      </div>
      {/* ========================Header======================== */}
      <div className={style.header}>
        {/* <Header userData={headerData} /> */}
        <h1 className={style.heading_main}>ComnVault</h1>
        <p className={style.design_text} style={{ paddingTop: "14.615vw" }}>OVERVIEW</p>
        <p className={style.content1}>ComnVault is a decentralized application (dApp) that serves as a
          management platform for the digital currency known as 'ComnCoins’ Created by Opus.ai,<br /><br />
          ComnCoin is a cryptocurrency specifically developed for use within the Opus Ecosystem.</p>
        <p className={style.design_text} style={{ paddingTop: "5.128vw" }}>Fall 2023 - 2 months</p>
        <p className={style.Illustrat}>Solo Product Designer involving skills:<br /><br />
          Researcher, UI Designer, Interaction Designer, Product Manager</p>
      </div>
      <div className={style.header_img}>
        <img src="/imagesVersion2/mobile/vault/valutHead.svg" alt="" />
      </div>
      {/* ================Role-Box=============== */}
      {/* <div className={style.role_box}>
        <p className={style.text}>Note: Depending on your internet speed, this page may require time to load. The MP4s take a moment to awaken from hibernation. Appreciate your patience!</p>
      </div> */}
      {/* ======================Company-Vision=================== */}
      <p className={style.vision_text}>Company’s Vision</p>
      <div className={style.vision_wraper}>
        {blackCard.map((data) => (
          <div className={style.vision_card} key={data.heading}>
            <div className={style.vision_img}>
              <img src={data.image} alt='' width={"100%"} height={"100%"} />
            </div>
            <h1 className={style.vision_heading}>{data.heading}</h1>
            <p className={style.vision_para}>{data.text}</p>
          </div>
        ))}
      </div>
      {/* ===============================Product-Goals===================== */}
      <div className={style.experience}>
        <div className={style.container_product}>
          <p className={style.heading_1} style={{ color: "#fff" }}>Primary Product Goals</p>
          {valutProduct.map((data, i) => (
            <div className={style.box_product} key={data.detail}>
              <div className={style.image}>
                <img src={data.img} alt="" width={"100%"} height={"100%"} />
              </div>
              <p className={style.box_detail} style={{ margin: 'auto', width: i === 0 ? '46.385vw' : '61vw' }}>{data.detail}</p>
            </div>
          ))}
        </div>
      </div>
     
        {/* ==================Product-Stages===================== */}
        <div className={style.cover}>
          <p className={style.cover_heading}>Product Stages & Management</p>
          <p className={style.cover_para}>There was a strict deadline of <span style={{ background: "black", color: "#fff" }}>&nbsp;2 months&nbsp;</span>, so I divided the project into stages. Could not fit usability testing with in. So it was shifted in the 2nd core of the design that was going to start after development. </p>
        </div>

        {/* ================Timeline============ */}
        <div className={style.timeline_wraper}>
          <p className={style.timeline_para}>Timeline constraints</p>
          <div className={style.timeline_img}>
            <img src='\imagesVersion2\mobile\vault\timeline.svg' alt='' width={"100%"} height={"100%"} />
          </div>
          <div className={style.text_container}>
            <p style={{ color: "#00000099" }}>Stage 1</p>
            <p style={{ color: "#00000099" }}>Later Stages</p>
          </div>
        </div>
        {/* ===============Graph================ */}
        <div className={style.graph_wraper}>
          <img src='\imagesVersion2\mobile\vault\graph.svg' alt=''></img>
        </div>
        {/* ========Insights=========== */}
        <div className={style.experts_wraper}>
          <p className={style.cover_heading} style={{ width: "100%", textAlign: "center" }}>Technology Insight</p>
          <div className={style.insight_img}>
            <img src='\imagesVersion2\mobile\vault\insights.png' alt='' width={"100%"} height={"100%"} />
          </div>
        </div>
        {/* ===============Experts=========== */}
        <div className={style.experts_wraper}>
          <p className={style.cover_heading} style={{ width: "100%" }}>Expert Interviews</p>
          <p className={style.insight_heading}>Experts:</p>
          <div className={style.persons} style={{ marginBottom: "2.051vw" }}>
            <div className={style.persons_img}>
              <img src='\imagesVersion2\mobile\vault\adnan.svg' alt='' width={"100%"} height={"100%"} />
            </div>
            <div style={{ marginLeft: "5.128vw" }}>
              <p className={style.persons_heading}>Adnan Yunus</p>
              <p className={style.persons_data}>Embeded Engineer</p>
              <p className={style.persons_data}>Technologist since 1995</p>
            </div>
          </div>
          <div className={style.persons}>
            <div className={style.persons_img}>
              <img src='\imagesVersion2\mobile\vault\asra.svg' alt='' width={"100%"} height={"100%"} />
            </div>
            <div style={{ marginLeft: "5.128vw" }}>
              <p className={style.persons_heading}>Asra Nadeem</p>
              <p className={style.persons_data}>Venture Capitalist</p>
              <p className={style.persons_data} style={{ marginTop: "0.513vw" }}>Helping Finding and Investing in Blockchain Startups/Space @ Draper University since 2016.</p>
            </div>
          </div>
        </div>
        {/* ===============Questions================= */}
        <div className={style.experts_wraper} style={{ marginTop: "17.436vw", width: "87vw" }}>
          <p className={style.insight_heading}>Questions:</p>
          <ol style={{ paddingLeft: "4.8vw", textAlign: 'justify' }}>
            {question1.map((data) => (
              <li className={style.questions} key={data}>{data}</li>
            ))}
          </ol>
        </div>
        {/* ===============Adnan-takeways================ */}
        <div className={style.ux_box} style={{ color: "unset" }}>
          <h1 style={{ marginLeft: "2vw", fontSize: '4.615vw' }}>Key Takeaways</h1>
          <h3 className={style.expert_name}>Adnan</h3>
        </div>
        <div className={style.box}>
          <div style={{ padding: "10.256vw 3.077vw 11.795vw 3.077vw" }}>
            <h1>Aspects & Recomendations</h1>
            {expertData.map((data) => (
              <div className={style.data_wraper} key={data.heading}>
                <h3 style={{ marginBottom: "2.051vw" }}>{data.heading}</h3>
                <p style={{ color: "#FFFFFF99", fontFamily: "DM Sans" }}>{data.text}</p>
              </div>
            ))}
          </div>
        </div>
        {/* ============================Asrar-takeways==================== */}
        <div className={style.ux_box} style={{ color: "unset" }}>
          {/* <h1 style={{marginLeft: "2vw"}}>Key Takeaways</h1> */}
          <h3 className={style.expert_name}>Asra</h3>
        </div>
        <div className={style.box}>
          <div style={{ padding: "10.256vw 3.077vw 11.795vw 3.077vw" }}>
            <h1>Aspects & Recomendations</h1>
            {expertData1.map((data) => (
              <div className={style.data_wraper} style={{ padding: '5.128vw 0 7.692vw 4.615vw' }}>
                <h3 style={{ marginBottom: "2.051vw" }}>{data.heading}</h3>
                <p style={{ color: "#FFFFFF99", fontFamily: "DM Sans" }}>{data.text}</p>
              </div>
            ))}
          </div>
        </div>
        {/* ==============================Planning Users============================ */}
        <div className={style.last_section} style={{ marginTop: "17.949vw" }}>
          <div className={style.sub_container}>
            <h3 className={style.last_heading}>Planning Users & Empathizing</h3>
            <p className={style.last_content}>
              Before jumping to the users it was important to understand the state of Blockchain.
              It’s a new technology with completely new mechanisms. Understanding and empathizing
              with users having tech background lay the foundation for crafting an inclusive and
              user-centric design. This strategic approach not only ensures a seamless experience
              for technical users but also sets the stage for Opus to extend its narrative to a
              broader audience.
            </p>
          </div>
        </div>
        {/* =============================factors-User===================================== */}
        <div className={style.redesign_cards}>
          <h2>Factors for user restriction:</h2>
          {redesignCards.map((card, i) => (
            <div className={style.redesign_card}>
              <div className={style.re_card_img}>
                <img src={card.image} alt='' width={"100%"} height={"100%"} />
              </div>
              <p style={{ width: i === 0 ? '30vw' : '36vw' }}>{card.content}</p>
            </div>
          ))}
        </div>
        {/* ===================Interviewees====================== */}
        <div className={style.experts_wraper}>
          <p className={style.cover_heading} style={{ width: "100%" }}>Interviewees:</p>
          {/* <p className={style.insight_heading}>Experts:</p> */}
          {viewerData.map((data) => (
            <div className={style.persons} style={{ marginBottom: "2.051vw", marginTop: "10.256vw", width: "89.821vw" }}>
              <div className={style.persons_img}>
                <img src={data.image} alt='' width={"100%"} height={"100%"} />
              </div>
              <div style={{ marginLeft: "5.128vw" }}>
                <p className={style.persons_heading}>{data.name}</p>
                <ul style={{ paddingLeft: "8vw" }}>
                  <li className={style.persons_data}>{data.data1}</li>
                  <li className={style.persons_data}>{data.data2}</li>
                </ul>
              </div>
            </div>
          ))}
        </div>
        {/* =================Interviewees-Questions================== */}
        <div className={style.experts_wraper} style={{ marginTop: "17.436vw", width: "86vw" }}>
          <p className={style.insight_heading}>Questions:</p>
          {viewsQuestion.map((data) => (
            <>
              <p className={style.inter_heading}>{data.heading}</p>
              <ol style={{ paddingLeft: "3.3vw", marginTop: '1.282vw', textAlign: 'justify' }}>
                <li className={style.questions}>{data.q1}</li>
                <li className={style.questions}>{data.q2}</li>
              </ol>
            </>
          ))}
        </div>

        {/* =====================mapping=======================*/}
        <p className={style.slider_heading} style={{ marginLeft: "11.754vw" }}>Empathy Mapping:</p>
        {interview.map((data) => (
          <div className={style.map_image}>
            <img src={data} alt='' width={"100%"} height={"100%"} />
          </div>
        ))}

        {/* =====================Circles====================== */}
        <div className={style.back_box}>
          <p className={`${style.slider_heading} ${style.back_box_heading}`}>Feelings:</p>
          <div className={style.circle_wraper}>
            {circles.map((data) => (
              <div className={style.circle_img}>
                <img src={data} alt='' width={"100%"} height={"100%"} />
              </div>
            ))}
          </div>
        </div>
        {/* ========================slider1===================== */}
        <p className={style.slider_heading}>Addressing Pain Points:</p>
        <div className={style.text_slider1}>
          <ValutSlider slider={textSlider1} />
        </div>
        {/* ========================slider2===================== */}
        <p className={style.slider_heading}>Leveraging Plus Points:</p>
        <div className={style.text_slider2}>
          <ValutSlider slider={textSlider2} />
        </div>
        {/* =================Solutions=============== */}
        <div className={style.ux_box}>
          <h3 className={style.solution_heading}>Solutions:</h3>
          {valutCards.map((data) => (
            <div className={style.card_wraper}>
              <p>{data}</p>
            </div>
          ))}
          <div className={style.card_wraper} >
            <p style={{ textAlign: "center" }}>Strong Visual Communication</p>
          </div>
        </div>
        {/* ==============================Black-boxData======================= */}
        <div className={style.ux_box} style={{ color: "unset", marginLeft: '7.692' }}>
          <h1>UX Writing :</h1>
          <p style={{ opacity: '60%' }}>Important Highlights,</p>
        </div>
        <div className={style.box}>
          <div style={{ padding: "10.256vw 3.077vw 7.795vw 3.077vw" }}>
            <h1>Layman Terms & Briefs</h1>
            {valutData.map((data) => (
              <div className={style.data_wraper}>
                <h3 style={{ marginBottom: "2.051vw" }}>{data.heading}</h3>
                <p style={{ color: "#FFFFFF99" }}>{data.text}</p>
              </div>
            ))}
          </div>
        </div>
        {/* ====================Nftheading-upper-cards================== */}
        <div className={style.vault_group_wrapper}>
          <img src='imagesVersion2/mobile/vault/vaultsGroup.png' alt=''></img>
        </div>

        {/* ==========================Heading-BeforeVideos==================== */}
        <div className={style.nft_wraper}>
          <h1>Passport NFTs:</h1>
          <div className={style.nft_wraper_img}>
            <img onClick={() => { navigate('/casestudy_1') }} src='\imagesVersion2\mobile\vault\text.svg' alt='' width={"100%"} height={"100%"} />
          </div>
          <h1 style={{ marginTop: "15.385vw" }}>Extension:</h1>
          <div className={style.nft_wraper_img} style={{ marginBottom: "15.385vw" }}>
            <img onClick={() => { navigate('/casestudy_4') }} src='\imagesVersion2\mobile\vault\text2.svg' alt='' width={"100%"} height={"100%"} />
          </div>
          <h1 style={{ margin: "15.385vw 0 34.359vw" }}>Decentralized Mobile Application:</h1>
        </div>
     
      {/* ====================Videos-Container======================== */}
      {dApp.map((data) => (
        <>
          <h4 className={style.video_heading} style={{ marginBlock: '10vw' }}>{data.text}</h4>
          <div className={style.video_wraper}>
            <video autoPlay loop muted playsInline preload='none' poster={data.poster}>
              <source src={data.videolink} type="video/mp4"></source>
            </video>
          </div>
        </>
      ))}
      {/* ========================Design-Feel======================== */}
      <div className={style.last_section} style={{ marginTop: "17.949vw" }}>
        <div className={style.sub_container} style={{ paddingBottom: '2.564vw' }}>
          <h3 className={style.last_heading}>Design and Feel -</h3>
          <p className={style.last_content} style={{ marginTop: '15.128vw', width: '70.256vw', opacity: '67%' }}>
            The design language of the application centers on
            combining mechanical and futuristic elements while maintaining a minimalist approach.
            This choice aims to achieve a user interface that is visually appealing, highly efficient,
            easy to navigate and also aligns with company’s message. Elements like clean lines, simple
            color schemes, and intuitive layouts contribute to a sleek and modern feel, aiding in user’s
            understanding and learning curve.
          </p>
        </div>
      </div>
      {/* ========================Slider================== */}
      <div className={style.slider_wrapper}>
        <div className={style.slider}>
          {vaultAutoSlider.map((image, index) => (
            <img className={style.slider_img} key={index} src={image} alt="" />
          ))}
        </div>
      </div>
      {/* =====================After-Slider====================== */}
      <div className={style.last_section}>
        <div className={style.sub_container} style={{ paddingRight: '8vw' }}>
          <h1>Challenges & Learnings</h1>
          {lastData.map((data) => (
            <>
              <div className={style.last_img}>
                <img src={data.image} alt='' width={"100%"} height={"100%"} />
              </div>
              <h3 className={style.last_heading}>{data.heading}</h3>
              <p className={style.last_content} style={{ width: '68.205vw' }}>{data.content}</p>
            </>
          ))}
        </div>
      </div>
      {/* ====================Same-footer====================== */}
      <div className={style.footer}>
        <div className={style.container} style={{ width: '85.462vw' }}>
          <p className={style.heading} style={{ paddingTop: '20.769vw', marginTop: '0' }}>{footerData.heading}</p>
          <p className={style.content} style={{ fontSize: '3.59vw' }}>{footerData.content}</p>
          <p className={style.content} style={{ color: "#fff" }}>{footerData.mail}</p>
          <div className={style.anchor}>
            <a className={style.link} href={footerData.linkedin} target='_blank' rel="noreferrer">
              LinkedIn
            </a>
            <a className={style.link} href={footerData.instagram} target='_blank' rel="noreferrer">
              Instagram
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Valut;