import { useEffect, useState } from "react";
import styles from "./navbar.module.css";
import {Link, useNavigate} from "react-router-dom";

function Navbar() {
  const [emailCopied, setEmailCopied] = useState(false);
  const [prevScrollpos, setPrevScrollpos] = useState(window.scrollY);
  const [top, setTop] = useState(0);
  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (prevScrollpos > currentScrollPos) {
        setTop(0); // Show navbar
      } else {
        setTop(-10); // Hide navbar
      }
      setPrevScrollpos(currentScrollPos);
    };
    // Add scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);
    // Clean up by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollpos]);

  let emailTimeout =()=> {
    setTimeout(()=>{setEmailCopied(false)}, 2000)
  };
  const navigate = useNavigate();
  return (

    <div  id="navbar"  className={styles.navbar_main_div} style={{top: `${top}vw`}}>
      <div className={styles.navbar_wraper}>
        <Link to='/'>
          <div className={styles.logo}>
            <img
              alt=""
              src=".\images\nameloading\abdullahlogo.svg"
              width={"100%"}
              height={"100%"}
            />
          </div>
        </Link>

        <div className={styles.tabs}>
          <div className={styles.design_section}>
            <p className={styles.designs}>designs & studies</p>
            <div className={styles.design_box_wraper}>
              <div className={styles.design_box}>
              <div className={styles.case}>
                <h4>case studies</h4>
                <p onClick={()=>{navigate('/casestudy_3')}}>Design System Portal</p>
                <p onClick={()=>{navigate('/casestudy_6')}}>comnValut dapp</p>
                <p onClick={()=>{navigate('/casestudy_4')}}>comnvault Extension</p>
                <p onClick={()=>{navigate('/casestudy_1')}}>opus passports</p>
              </div>
              <div className={styles.web}>
                <h4>web interfaces</h4>
                <p onClick={()=>{navigate('/casestudy_5')}}>Producer App</p>
                <p onClick={()=>{navigate('/casestudy_7')}}>yip website</p>
                <p onClick={()=>{navigate('/casestudy_8')}}>Token Sales Page</p>
              </div>
              </div>
            </div>
          </div>
          <Link to='/aboutus'>
            <p className={styles.about}>about</p>
          </Link>
          <div className={styles.contact_section}>
            <p className={styles.contact_us}>contact</p>
            <div className={styles.contact_box_wraper}>
              <div className={styles.contact_box}>
              <p className={styles.email} onClick={() => {
            navigator.clipboard.writeText('abdullahqamar600@gmail.com')
            setEmailCopied(true);
            emailTimeout();
            }}>{emailCopied ? 'Email Copied' : 'abdullahqamar600@gmail.com'}</p>
                <a href="https://www.linkedin.com/in/abdullah-qamar-618057206/" target="#">Linkedin</a>
                <a href="https://www.instagram.com/nerdy4202?igsh=dG90d251d3J5YXdh&utm_source=qr" target="#">Instagram</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
