import { useEffect, useState } from "react";
import styles from "./navbar.module.css";
import { useNavigate } from "react-router-dom";

function Navbar({ props }) {
  // const path = window.location.pathname;
  const [emailCopied, setEmailCopied] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const [prevScrollpos, setPrevScrollpos] = useState(window.scrollY);
  const [top, setTop] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      if (showNavbar) return;
      const currentScrollPos = window.scrollY;
      if (prevScrollpos > currentScrollPos || currentScrollPos < 20) {
        setTop(0); // Show navbar
      } else {
        setTop(-18); // Hide navbar
      }
      setPrevScrollpos(currentScrollPos);
    };
    // Add scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollpos, showNavbar]);


  let emailTimeout = () => {
    setTimeout(() => { setEmailCopied(false) }, 2000)
  };

  const handleClick = () => {
    setShowNavbar(!showNavbar);
  };

  const navData = [
    {
      heading: "CASE STUDIES",
      data: [{ case: "Producer App", link: '/casestudy_5' }, { case: "ComnVault dApp", link: '/casestudy_6' },
      { case: "ComnVault Extension", link: '/casestudy_4' }, { case: "Opus Passports", link: '/casestudy_1' }],
    },
    {
      heading: "WEB INTERFACES",
      data: [{ case: "DESIGN SYSTEM MANAGEMENT PORTAL", link: '/casestudy_3' }, { case: "YIP Website", link: '/casestudy_7' }, { case: "Token Sales Page", link: '/casestudy_8' }],
    },
  ];

  return (
    <div className={styles.navbar_main_wraper}>
      <div className={showNavbar ? styles.navbar_main_div_after : styles.navbar_main_div_before}
        style={{
          // backgroundColor: showNavbar ? '#FFFFFF' : props?.background ?? '#EFEFEF',
          top: `${top}vw`
        }}
      >
        <div className={styles.navHeading}>
          <div className={styles.nav_title} onClick={() => { navigate('/') }}>
            <img src="/imagesVersion2/mobile/navbar/navTitle.svg" alt=""
              style={{ objectFit: 'cover' }} width={"100%"} height={"100%"} />
          </div>
          <div className={styles.nav_btn} onClick={handleClick}>
            <svg style={{ top: showNavbar ? '2.051vw' : '0' }} width="27" height="4" viewBox="0 0 27 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line style={{ stroke: showNavbar ? '#F2751A' : '#A8A8A8' }} x1="27" y1="2.25293" x2="-1.86349e-07" y2="2.25293" stroke="#C38D02" strokeWidth="3" />
            </svg>
            <svg style={{ top: '2.051vw' }} width="27" height="4" viewBox="0 0 27 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line style={{ stroke: showNavbar ? '#F2751A' : '#A8A8A8' }} x1="27" y1="2.25293" x2="-1.86349e-07" y2="2.25293" stroke="#C38D02" strokeWidth="3" />
            </svg>
            <svg style={{ bottom: showNavbar ? '2.051vw' : '0' }} width="27" height="4" viewBox="0 0 27 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line style={{ stroke: showNavbar ? '#F2751A' : '#A8A8A8' }} x1="27" y1="2.25293" x2="-1.86349e-07" y2="2.25293" stroke="#C38D02" strokeWidth="3" />
            </svg>
          </div>
        </div>
        <div className={styles.container}>
          <h5>designs & Studies</h5>
          {navData.map((data) => (
            <div key={data.heading}>
              <h2 className={styles.heading}>{data.heading}</h2>
              <ul className={styles.list}>
                {data.data.map((item, index) => (
                  <div key={item.case}>
                    <li className={styles.list_data} onClick={() => { navigate(item.link) }}>{item.case}</li>
                  </div>
                ))}
              </ul>
            </div>
          ))}
          <h5 className={styles.about} onClick={() => { navigate('/aboutus') }}>about</h5>
          <h5 className={styles.contact}>contact</h5>
          <p className={styles.contact_type} onClick={() => {
            navigator.clipboard.writeText('abdullahqamar600@gmail.com')
            setEmailCopied(true);
            // clearTimeout(emailTimeout);
            emailTimeout();
          }}>{emailCopied ? 'Email Copied' : 'abdullahqamar600@gmail.com'}</p>
          <p className={styles.contact_type}>
            <a href="https://www.linkedin.com/in/abdullah-qamar-618057206/" target="#">LinkedIn</a>
          </p>
          <p className={styles.contact_type}>
            <a href="https://www.instagram.com/nerdy4202?igsh=dG90d251d3J5YXdh&utm_source=qr" target="#" style={{ paddingBottom: '25vw' }}>Instagram</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
