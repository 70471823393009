import styles from "./designSystem.module.css";
import Navbar from "../../components/navbar/navbar";
import ComnFooter from "../../components/caseStudiesFooter/comnFooter";
import Header from "./header";
import DesignSlider from "./designSlider";
import { cloudVideos } from "../../videos";
import AutoScroller from "./autoScroller";

function DesignSystem() {
    const header = {
        left: 'Redesign Walkthrough',
        center: 'OVERVIEW & GOALS',
        description1: 'The primary goal of i2c’s DSM redesign was to enhance usability, improve user experience through a modern interface, and provide better documentation.',
        description2: 'Additionally, we aimed to introduce a new Marketing Design System alongside the existing Product Design System. This dual focus required the need for a new and better approach for DSM Portal.',
        right: 'Summer 2024'
    };
    const header2 = {
        left: 'START OF JOURNEY',
        center: 'Role - Team of 3 Designers',
        description1: 'Our team comprised of 11 members. Myself, Lead, Motion Designer,\n 6 Developers & 2 Managers',
        description2: 'I designed Inner Pages, responsives, designed illustrations and worked alongside developers for support & quality assurance.',
        right: '2-3 months'
    }
    const redesignCards = [
        {
            image: "/imagesVersion2/mobile/producer/card1.svg",
            title: "Outdated Information",
            content: "The existing portal contained outdated information that no longer reflected current best practices.",
        },
        {
            image: "/imagesVersion2/mobile/producer/card5.svg",
            title: "Brand Evaluation",
            content: 'i2c\'s branding had evolved significantly since the last iteration of the portal, necessitating a visual and functional update.',
        },
        {
            image: "/imagesVersion2/mobile/producer/card3.svg",
            title: "Target Audience Misalignment",
            content: 'While the previous design served both designers and developers, it lacked the focused approach needed for developers, who are the primary users.',
        },
    ];
    const design = [
        {
            h1: 'Closely collaborated with developers to update the documentation & design.',
            p: 'There were average 2-3 meetings each day to make decisions and mold the design accordingly. These activities opened a whole new perspective for me on how corporates write code, how design system is maintained on dev side and how to tackle design restrictions. ',
            img: 'images/casestudy_3/light_1.png',
            imgHeight: '71.875vw'
        },
        {
            h3: '20+ inner pages & 60+ illustrations were designed',
            p: 'Related to design system’s foundations, components, frameworks, design \n and development overviews.',
            img: 'images/casestudy_3/light_2.png',
            imgHeight: '80.052vw'
        },
        {
            h3: '& their responsives',
            p: '1920, 1366, 1024, 768, 375',
            img: 'images/casestudy_3/light_3.png',
            imgHeight: '31.25vw'
        },
        {
            h1: 'Dark mode is a must for devs, that’s a well established fact',
            p: 'For dark mode we used i2c’s design system and variables. ',
            img: 'images/casestudy_3/dark.png',
            imgHeight: '71.927vw'
        }
    ];
    const sliderImages = [
        {
            img: 'images/casestudy_3/slider_1.png',
        },
        {
            img: 'images/casestudy_3/slider_2.png',
        },
        {
            img: 'images/casestudy_3/slider_3.png'
        },
        {
            img: 'images/casestudy_3/slider_4.png'
        },
        {
            img: 'images/casestudy_3/slider_5.png'
        },
        {
            img: 'images/casestudy_3/slider_6.png'
        },
        {
            img: 'images/casestudy_3/slider_7.png',
        },
    ];
    const footer = {
        text_width: "18.208vw",
        text: "Craving software sorcery? Drop me a line, let's craft the recipe"
    };

    return (
        <div className={styles.design_main_wraper} id="scroll">
            <Navbar />
            <div className={styles.design_main}>
                <h1 className={styles.page_title}>Design System Management</h1>

                <Header header={header} />

                <div className={styles.vidoe1} style={{ position: 'relative', margin: '4.167vw 0 13.542vw', boxSizing: 'border-box', height: '50vw' }}>
                    <div className={styles.video1} style={{ width: '100%', height: '100%' }}>
                        <img src="images/casestudy_3/vid1_frame.png" alt=""></img>
                    </div>
                    <video autoPlay loop muted playsInline preload='none' poster='' width={'100%'} height={'100%'}
                        style={{ position: 'absolute', top: '4.948vw', left: '0', right: '0', margin: 'auto', width: '76.5vw', height: '42.969vw', objectFit: 'cover' }}
                    >
                        <source src={cloudVideos.Design_V1_Landing} type="video/mp4"></source>
                    </video>
                </div>

                <Header header={header2} />

                <div className={styles.vidoe1}
                    style={{ position: 'relative', margin: '13.542vw 0', boxSizing: 'border-box', border: '0.026vw solid #C7E2FF', background: '#F4FAFF' }}>
                    <video
                        autoPlay loop muted playsInline
                        preload='none' poster='' width={'100%'} height={'100%'}
                        style={{ padding: '2.083vw', boxSizing: 'border-box' }}
                    >
                        <source src={cloudVideos.Design_V2_Dashboard} type="video/mp4"></source>
                    </video>
                </div>

                <div className={styles.challenges}>
                    <h4>CHALLENGES</h4>
                    <h1>The previous design presented several challenges:</h1>
                    <div className={styles.card_wraper}>
                        {redesignCards.map((data) => (
                            <div className={styles.card_container}>
                                <div className={styles.card_img}>
                                    <img src={data.image} alt='' width={"100%"} height={"100%"} />
                                </div>
                                <h5>{data.title}</h5>
                                <p>{data.content}</p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.img_design}>
                    {design.map((val, index) => (
                        <div className={styles.design_box} key={index}>
                            <h1 style={{ marginBottom: index === 0 ? '1.875vw' : '0.417vw' }}>{val?.h1}</h1>
                            <h3>{val?.h3}</h3>
                            <p style={{ whiteSpace: "pre-line", marginBottom: index === 0 || index === 3 ? '2.604vw' : '1.563vw' }}>{val?.p}</p>
                            <img src={val.img} alt=""
                                style={{ height: val?.imgHeight, width: '100%', objectFit: 'cover', marginBottom: index === 2 || index === 3 ? '13.542vw' : '8.333vw' }}>
                            </img>
                        </div>
                    ))}
                </div>
                <div className={styles.slider_wraper}>
                    <DesignSlider sliderImages={sliderImages} sliderNum={1} />
                </div>
            </div>
            <div className={styles.auto_scroller_wraper}>
                <AutoScroller />
            </div>
            <ComnFooter userData={footer} />
        </div>
    );
}

export default DesignSystem;