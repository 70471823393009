import { useNavigate } from "react-router-dom";
import styles from "./commonExtension.module.css";
import Slider from './extensionSlider.js';
import Navbar from '../../components/navbar/navbar.js';
import ComnFooter from "../../components/caseStudiesFooter/comnFooter";

function CommonExtension() {
  const navigate = useNavigate();
  const userData = {
    text_width: "21.813vw",
    text: "Exploring the decentralized frontier? Let's build the future of dApps."
  };
  const images = [
    "images/casestudy4/autoSlider1.png",
    "images/casestudy4/autoSlider2.png",
    "images/casestudy4/autoSlider3.png",
    "images/casestudy4/autoSlider4.png",
    "images/casestudy4/autoSlider5.png",
    "images/casestudy4/autoSlider6.png",
    "images/casestudy4/autoSlider7.png",
    "images/casestudy4/autoSlider8.png",
  ];
  const extensionCard = [
    {
      card: "Lack of Trust in decentralization.",
      image: "/imagesVersion2/mobile/extension/card1.png",
    },
    {
      card: "Learning curve and hesitancy to engage with new technology/application.",
      image: "/imagesVersion2/mobile/extension/card2.png",
    },
    {
      card: "On-boarding non-technical users and millennials without overwhelming with information.",
      image: "/imagesVersion2/mobile/extension/card3.png",
    },
  ];
  const sliderImages = [
    {
      img: 'images/casestudy4/slider1.png',
      width: '52.812vw',
    },
    {
      img: 'images/casestudy4/slider2.png',
      width: '17.708vw',
    },
    {
      img: 'images/casestudy4/slider3.png',
      width: '17.708vw',
    },
    {
      img: 'images/casestudy4/slider4.png',
      width: '17.708vw',
    },
    {
      img: 'images/casestudy4/slider5.png',
      width: '17.708vw',
    },

    {
      img: 'images/casestudy4/slider6.png',
      width: '17.708vw',
    },
    {
      img: 'images/casestudy4/slider7.png',
      width: '17.708vw',
    },
    {
      img: 'images/casestudy4/slider8.png',
      width: '17.708vw',
    },
    {
      img: 'images/casestudy4/slider9.png',
      width: '17.708vw',
    },
    {
      img: 'images/casestudy4/slider10.png',
      width: '17.708vw',
    },
    {
      img: 'images/casestudy4/slider11.png',
      width: '17.708vw',
    },
  ];
  const application = [
    "Send/Recieve Send/Recieve",
    "Buy",
    "Locker (Nft holder)",
    "Coin Treasury",
    "Coin Minting",
    "Earn Coin",
    "Governance",
    "Counsels",
    "Profile",
    "Navigation Center",
  ];
  const extension = [
    "Send/Recieve Send/Recieve",
    "Buy",
    "Locker (Nft holder)",
  ];

  return (
    <div className={styles.main_div}>
      <div className={styles.navbar}>
          <Navbar/>
      </div>
            {/* ====================Header-Data============================= */}
            <div className={styles.section2_passport_detail}>
        <h3>ComnVault Extension</h3>
        {/* <div className={styles.detail}>
          <h5>Design Walkthrough</h5>
          <p>Getting things out and rolling</p>
          <h6>1 week</h6>
        </div> */}
        <div className={styles.overview}>
          <h4>OVERVIEW</h4>
          <p>ComnVault Extension is one of the solution that I came up with when building opus’s decentralized platform. Its objective is to enhance user comfort, foster trust, and guide them toward a more sophisticated and feature-rich platform—the ComnVault decentralized application (dApp), without overwhelming them.</p>
        </div>
        <div className={styles.role}>
          <h4>Fall 2023 - 1 week</h4>
          <p>UX/UI Designer, Strategist</p>
        </div>
      </div>
      <div className={styles.section1_cat}>
        <img src="\images\casestudy4\headerimg.png" alt="cat"></img>
      </div>
      {/* ===================Research================== */}
      <div className={styles.research_wraper}>
        <h1>Research</h1>
        <div className={styles.case_study} onClick={() => { navigate('/casestudy_6') }}>
          <h6>01 THE STUDENT EXPERIENCE</h6>
          <div className={styles.research_heading_wraper}>
            <h5 className={styles.research_heading}>Read the ComnVault Case Study</h5>
            <div className={styles.arrow}>
              <img src="\images\casestudy4\arrow.svg" alt="" width={"100%"} height={"100%"} />
            </div>
          </div>
          <p>Extension was designed to onboard non-technical people on Opus’s decentralized platform without overwhelming them. It was kept simple and to the point to get the users comfortable. Once they enter Opus’s ecosystem, they will be introduced with more features, and will be reinforced through rewards to educate themselves and engage further.</p>
        </div>
      </div>
      {/* ==================Problems===================== */}
      <div className={styles.problem_wraper}>
        <h1 className={styles.problem_heading}>Problems Targeted</h1>
        <p className={styles.problem_data}>These problems were concluded from the user interviews, mentioned in ComnVault Case Study linked above. </p>
        <div className={styles.card_wraper}>
          {extensionCard.map((data) => (
            <div className={styles.card_container}>
              <div className={styles.card_img}>
                <img src={data.image} alt='' width={"100%"} height={"100%"} />
              </div>
              <p>{data.card}</p>
            </div>
          ))}
        </div>
      </div>
      {/* ==========================Button-Slider=================== */}
      <div className={styles.slider1_wraper}>
        <h1 className={styles.problem_heading} style={{ marginLeft: "10.833vw" }}>User Flows: Decentralized On-Boarding</h1>
        <p className={styles.problem_data} style={{ marginLeft: "10.833vw", width: "100%" }}>Users are not forced and given both options either go decentralized or choose their familiar onboarding option through email (g-mail).</p>
        <div className={styles.slider1_container}>
          <Slider sliderImages={sliderImages} />
        </div>
      </div>
      {/* ====================FIlters================= */}
      <div className={styles.filter_wraper}>
        <div className={styles.options}>
          <h4>Filtered Options</h4>
          <p>To get users comfortable and build connection with the platform total number of options were reduced to just 30% in comparison to ComnVault Mobile Application.<br /><br />Once the user starts feeling a sense of attachment they can be funneled to the application which provides more functionalities that further deepen users connection within opus ecosystem. </p>
        </div>
        <div>
          <div className={styles.filter_data}>
            <div>
              <h4 className={styles.filter_heading}>Mobile Application</h4>
              {application.map((data) => (
                <div className={styles.application_data1}>
                  <p className={styles.application_data}>{data}</p>
                </div>
              ))}
            </div>
            <div>
              <h4 className={styles.filter_heading}>Extension</h4>
              {extension.map((data) => (
                <div className={styles.application_data1} style={{ marginLeft: "0" }}>
                  <p className={styles.application_data} style={{ marginLeft: "3.802vw" }}>{data}</p>
                </div>
              ))}
            </div>
          </div>
          {/* <div className={styles.chart}>
            <img src="\images\casestudy4\chart.png" alt="" width={"100%"} height={"100%"} />
          </div> */}
        </div>
      </div>
      {/* =====================Images===================== */}
      <h3 className={styles.slider_heading}>Home and the Fundamental do ables -</h3>
      <div className={styles.img_wraper}>
        <div className={styles.img_container}>
          <img src="\images\casestudy4\img1.png" alt="" width={"100%"} height={"100%"} />
        </div>
        <div className={styles.img_container}>
          <img src="\images\casestudy4\img2.png" alt="" width={"100%"} height={"100%"} />
        </div>
        <div className={styles.img_container}>
          <img src="\images\casestudy4\img3.png" alt="" width={"100%"} height={"100%"} />
        </div>
        <div className={styles.img_container}>
          <img src="\images\casestudy4\img4.png" alt="" width={"100%"} height={"100%"} />
        </div>
      </div>
      {/* ========================Slider================== */}
      <div className={styles.main_div}>
      <h3 className={styles.slider_heading}>Nitty Gritties  -</h3>
      <div className={styles.slider_wrapper}>
        <div className={styles.slider}>
          {images.map((image, index) => (
            <img className={styles.slider_img} key={index} src={image} alt="" />
          ))}
        </div>
      </div>
      {/* =======================Footer======================== */}
      <div className={styles.footer}>
        <div className={styles.next_data}>
          <h1 className={styles.content1_text}>What's next?</h1>
          <h5 className={styles.sub_heading}>TALKING TO PEOPLE</h5>
          <div className={styles.content2} style={{width: "100%", marginTop: "1.107vw"}}>
            <p style={{fontFamily: "DM Sans"}}>Moving forward, we aim to further refine the ComnVault Extension based on user feedback and iterative testing. Additionally, we will continue to explore innovative solutions to enhance user trust, streamline onboarding processes, and empower users to navigate the complexities of decentralized technologies with confidence.</p>
          </div>
        </div>
        <div className={styles.footer_data}>
          <div className={styles.content1}>
            <div className={styles.footer_img}>
              <img src="\images\tokenSale\footer.svg" alt="" width={'100%'} height={'100%'} />
            </div>
            <h1 className={styles.content1_text}>Let’s Befriend</h1>
          </div>
          <div className={styles.content2}>
            <p>I'm super friendly! Feel free to drop me an email, send a cheerful hello, or connect with me on Insta. Looking forward to hearing from you!</p>
          </div>
        </div>
      </div>
      <ComnFooter userData={userData}/>
    </div>
    </div>
  );
}

export default CommonExtension;
