import React, { useState } from 'react';
import style from './textComponent.module.css';
function TextComponent(props) {
    const [isShown, setIsShown] = useState(false);
    const [isShownImg, setisShownImg] = useState(false);
    const {
        smallHeading,
        bigHeading1,
        bigHeading2,
        discription,
        buttonText,
        mainWidth,
    } = props.userData;
    let timeout;

    return (
        <div className={style.mainDiv_text}>
            <div className={style.mainDiv} style={{ width: mainWidth }}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
            onClick={() => {
                if (bigHeading2 === 'Philosophy' && !isShownImg) {
                    setisShownImg(true);
                    clearInterval(timeout);
                    timeout = setTimeout(()=>{
                        setisShownImg(false);
                    }, 5000);
                }
            }}
            >
                <div className={style.smallHeading}>
                    <pre>
                        {smallHeading || 'divergent    -    quick    -    professional    -    non conventional'}
                    </pre>
                </div>
                <div className={style.bigHeading}>
                    <p>
                        {bigHeading1 || 'My   Design'} 
                        {/* {bigHeading2} */}
                    </p>
                </div>
                <div className={style.discription}>
                    {props.userData.centerDiv && <p>Currently working in Fintech, B2B, SaaS. </p>}
                    {
                        isShownImg ?
                            <div style={{height: '6.87vw'}}>
                                <img src="./imagesVersion2/workProcess.png" alt="" width={'100%'} height={'100%'} />
                            </div>
                            :
                            <p  style={{marginTop: '0.5vw'}}>
                                {discription || 'With standard UX design process as a solid foundation, ensuring attention to user needs and iterative refinement. What sets my approach apart is my artistic perspective that breathes life into every project. In the era of emerging technologies like AI, Web 3.0, and Blockchain, I break free from conventional design norms, adopting a holistic mindset. Enabling me to navigate the evolving landscape with adaptability.'}
                            </p>
                    }
                </div>
                <div className={style.buttonMainDiv}
                    style={buttonText === "" ? { display: "none" } : { display: "block" }}
                >
                    <div className={style.buttonText}>
                        <p>
                            {buttonText || 'My Working Process'}
                        </p>
                    </div>
                    <div className={style.moveableButtonDiv} style={{ marginLeft: isShown ? "0vw" : "11vw" }}>

                    </div>

                </div>

            </div>
        </div>
    )
}

export default TextComponent
