import style from './producer.module.css';
import Slider from '../../comp/slider';
import { redesignCards, data, pointData, boxData, screenData } from '../../mblData'
import Navbar from '../../comp/navbar/navbar';
import { cloudVideos } from '../../../videos';

function Producer() {
  const slider = [
    "/imagesVersion2/mobile/producer/slide1.svg", "/imagesVersion2/mobile/producer/slide2.svg",
    "/imagesVersion2/mobile/producer/slide3.svg", "/imagesVersion2/mobile/producer/slide4.svg",
    "/imagesVersion2/mobile/producer/slide5.svg",
  ];
  const sliderDimensions = {
    height: '55vw',
  }

  return (
    <div className={style.maindiv}>
      <div className={style.nav_container}>
        <Navbar />
      </div>
      <p className={`${style.top_heading} ${style.top_heading1}`}>Producer</p>
      <p className={`${style.top_heading} ${style.top_heading2}`}>Application</p>
      <div className={style.divider}>
        <img src='imagesVersion2/mobile/producer/divider.svg' alt=''></img>
      </div>
      <div className={style.container} style={{ marginTop: 0 }}>
        {/* <div className={style.text_wraper}>
          <p>CASE STUDY</p>
          <p>Fall 2023</p>
        </div> */}
        <p className={style.heading} style={{ fontSize: '3.077vw' }}>OVERVIEW</p>
        <p className={style.content}>Producer Persona, an AI desktop software, turns text
          into interactive videos. What sets <br />it apart? This AI has a fervor for stories,
          <br /> fiction, and the limitless world of <br />imagination.</p>
        <p className={style.heading} style={{ fontSize: '3.077vw' }}>Fall 2023 - 3 months</p>
        <div className={style.box}>
          <p>NOTE: DEPENDING ON YOUR INTERNET SPEED, THIS PAGE MAY NEED TIME TO LOAD.
            THE MP4s TAKE A MOMENT TO COME OUT OF HIBERNATION ;)</p>
        </div>
      </div>
      <div className={style.img_warper}>
        <img src='\imagesVersion2\mobile\producer\header1.svg' alt='' width={"100%"} height={"100%"} />
      </div>
      <p className={style.heading}>GOALS</p>
      <p className={style.content} style={{ marginTop: '5.128vw', width: '90.256vw' }}>To make storytelling fun and accessible to all.<br /><br />
        Foster a governance system, and build initial components of a self sustaining system
        that gets it rolling</p>
      <p className={style.heading}>Role - Team of 2 Designers </p>
      <p className={style.content}>Product Designer involving skills:<br /><br />
        Product Thinking, User Research, Interaction Design, Visual Design</p>
      <div className={style.line_img}>
        <img src='\imagesVersion2\mobile\producer\line1.svg' alt='' width={"100%"} height={"100%"} />
      </div>
      <div className={style.container} style={{ marginTop: "0" }}>
        <p className={style.para_product} style={{ marginTop: "7.949vw" }}>Think AI, think simplicity – </p>
        <p className={style.para_product} style={{ margin: "2.821vw 0 0 0", paddingBottom: "2.821vw" }}>it’s practically its middle name.</p>
      </div>
      <div className={style.redesign_cards}>
        {redesignCards.map((card) => (
          <div className={style.redesign_card}>
            <div className={style.re_card_img}>
              <img src={card.image} alt='' width={"100%"} height={"100%"} />
            </div>
            <h6>{card.title}</h6>
            <p>{card.content}</p>
          </div>
        ))}
      </div>
      <div className={style.re_card_img} style={{ marginTop: "34.913vw" }}>
        <img src="\imagesVersion2\mobile\producer\card1.svg" alt='' width={"100%"} height={"100%"} />
      </div>
      <div className={style.container} style={{ marginTop: "5.128vw" }}>
        <p className={style.detail} style={{ width: '84.695vw' }}>A lot to conceptualize on, Company’s Vision, Expectations, User’s Preferences & Flows</p>
        <p className={style.detail_box} style={{ marginTop: "5.128vw" }}>Vision was to create Text-to-video interface, Governance & Self Sustaining System, Sale Book NFTs, and Gamify it.</p>
        <div className={style.re_card_img} style={{ margin: "37.315vw 0 0 0" }}>
          <img src="\imagesVersion2\mobile\producer\card2.svg" alt='' width={"100%"} height={"100%"} />
        </div>
        <p className={style.goal} style={{ paddingTop: "6.154vw", fontSize: '2.051vw' }}>1st Iteration Overview</p>
        <p className={style.detail} style={{ lineHeight: "10.256vw", marginTop: "5.128vw", textAlign: "unset" }}>Laying the groundwork</p>
        <p className={style.detail_box} style={{ margin: "5.128vw 0 0 0", textAlign: "unset" }}>First Iteration had two modes. In Editor mode, users crafted stories; Community mode housed governance, counseling, and the marketplace. The aim was to distinctively organize and delineate features.</p>
      </div>
      <div className={style.chart}>
        <img src="\imagesVersion2\mobile\producer\chart.svg" alt='' width={"100%"} height={"100%"} />
      </div>
      <div className={style.container} style={{ margin: "23.846vw 2.051vw 0 1.91vw" }}>
        {data.map((data, i) => (
          <>
            <div className={style.img_container}>
              <img src={data.image} alt='' width={"100%"} height={"100%"} />
            </div>
            <p className={style.img_text} style={{ width: i >= 2 ? '80.769vw' : '57.308vw', marginTop: '0.769vw' }}>{data.content}</p>
          </>

        ))}
      </div>
      <div className={style.container} style={{ marginTop: "5.128vw" }}>
        <p className={style.detail} style={{ lineHeight: "10.256vw", marginTop: "5.128vw", textAlign: "unset" }}>Prototype en route to Grandmas: because if they get it, everyone will!</p>
        <p className={style.detail_box} style={{ margin: "5.128vw 0 0 0", textAlign: "unset", width: '84.615vw' }}>Our designer took 'working from home' to the next level by turning it into 'testing from home.' Because let's face it, the best insights come when you're in your PJs..</p>
        <div className={style.re_card_img} style={{ margin: "27.179vw 0 0 0" }}>
          <img src="\imagesVersion2\mobile\producer\eyeImg.svg" alt='' width={"100%"} height={"100%"} />
        </div>
        <p className={style.goal} style={{ paddingTop: "6.154vw", fontSize: '2.051vw' }}>Key Insights from Testings</p>
        <p className={style.detail} style={{ lineHeight: "10.256vw", marginTop: "5.128vw", textAlign: "unset" }}>Understanding Users</p>
        <p className={style.detail_box} style={{ margin: "5.128vw 0 0 0", textAlign: "unset", width: '84.615vw' }}>My teammate had a cozy testing session with two grandmas and two middle-aged uncles, letting them dive into the prototype from the comfort of their homes—where they'll likely use the product the most. After dissecting their questions, concerns, moods, and actions, we pinpointed the following pain points.</p>
        <div className={style.point_wraper}>
          {pointData.map((data) => (
            <div className={style.point}>
              <div className={style.point_img}>
                <img src={data.image} alt='' width={"100%"} height={"100%"} />
              </div>
              <h3>{data.title}</h3>
              <p>{data.data}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={style.experience}>
        <div className={style.container_ex}>
          <p className={style.heading_1}>Refinements</p>
          {boxData.map((data) => (
            <div className={style.box_ex}>
              <div className={style.image_ex}>
                <img src={data.img} alt="" width={"100%"} height={"100%"} />
              </div>
              <p className={style.box_text}>{data.title}</p>
              <p className={style.box_detail}>{data.detail}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={style.line_img}>
        <img src='\imagesVersion2\mobile\producer\line2.svg' alt='' width={"100%"} height={"100%"} />
      </div>
      <div className={style.container} style={{ marginTop: "0", marginLeft: '3.846vw', marginRight: '3.846vw', width: 'unset' }}>
        <div className={style.star_right}>
          <img src='\imagesVersion2\mobile\producer\starRight.svg' alt='' width={"100%"} height={"100%"} />
        </div>
        <p className={style.intro}>Introducing</p>
        <p className={style.narative}>Narrative AI</p>
        <div className={style.star_right} style={{ float: "unset" }}>
          <img src='\imagesVersion2\mobile\producer\starLeft.svg' alt='' width={"100%"} height={"100%"} />
        </div>
        <p className={style.detail_box} style={{ margin: "5.128vw 0 0 0", textAlign: "center", width: 'unset' }}>A place for people to visualize their imagination,
          building the future of entertainment
        </p>
      </div>
      <div className={style.img_container} style={{ margin: "10.256vw auto 0 auto" }}>
        <video autoPlay loop muted playsInline preload='none'
          poster='' width={'100%'} height={'100%'}>
          <source src={cloudVideos.PA_V1_NarrativeAI} type="video/mp4"></source>
        </video>
      </div>
      <div className={style.container} style={{ marginTop: "43.59vw" }}>
        <p className={style.detail} style={{ lineHeight: "10.256vw", marginTop: "5.128vw", textAlign: "unset" }}>Onboarding the decentralized</p>
      </div>
      {/* Slider */}
      <div className={style.slider} style={{ marginTop: "21.026vw" }}>
        <Slider slider={slider} dimensions={sliderDimensions} />
      </div>
      {/* Slider */}
      <div className={style.container} style={{ marginTop: "47.436vw" }}>
        <p className={style.suite}>Welcome to Opus Suite</p>
        <p className={style.detail} style={{ lineHeight: "10.256vw", marginTop: "30.513vw", textAlign: "unset" }}>The Anatomy of Dashboard</p>
        <p className={style.detail_box} style={{ margin: "5.128vw 0 0 0", textAlign: "unset" }}>Community mode was removed and was converted to opus suite, they will be introduced one by one:</p>
        <p className={style.detail_box} style={{ margin: "0", textAlign: "unset" }}>(a) Writer (text-to-video) </p>
        <p className={style.detail_box} style={{ margin: "0", textAlign: "unset" }}>(b) Marketplace</p>
        <p className={style.detail_box} style={{ margin: "0", textAlign: "unset" }}>(c) Governance</p>
        <p className={style.detail_box} style={{ margin: "0", textAlign: "unset" }}>(d) Wallet</p>
      </div>
      <div className={style.img_container} style={{ margin: "10.256vw auto 0 auto" }}>
        <img src='\imagesVersion2\mobile\producer\appDash.svg' alt='' width={"100%"} height={"100%"} />
      </div>
      <div className={style.container} style={{ marginTop: "35.128vw" }}>
        {/* {screenData.map((data)=>( */}
        <div className={style.screen1}>
          <img src={screenData[0].screen} alt='' width={"100%"} height={"100%"} />
        </div>
        <p className={style.screen_heading}>{screenData[0].heading}</p>
        <p className={style.screen_content}>{screenData[0].content}</p>
        {/* ))} */}
      </div>
      <div className={style.img_container} style={{ margin: "10.256vw auto 0 auto" }}>
        <video autoPlay loop muted playsInline preload='none'
          poster='' width={'100%'} height={'100%'}>
          <source src={screenData[1].videolink} type="video/mp4"></source>
        </video>
      </div>
      <div className={style.container} style={{ marginTop: "35.128vw" }}>
        {/* {screenData.map((data)=>( */}
        <div className={style.screen1}>
          <img src={screenData[1].screen} alt='' width={"100%"} height={"100%"} />
        </div>
        <p className={style.screen_heading}>{screenData[1].heading}</p>
        <p className={style.screen_content}>{screenData[1].content}</p>
        {/* ))} */}
      </div>
      <div className={style.img_container} style={{ margin: "10.256vw auto 0 auto" }}>
        <video autoPlay loop muted playsInline preload='none'
          poster='' width={'100%'} height={'100%'}>
          <source src={screenData[1].videolink} type="video/mp4"></source>
        </video>
      </div>
      <div className={style.container} style={{ marginTop: "35.128vw" }}>
        {/* {screenData.map((data)=>( */}
        <div className={style.screen1}>
          <img src={screenData[2].screen} alt='' width={"100%"} height={"100%"} />
        </div>
        <p className={style.screen_heading}>{screenData[2].heading}</p>
        <p className={style.screen_content}>{screenData[2].content}</p>
        {/* ))} */}
      </div>
      <div className={style.img_container} style={{ margin: "10.256vw auto 0 auto" }}>
        <video autoPlay loop muted playsInline preload='none'
          poster='' width={'100%'} height={'100%'}>
          <source src={screenData[2].videolink} type="video/mp4"></source>
        </video>
      </div>
      <div className={style.container} style={{ marginTop: "46.41vw", position: 'relative' }}>
        <p className={style.edit}>Editor</p>
        <img className={style.imagination_vec} src='/imagesVersion2/mobile/producer/imagination.svg' alt='' />
        <p className={style.edit_head} style={{ position: 'relative' }}>To the Sweet Part Making Imagination come to Life</p>
      </div>
      <div className={style.container} style={{ marginTop: "35.128vw" }}>
        <p className={style.screen_heading}>Editor Window</p>
        <p className={style.screen_content}>Dive into the simplicity – just write, let your imagination soar. Picture this: a starting window with a liner, revealing the magic of how it works and looks.</p>
      </div>
      <div className={style.img_container} style={{ margin: "10.256vw auto 0 auto" }}>
        <img src='\imagesVersion2\mobile\producer\view1.svg' alt='' width={'100%'} height={'100%'} />
      </div>
      <div className={style.container} style={{ marginTop: "35.128vw" }}>
        <p className={style.screen_heading}>Writing Book.</p>
        <p className={style.screen_content}>The Editor is designed to maintain a focus on text, while also incorporating book-building features like chapters and page management.</p>
      </div>
      <div className={style.img_container} style={{ margin: "10.256vw auto 0 auto" }}>
        <img src='\imagesVersion2\mobile\producer\view2.svg' alt='' width={'100%'} height={'100%'} />
      </div>
      <div className={style.container} style={{ marginTop: "46.41vw" }}>
        <p className={style.edit} style={{ width: "25.641vw" }}>The work window</p>
        <p className={style.edit_head} style={{ width: "76.923vw" }}>Anatomy of the text Panel</p>
        <p className={style.list}>Comprised of 3 parts:</p>
        <p className={style.list}>(a) Header </p>
        <p className={style.list}>(b) Text Field</p>
        <p className={style.list}>(c) Bottom Bar</p>
        <p className={style.screen_heading} style={{ marginTop: "20vw" }}>Header and Text Field</p>
        <p className={style.screen_content} style={{ marginTop: "4.872vw" }}>The header proudly wears the project's title and a nifty search bar.</p>
        <p className={style.screen_content} style={{ marginTop: "5.897vw" }}>The text field effortlessly keeps you in the narrative loop, displaying your current chapter prominently at the top, and sneakily slipping the page number to the bottom right – because even chapters need their spotlight moments!</p>
        <div className={style.edit2} style={{ width: '89.487vw', height: '126.923vw' }}>
          <img src='\imagesVersion2\mobile\producer\edit2.svg' alt='' width={"100%"} height={"100%"} />
        </div>
        <p className={style.screen_heading} style={{ marginTop: "20vw" }}>Search Field</p>
        <p className={style.screen_content} style={{ marginTop: "4.872vw" }}>Simply type in your desired words, and let the search option unveil a treasure trove of relevant results, complete with chapter and page references.</p>
        <div className={style.edit2} style={{ margin: "0 0 0 33vw" }}>
          <video autoPlay loop muted playsInline preload='none'
            poster='imagesVersion2/mobile/producer/edit3.svg' width={'100%'} height={'100%'}>
            <source src={cloudVideos.PA_Search} type="video/mp4"></source>
          </video>
        </div>
        <p className={style.screen_heading} style={{ marginTop: "20vw" }}>Customizing text & font</p>
        <p className={style.screen_content} style={{ marginTop: "4.872vw" }}>Highlighting the text, allows you to personalize it with the font and style that perfectly matches your taste.</p>
        <div className={style.edit2} style={{ margin: "auto" }}>
        <video autoPlay loop muted playsInline preload='none'
          poster='imagesVersion2\mobile\producer\edit4.svg' width={'100%'} height={'100%'}>
          <source src={cloudVideos.PA_Prologue} type="video/mp4"></source>
        </video>
        </div>
        <div className={style.addBtn} style={{ marginTop: "40.769vw" }}>
          <img src='\imagesVersion2\mobile\producer\addBtn.svg' alt='' width={"100%"} height={"100%"} />
        </div>
        <div className={style.addBtn} style={{ marginTop: "5.128vw" }}>
          <img src='\imagesVersion2\mobile\producer\pageBreak.svg' alt='' width={"100%"} height={"100%"} />
        </div>
        <p className={style.screen_heading} style={{ marginTop: "2.821vw" }}>Bottom bar</p>
        <p className={style.screen_content} style={{ marginTop: "4.872vw" }}>The bottom bar is like a command center with four options – effortlessly create chapters and pages. Plus, the left menu option is your backstage pass to manage them all. Oh, and there's a play button too, dive into the action right from your current page!"</p>
        <div className={style.edit2}>
        <video autoPlay loop muted playsInline preload='none'
          poster='imagesVersion2\mobile\producer\edit5.svg' width={'100%'} height={'100%'}>
          <source src={cloudVideos.PA_Story} type="video/mp4"></source>
        </video>
        </div>
      </div>
      <div className={style.edit2} style={{ width: '89.744vw', height: '129.231vw', marginLeft: '5.128vw' }}>
        <img src='\imagesVersion2\mobile\producer\edit6.svg' alt='' width={"100%"} height={"100%"} />
      </div>
      <div className={style.whiteboard}>
        <h5>HYPER CUSTOMIZED</h5>
        <div className={style.white_heading}>
          <img src='imagesVersion2/mobile/producer/whiteboard_vec.svg' alt='' />
          <h2>Whiteboard, Flush out ideas</h2>
        </div>
        <p>WB was created to mimic old school exploration of ideas, so users can write stories without the need for GPU muscle or enduring the agony of old hardware.</p>
      </div>
      <div style={{ marginTop: "28.205vw", height: "77.179vw" }}>
        <img src='\imagesVersion2\mobile\producer\black1.svg' alt='' width={"100%"} height={"100%"} />
      </div>
      <div className={style.blank}>
        <img src='\imagesVersion2\mobile\producer\edit7.svg' alt='' width={"100%"} height={"100%"} />
      </div>
      <div style={{ height: "77.179vw" }}>
        <img src='\imagesVersion2\mobile\producer\blank2.svg' alt='' width={"100%"} height={"100%"} />
      </div>
      <div className={style.blank}>
        <img src='\imagesVersion2\mobile\producer\edit8.svg' alt='' width={"100%"} height={"100%"} />
      </div>
      <div style={{ height: "77.179vw" }}>
        <img src='\imagesVersion2\mobile\producer\blank3.svg' alt='' width={"100%"} height={"100%"} />
      </div>
      <div className={style.blank}>
        <img src='\imagesVersion2\mobile\producer\edit9.svg' alt='' width={"100%"} height={"100%"} />
      </div>
      <div className={style.back}>
        <h1 className={style.back_head}>What's next?</h1>
        <p className={style.back_para} style={{ marginTop: "14.969vw" }}>ONTO THE OTHER PLATFORM</p>
        <p className={style.back_para} style={{ marginTop: "5.031vw" }}>Plotting the course for Governance, Counsels, Marketplace, and Economy System – our next design adventure. Turning it into a game trhough user interviews and research, because who said building the future can't be a bit playful?</p>
        <p className={style.back_para} style={{ marginTop: "25.641vw" }}>HOPING FOR A BRIGHTER FUTURE</p>
        <p className={style.back_para} style={{ marginTop: "5.031vw" }}>I envision a version where users get their hands on it, unleashing their inner goat mode of imagination. Now, toss in some VR, and voila! We'll be proudly proclaiming, 'The future has officially arrived!</p>
      </div>
      <div className={style.back1}>
        <h1 className={style.back_head}>Final Notes</h1>
        <p className={style.back_para1} style={{ marginTop: "14.969vw" }}>I love exploring new ideas, even if they’re a little risky.</p>
        <p className={style.back_para} style={{ width: '81.231vw', marginTop: "5.031vw", color: "#6F6F6F" }}>It’s important to know how to scale back, as design falters without the “how” of implementation, but preemptively limiting your imagination is as equally a disservice as an ungrounded concept. I believe that design thrives when it looks into the future, and my hope with this project is to showcase my love for vision-making.</p>
        <p className={style.back_para1} style={{ marginTop: "12.051vw" }}>If you have questions on any part of this case study, I’m happy to share more about my design process. Feel free to reach out: I’d love to chat!</p>
        <p className={style.back_para} style={{ marginTop: "5.128vw" }}>Until then, thank you for reading!</p>
        <p className={style.back_para} style={{ marginTop: "5.031vw" }}>abdullahqamar600@gmail.com</p>
      </div>
    </div>
  );
}

export default Producer;



