import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export default function PrivateRoute({ children }) {
  const location = useLocation();

  const loginData = JSON.parse(localStorage.getItem('loginData'));
  const isAuthenticated = (loginData && Date.now() - loginData.timestamp < 86400000) || true;

  return isAuthenticated ? children : <Navigate to={`/login?redirect=${location.pathname}`} />;
}
