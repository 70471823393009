import React, { useState, useEffect } from "react";
import style from './App.module.css';
import TokenSale from "./casestudies/tokenSale_8/tokendSale.js";
import AboutPage from "./aboutPage/aboutPage.js";
import LandingPage from "./landingPageVersion2/landingPageV2.js";
import OpusPassport from "./casestudies/opusPassport_1/opusPassport.js";
import OpusWebsite from "./casestudies/opuswebsite_2/opusWebsite.js";
import CommonVault from "./casestudies/commonextension_4/commonExtension.js";
import CommonExtension from "./casestudies/comnvault_6/comnVault.js";
import YipWebsite from "./casestudies/yipWebsite/yipWebsite.js";
import ProducerApp from "./casestudies/producerapp_5/producerApp.js";
import LandingPageMbl from "./mobile/landingPage/landingPage.js";
import OpusPassportMbl from "./mobile/casestudies/opusPassort/opusPassport.js";
import TokenMbl from "./mobile/casestudies/tokenSale/tokenSales.js";
import AboutMbl from "./mobile/about/about.js";
import ExtensionMbl from "./mobile/casestudies/comnExtension/comnExtension.js"
import VaultMbl from "./mobile/casestudies/comnValut/comnVault.js"
import DesignSystemMbl from "./mobile/casestudies/designSystem/designSystem.js"
import YipMbl from "./mobile/casestudies/yipWebsite/yipWebsite.js";
import ProducerAppMbl from "./mobile/casestudies/producer/producer.js";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Footer from "./mobile/footer/footer.js";
import ScrollToTop from "./scrollBox.js";
import DesignSystem from "./casestudies/designSystem_3/designSystem.js";
import PrivateRoute from "./PrivateRoute.js";
import Login from "./login/login.js";
import Admin from "./Admin/admin.js";


function App() {
  const resolution = window.innerWidth;
  const isDesktop = resolution >= 1024;
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    // Add event listener for mouse movement
    const onMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", onMouseMove);

    const cleanup = () => {
      window.removeEventListener("mousemove", onMouseMove);
    };

    return cleanup;
  }, []);

  return (
    <div className={style.maindiv}>
      <div>
        {
          isDesktop ?
            <div className={style.desktop}>
              <div
                className={style.custom_cursor}
                style={{
                  left: `${position.x}px`,
                  top: `${position.y}px`,
                }}
              >
                <svg width="57" height="48" viewBox="0 0 57 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_d_3330_26960)">
                    <path d="M6.92188 11.5365V2.00391L13.1532 17.9285C13.5368 18.9087 14.66 19.3716 15.6228 18.9462L18.8328 17.5278C19.8746 17.0675 21.0703 17.1128 22.0743 17.6506L36.8186 25.5494C37.4027 25.8623 38.0981 25.8894 38.7048 25.6231L50.3808 20.4971V31.6722C50.3808 31.9068 50.2476 32.121 50.0373 32.2249L40.617 36.8769C38.9226 37.7136 36.9188 37.6304 35.2996 36.6559L22.997 29.2515C21.8933 28.5873 20.5505 28.4508 19.3358 28.8796L15.9598 30.0711C14.535 30.574 12.9698 29.844 12.4393 28.4293L7.68723 15.7572C7.18112 14.4076 6.92188 12.9779 6.92188 11.5365Z" fill="#E37729" />
                  </g>
                  <path d="M41.4414 14.1771V7.24219L35.4958 9.93185C35.271 10.0335 35.2508 10.3449 35.4606 10.4747L41.4414 14.1771Z" fill="#B6774A" />
                  <path d="M13.2677 18.2405L7.04722 2.62831C6.59542 1.4944 7.57844 0.310291 8.77612 0.545733L41.0912 6.89828C41.3879 6.9566 41.432 7.3628 41.1547 7.48337L35.5098 9.93767C35.2822 10.0366 35.2589 10.3502 35.4693 10.4817L50.0136 19.5719C50.4331 19.834 50.3885 20.4586 49.9361 20.6585L38.9846 25.4975C38.2033 25.8427 37.3065 25.8087 36.5535 25.4054L21.6794 17.4371C20.9264 17.0337 20.0296 16.9997 19.2483 17.345L15.3222 19.0798C14.5233 19.4328 13.591 19.0519 13.2677 18.2405Z" fill="#FFCAA4" />
                  <defs>
                    <filter id="filter0_d_3330_26960" x="0.921875" y="0.00390625" width="55.4609" height="47.4453" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="3" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0.890196 0 0 0 0 0.466667 0 0 0 0 0.160784 0 0 0 0.25 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3330_26960" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3330_26960" result="shape" />
                    </filter>
                  </defs>
                </svg>

              </div>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/casestudy_1" element={<OpusPassport />} />
                  <Route path="/casestudy_2" element={<OpusWebsite />} />
                  <Route path="/casestudy_3" element={<PrivateRoute><DesignSystem /></PrivateRoute>} />
                  <Route path="/casestudy_4" element={<CommonVault />} />
                  <Route path="/casestudy_5" element={<ProducerApp />} />
                  <Route path="/casestudy_6" element={<CommonExtension />} />
                  <Route path="/casestudy_7" element={<YipWebsite />} />
                  <Route path="/casestudy_8" element={<TokenSale />} />
                  <Route path="/aboutUs" element={<AboutPage />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
                <ScrollToTop />
              </BrowserRouter>

            </div> :
            <div className={style.mobile}>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<LandingPageMbl />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/casestudy_1" element={<OpusPassportMbl />} />
                  <Route path="/casestudy_3" element={<PrivateRoute><DesignSystemMbl /></PrivateRoute>} />
                  <Route path="/casestudy_4" element={<ExtensionMbl />} />
                  <Route path="/casestudy_5" element={<ProducerAppMbl />} />
                  <Route path="/casestudy_6" element={<VaultMbl />} />
                  <Route path="/casestudy_7" element={<YipMbl />} />
                  <Route path="/casestudy_8" element={<TokenMbl />} />
                  <Route path="/aboutUs" element={<AboutMbl />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
                <ScrollToTop />
                <Footer />
              </BrowserRouter>

            </div>
        }
      </div>
    </div>
  );
}

export default App;