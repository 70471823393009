import React, { useState } from 'react';
import style from './feedbackScroller.module.css';

const FeedbackScroller = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);

  const sliderData = [
    {
        image: 'images/casestudy_3/comma.png',
        description: "As someone who works on both web and mobile, the enhanced DSM has made a massive difference. The consistency across platforms and the logical component structure save time and reduce errors. Plus, the developer-focused documentation is a nice touch, showing a real understanding of our needs.",
        userImage: 'images/casestudy_3/user_1.png',
        userName: 'Muzaffar Iqbal',
        userTitle: 'Sr. Developer'
    },
    {
        image: 'images/casestudy_3/comma.png',
        description: "The new i2c DSM is a huge improvement over the previous version! The componentized structure and updated documentation make it so much easier to implement designs accurately. I can finally focus on coding rather than guessing design details. It’s clear and efficient—a real game changer.",
        userImage: 'images/casestudy_3/user_2.png',
        userName: 'Waleed Baig',
        userTitle: 'Pr. Developer'
    },
    {
        image: 'images/casestudy_3/comma.png',
        description: "I love the attention to detail in the revamped DSM. Every component is now intuitive and feels cohesive, which saves a lot of time. The updated guidelines have been especially helpful for responsive design, making my work smoother across devices. It feels built for developers, which I appreciate.",
        userImage: 'images/casestudy_3/user_3.png',
        userName: 'Maaz Haris',
        userTitle: 'Sr. Developer'
    }
];
  const maxIndex = sliderData.length - 1;

  // Each slide translates the entire viewport width (100vw) per slide
  const translateValue = (index) => index * 87.436;

  const handlePrevClick = () => {
    if (currentIndex > 0) setCurrentIndex(currentIndex - 1);
  };

  const handleNextClick = () => {
    if (currentIndex < maxIndex) setCurrentIndex(currentIndex + 1);
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.clientX);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const diff = e.clientX - startX;
      if (diff > 100 && currentIndex > 0) {  // Dragging right
        handlePrevClick();
        setIsDragging(false);
      } else if (diff < -100 && currentIndex < maxIndex) {  // Dragging left
        handleNextClick();
        setIsDragging(false);
      }
    }
  }; 
  
  // Touch event handlers for mobile
  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (isDragging) {
      const diff = e.touches[0].clientX - startX;
      if (diff > 100 && currentIndex > 0) {  // Swiping right
        handlePrevClick();
        setIsDragging(false);
      } else if (diff < -100 && currentIndex < maxIndex) {  // Swiping left
        handleNextClick();
        setIsDragging(false);
      }
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  return (
    <div className={style.slider_main_div}>
      <div
        className={style.slider_wraper}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div
          className={style.slider_scroller}
          style={{ transform: `translateX(-${translateValue(currentIndex)}vw)`, width: `${87.436 * sliderData.length}vw` }}>
          {sliderData.map((item, index) => (
            <div key={index} className={style.slide}>
              <img src={item.image} alt="slide img" className={style.mainImage} />
              <p className={style.slideDescription}>{item.description}</p>
              <div className={style.userInfo}>
                <img src={item.userImage} alt="User" className={style.userImage} />
                <div>
                  <p className={style.userName}>{item.userName}</p>
                  <p className={style.userTitle}>{item.userTitle}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Dots below the slider */}
      <div className={style.slider_dots}>
        {sliderData.map((_, index) => (
          <span
            key={index}
            className={`${style.dot} ${currentIndex === index ? style.active : ''}`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default FeedbackScroller;
