import ComnFooter from "../../components/caseStudiesFooter/comnFooter";
import Navbar from "../../components/navbar/navbar";
import { cloudVideos } from "../../videos";
import styles from "./yipWebsite.module.css";

function OpusPassport() {
  const userData = {
    text_width: "19.813vw",
    text: "Require a data based Strategic Shift? Drop me a hellloooo."
  };
  const cardsData = [
    {
      heading: "Shift to Consultative Model",
      img: "/images/yitWebsite/phoneIcon.svg",
      text: "emphasizing expert guidance through personalized phone consultations and built competitive differentiation.",
    },
    {
      heading: "Strong Identity",
      img: "/images/yitWebsite/icon.svg",
      text: 'from "Search & Compare" to "Consult Us," clearly communicating YIP commitment to problem-solving via phone.',
    },
    {
      heading: "Clear User Journey",
      img: "/images/yitWebsite/icon2.svg",
      text: " with strategic messaging and a well-defined funnel, guiding users to a primary call-to-action—contact YIP.",
    },
    {
      heading: "Visual Language",
      img: "/images/yitWebsite/icon3.svg",
      text: "imprinting tech experts through motion design rather than extensive design.",
    },
  ];
  const card1 = [
    "Cluttered User Interface",
    "Dull and Bad Visual Language",
    "Confusing Hierarchy",
    "Competitive Disadvantage",
    "Hassel of Searching and Comparing",
    "Too Much Text",
    "Tech-Centric Messaging"
  ];
  const card2 = [
    "Search & Compare Tools",
    "Existing User Base",
    "Information Availability",
  ];
  return (
    <div className={styles.main_div}>
      <div className={styles.navbar}>
        <Navbar />
      </div>
      {/* ==================================header-data========================= */}
      <div className={styles.section2_passport_detail}>
        <h3>YIP Website Revamp</h3>
        {/* <div className={styles.detail}>
          <h5>Design Walkthrough</h5>
          <p>Evolution of User’s Journey: YIP's Consultancy</p>
          <h6>1 month</h6>
        </div> */}
        <div className={styles.overview}>
          <h4>OVERVIEW</h4>
          <p>YIP’s (YoutInternetProvider) is a telecommunication service provider who sells 3rd party internet services through telecommunication. It’s redesign journey aimed to redefine direction, user experience and seo to generate traffic.</p>
        </div>
        <div className={styles.role}>
          <h4>Fall 2022- 2 months</h4>
          <p>UX/UI Designer, Brand Identity Designer</p>
        </div>
      </div>
      {/* =====================header-image================== */}
      <div className={styles.header_img}>
        <video autoPlay loop muted playsInline preload='none' poster='/images/yitWebsite/yip1.png' width={'100%'} height={'100%'}>
          <source src={cloudVideos.YIPHome} type="video/mp4"></source>
        </video>
      </div>
      {/* =====================Old_design_cards================ */}
      <div className={styles.old_design}>
        <h3>Old Design :</h3>
        <div style={{ display: "flex", marginTop: "2.604vw" }}>
          <div className={styles.card1}>
            <h3>Issues</h3>
            {card1.map((data) => (
              <p>{data}</p>
            ))}
          </div>
          <div className={styles.card1}>
            <h3>Strong Point</h3>
            {card2.map((data) => (
              <p>{data}</p>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.header_img}>
        <video autoPlay loop muted playsInline preload='none' poster='/images/yitWebsite/yip1.png' width={'100%'} height={'100%'}>
          <source src={cloudVideos.Yip_V2} type="video/mp4"></source>
        </video>

      </div>
      <div className={styles.old_design}>
        <div className={styles.data_analysis}>
          <div className={styles.analysis_img}>
            <img src="\images\yitWebsite\analysisImg.svg" alt="" width={"100%"} height={"100%"} />
          </div>
          <h4 className={styles.analysis_text}>Data Analysis</h4>
        </div>
        <p style={{ marginTop: "2.573vw", marginLeft: "1.8vw" }}>User Interaction :</p>
        <div className={styles.data_analysis_img}>
          <img src="\images\yitWebsite\dataAnalysis.svg" alt="" width={"100%"} height={"100%"} />
        </div>
        <div className={styles.data_analysis} style={{ marginTop: "3.125vw" }}>
          <div className={styles.analysis_img}>
            <img src="\images\yitWebsite\marketImg.svg" alt="" width={"100%"} height={"100%"} />
          </div>
          <h4 className={styles.analysis_text}>Market Analysis</h4>
        </div>
        <p style={{ marginTop: "2.24vw", marginLeft: "1.8vw" }}>Competitors:</p>
        <div className={styles.data_analysis_img}>
          <img src="\images\yitWebsite\marketCard.svg" alt="" width={"100%"} height={"100%"} />
        </div>
        <p style={{ marginLeft: "1.8vw" }}>SWOT Analysis:</p>
        <div className={styles.data_analysis_img} style={{ height: "26.469vw" }}>
          <img src="\images\yitWebsite\swot.svg" alt="" width={"100%"} height={"100%"} />
        </div>
        <div className={styles.data_analysis} style={{ marginTop: "3.125vw" }}>
          <div className={styles.analysis_img}>
            <img src="\images\yitWebsite\businessImg.svg" alt="" width={"100%"} height={"100%"} />
          </div>
          <h4 className={styles.analysis_text}>Business Goals</h4>
        </div>
        {/* <p>Competitors:</p> */}
        <div className={styles.data_analysis_img}>
          <img src="\images\yitWebsite\buisnessbelowImage.svg" alt="" width={"100%"} height={"100%"} />
        </div>
      </div>
      {/* ===================Customer======================= */}
      <div className={styles.customer_wraper}>
        <h6 className={styles.data}>Pointing it Out</h6>
        <div className={styles.customer_heading}>
          <div className={styles.customer_img}>
            <img src="\images\yitWebsite\cutomerIcon.svg" alt="" width={"100%"} height={"100%"} />
          </div>
          <h1>Customers/Users Demograph:</h1>
        </div>
        <div className={styles.customer_container}>
          <h6 className={styles.data} style={{ marginLeft: "0" }}>Shying</h6>
          <h3 className={styles.customer_heading2}>Adults & The Responsible Ones</h3>
          <p className={styles.customer_para}>Through previous call’s data I got to know about the audience who are primarily individuals aged 30 to 70 who  shy away from the word of “tech” and value simplicity, personalized assistance, a straightforward internet setup process and those who .</p>
        </div>

        <h6 className={styles.data} style={{ marginTop: "6.215vw", marginLeft: "4.49vw" }}>FLUSHING OUT IDEAS</h6>
        <div className={styles.customer_heading}>
          <div className={styles.customer_img1}>
            <img src="\images\yitWebsite\brainIcon.svg" alt="" width={"100%"} height={"100%"} />
          </div>
          <h1>Brain Storming</h1>
        </div>
        <div style={{ display: "flex", gap: "0.625vw" }}>
          <div className={styles.customer_container1}>
            <h3 className={styles.customer_heading2}>Proposed Strategy</h3>
            <p className={styles.customer_para}>Proposed shift from self-service to a consultative model, changing its messaging from "Search & Compare" to "Consult Us." Leveraging its telecommunications expertise, I made it evident that the platform is committed to delivering personalized guidance.</p>
          </div>
          <div className={styles.customer_container1}>
            <h3 className={styles.customer_heading2}>Key Decisions</h3>
            <p className={styles.customer_para}>The central decision was transitioning users from an online self-service model to a phone-based consultancy approach. Users were encouraged to reach out to YIP via a phone call to receive expert guidance and connect with service providers.</p>
          </div>
        </div>
      </div>

      {/* ============================Redesign=========================== */}
      <div className={styles.main_div}>
        <div className={styles.redesign_wraper}>
          <div className={styles.redesign_heading}>
            <div className={styles.redesign_img}>
              <img src="\images\yitWebsite\redesign.svg" alt="" width={"100%"} height={"100%"} />
            </div>
            <h3>Redesign</h3>
          </div>
          <p className={styles.redesign_heading_detail}>These problems were concluded from the user interviews, through on call customer surveys. </p>
          <div className={styles.cards_wraper}>
            {cardsData.map((data, i) => (
              <div className={styles.card_container}>
                <div className={styles.card_img}>
                  <img src={data.img} alt="" width={"100%"} height={"100%"} />
                </div>
                <h6 className={styles.card_inside_heading}>{data.heading}</h6>
                <h5 className={styles.card_para} style={{ marginTop: i === 0 ? '0.521vw' : '1.563vw' }}>{data.text}</h5>

              </div>
            ))}
          </div>
        </div>


        {/* ========================Before-footer-imgaes======================= */}
        <div className={styles.video1} style={{ height: "36.823vw", marginTop: "10.573vw" }}>
          <video autoPlay loop muted playsInline preload='none' style={{ objectFit: 'cover' }}
            poster='/images/yitWebsite/YipPic2nd.PNG' width={'100%'} height={'100%'}>
            <source src={cloudVideos.Yip_V1} type="video/mp4"></source>
          </video>
        </div>
        <div className={styles.main_div}>
          <div className={styles.video1} style={{ height: "42.823vw", marginTop: "2.604vw" }}>
            <img src='\images\yitWebsite\image1.svg' alt='' width={'100%'} height={'100%'} />
          </div>
          <div className={styles.video1} style={{ height: "42.823vw", marginTop: "2.604vw" }}>
            <img src='\images\yitWebsite\image2.svg' alt='' width={'100%'} height={'100%'} />
          </div>
          <div className={styles.video1} style={{ height: "42.823vw", marginTop: "2.604vw" }}>
            <img src='\images\yitWebsite\image3.svg' alt='' width={'100%'} height={'100%'} />
          </div>
          <div className={styles.video1} style={{ height: "42.823vw", marginTop: "2.604vw" }}>
            <img src='\images\yitWebsite\image4.svg' alt='' width={'100%'} height={'100%'} />
          </div>
          <div className={styles.main_div}>
            <div className={styles.video1} style={{ height: "42.823vw", marginTop: "2.604vw" }}>
              <img src='\images\yitWebsite\image5.svg' alt='' width={'100%'} height={'100%'} />
            </div>
            <div className={styles.video1} style={{ height: "42.823vw", marginTop: "2.604vw" }}>
              <img src='\images\yitWebsite\image6.svg' alt='' width={'100%'} height={'100%'} />
            </div>
            <div className={styles.video1} style={{ height: "42.823vw", marginTop: "2.604vw" }}>
              <img src='\images\yitWebsite\image7.svg' alt='' width={'100%'} height={'100%'} />
            </div>
            <div className={styles.video1} style={{ height: "27.823vw", marginTop: "2.604vw" }}>
              <img src='\images\yitWebsite\image8.svg' alt='' width={'100%'} height={'100%'} />
            </div>
            <div className={styles.video1} style={{ height: "27.823vw", marginTop: "2.604vw" }}>
              <img src='\images\yitWebsite\image9.svg' alt='' width={'100%'} height={'100%'} />
            </div>
            <div className={styles.video1} style={{ height: "42.823vw", marginTop: "2.604vw" }}>
              <img src='\images\yitWebsite\image10.svg' alt='' width={'100%'} height={'100%'} />
            </div>
            <div className={styles.video1} style={{ height: "76.823vw", marginTop: "2.604vw" }}>
              <img src='\images\yitWebsite\image11.svg' alt='' width={'100%'} height={'100%'} />
            </div>

            {/* ============================Footer============================= */}
            <div className={styles.footer}>
              <div className={styles.footer_data}>
                <div className={styles.content1}>
                  <div className={styles.footer_img}>
                    <img src="\images\tokenSale\footer.svg" alt="" width={'100%'} height={'100%'} />
                  </div>
                  <h1 className={styles.content1_text}>Let’s Befriend</h1>
                </div>
                <div className={styles.content2}>
                  <p>I'm super friendly! Feel free to drop me an email, send a cheerful hello, or connect with me on Insta. Looking forward to hearing from you!</p>
                </div>
              </div>
            </div>
            <div className={styles.comn_footer}>
              <ComnFooter userData={userData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpusPassport;
