import React, { useState, useEffect } from "react";
import ContentSlot from "../componentsVersion2/textComponent/textComponent.js";
import ProjectCard from "../componentsVersion2/videoComponent/videoComponentShadow.js";
import ProjectCard2 from "../componentsVersion2/imageComponent/imageComponent.js";
import { cardData, contentData } from "../data";
import styles from "./landingPageV2.module.css";
import { Link } from "react-router-dom";
import ContactUs from "../components/contactUsButton/contactUs";
import Nameloading from "../components/nameloading/nameLoading.js";

function LandingPage() {
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [mouseEvent, setmouseEvent] = useState(false);
  const [scaleValue, setscaleValue] = useState("1.3");
  const [isVisible, setIsVisible] = useState(true);
  const [nameAnimationDisplay, setnameAnimationDisplay] = useState("block");

  useEffect(() => {
    function onPageLoad() {

      // Autoplay videos after preload
      const videoElements = document.querySelectorAll('video');
      videoElements.forEach(video => {
        video.play().catch((error) => {
          console.error("Error trying to play video:", error);
        });
      });
      
      setIsVisible(false);
      let timeoutId = setTimeout(() => {
        clearInterval(timeoutId);
        setTranslateX(0);
        setTranslateY(0);
        setscaleValue("2");
        setnameAnimationDisplay("none");
        timeoutId = setTimeout(() => {
          setmouseEvent(true);
          clearInterval(timeoutId);
        }, 1000);
      }, 2000);
    }
    // Check if the page is already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
    }

    // Cleanup event listener when the component unmounts
    return () => window.removeEventListener("load", onPageLoad);
  }, []);

  const handleMouseMove = (e) => {
    const movingDiv = document.getElementById('moving-div');
    const maxY = movingDiv?.clientHeight;
    const maxX = movingDiv?.clientWidth;
    const moveY = -(e.pageY - window.innerHeight / 2) * 4;
    const moveX = -(e.pageX - window.innerWidth / 2) * 4;


    // Limit translateX and translateY values
    const limitedTranslateX = Math.max(-(maxX / 2), Math.min((maxX / 2) - 1, moveX));
    const limitedTranslateY = Math.max(-(maxY / 2 + (maxY - window.innerHeight)), Math.min((maxY / 2) - 1, moveY));

    setTranslateX(limitedTranslateX);
    setTranslateY(limitedTranslateY);
  };

  return (
    <section onMouseMove={mouseEvent ? handleMouseMove : null}>
      <div
        className={
          isVisible
            ? styles.nameLoading
            : `${styles.nameLoading} ${styles.fadeOut}`
        }
        style={{ display: nameAnimationDisplay }}
      >
        <Nameloading />
      </div>
      <div className={styles.main_container}>
        <div
          className={styles.moving_div}
          id="moving-div"
          style={{ position: 'absolute', top: translateY, left: translateX, scale: scaleValue }}
        >
          <div className={styles.navbar_main_div}>
            <div className={styles.logo}>
              <img
                style={{ position: 'absolute', top: '0', left: '0' }}
                alt=""
                src=".\images\nameloading\abdullahlogo.png"
                width={"100%"}
                height={"100%"}
              />
            </div>
          </div>
          <div className={styles.grid_image}>
            <img src="./imagesVersion2/grid.svg" alt="" width={"100%"} height={"100%"} />
          </div>
          <div className={styles.topLeftLabel}>
            <img src="./imagesVersion2/topleftlabel.png" alt="" width={'100%'} height={'100%'} />
          </div>
          <div className={styles.bottomLeftLabel}>
            <img src="./imagesVersion2/bottomleftlabel.png" alt="" width={'100%'} height={'100%'} />
          </div>
          <div className={styles.topRightLabel}>
            <img src="./imagesVersion2/toprightlabel.png" alt="" width={'100%'} height={'100%'} />
          </div>
          <div className={styles.bottomRightLabel}>
            <img src="./imagesVersion2/bottomrightlabel.png" alt="" width={'100%'} height={'100%'} />
          </div>

          {/* center-content */}
          <div className={styles.center_div}>
            <ContentSlot userData={contentData[0]} />
          </div>
          {/**/}

          {/* right-top-content */}
          <Link to="/aboutUs">
            <div className={styles.about_location}>
              <ContentSlot userData={contentData[1]} />
            </div>
          </Link>
          {/**/}

          {/* toolkit-content */}
          <div className={styles.design_philosophy}>
            <ContentSlot userData={contentData[3]} />
          </div>
          {/**/}

          <div className={`${styles.comment_box3} ${styles.box}`}>
            <p className={styles.comment_box_text}>
              “I am an engineer who aspired to be a designer and a designer who aspired to be an engineer”.
            </p>
          </div>

          {/* producer-app-page-card-top-center */}
          <Link to="/casestudy_5">
            <div className={styles.producer_app_card}>
              <ProjectCard userData={cardData[0]} />{" "}
            </div>
          </Link>
          {/**/}

          <div className={`${styles.comment_box} ${styles.box}`}>
            <p className={styles.comment_box_text}>
              “I create a digital experience that borders on efficiency, aesthetics and functionality. For me projects have a soul”
            </p>
          </div>

          {/* token-sale-card-right-center */}
          <Link to="/casestudy_8">
            <div className={styles.token_sale_page_card}>
              <ProjectCard userData={cardData[1]} />
            </div>
          </Link>
          {/**/}

          {/* opus-website-card-right-center */}
          <Link to="/casestudy_3">
            <div className={styles.opus_website_card}>
              <ProjectCard userData={cardData[2]} />
            </div>
          </Link>
          {/**/}

          <div className={`${styles.comment_box5} ${styles.box}`}>
            <p className={styles.comment_box_text}>
              “My job is  to define the important aspects of the brand, demonstrating character and emotions”
            </p>
          </div>

          <div className={`${styles.comment_box2} ${styles.box}`}>
            <p className={styles.comment_box_text}>
              “I merge aesthetics with business goals”
            </p>
          </div>

          {/* nft-password-card-right-center */}
          <Link to="/casestudy_7">
            <div className={styles.yip_Website}>
              <ProjectCard userData={cardData[3]} />
            </div>
          </Link>
          {/**/}
          <div className={`${styles.comment_box4} ${styles.box}`}>
            <p
              className={styles.comment_box_text}
            >
              “I am an eternal optimist - there’s always a better, more beautiful way and i enjoy looking for it”
            </p>
          </div>
          <Link to="/casestudy_6">
            <div className={styles.commn_wallet_card}>
              <ProjectCard2 userData={cardData[4]} />
            </div>
          </Link>
          <Link to="/casestudy_4">
            <div className={styles.commn_extension_card}>
              <ProjectCard2 userData={cardData[5]} />
            </div>
          </Link>
          <Link to="/casestudy_1">
            <div className={styles.nft_password_card}>
              <ProjectCard2 userData={cardData[6]} />
            </div>
          </Link>
        </div>
      </div>
      <div className={styles.contactUsButton}>
        <ContactUs />
      </div>
    </section>
  );
}

export default LandingPage;
