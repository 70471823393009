import { useEffect, useRef } from 'react';
import styles from './autoScroller.module.css';

const AutoScroller = () => {
    const scrollContainerRef = useRef(null);

    useEffect(() => {
        const container = scrollContainerRef.current;
        let scrollAmount = 0;

        const scroll = () => {
            if (scrollAmount >= container.scrollWidth) {
                scrollAmount = 0; // Reset scroll position to create the infinite effect
            } else {
                scrollAmount += 1;
            }
            container.scrollLeft = scrollAmount;
        };

        const interval = setInterval(scroll, 30); // Speed of scrolling
        return () => clearInterval(interval);
    }, []);

    const sliderData = [
        {
            image: 'images/casestudy_3/comma.png',
            description: "As someone who works on both web and mobile, the enhanced DSM has made a massive difference. The consistency across platforms and the logical component structure save time and reduce errors. Plus, the developer-focused documentation is a nice touch, showing a real understanding of our needs.",
            userImage: 'images/casestudy_3/user_1.png',
            userName: 'Muzaffar Iqbal',
            userTitle: 'Sr. Developer'
        },
        {
            image: 'images/casestudy_3/comma.png',
            description: "The new i2c DSM is a huge improvement over the previous version! The componentized structure and updated documentation make it so much easier to implement designs accurately. I can finally focus on coding rather than guessing design details. It’s clear and efficient—a real game changer.",
            userImage: 'images/casestudy_3/user_2.png',
            userName: 'Waleed Baig',
            userTitle: 'Pr. Developer'
        },
        {
            image: 'images/casestudy_3/comma.png',
            description: "I love the attention to detail in the revamped DSM. Every component is now intuitive and feels cohesive, which saves a lot of time. The updated guidelines have been especially helpful for responsive design, making my work smoother across devices. It feels built for developers, which I appreciate.",
            userImage: 'images/casestudy_3/user_3.png',
            userName: 'Maaz Haris',
            userTitle: 'Sr. Developer'
        }
    ];

    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                <h2>Final Thoughts & Reflections</h2>
                <h4>Project Impact</h4>
                <p className={styles.description}>
                    The i2c DSM redesign was a significant step forward in enhancing the user experience for internal teams.
                    By modernizing the interface, improving documentation, and aligning with the evolving brand, we were able
                    to create a more efficient, cohesive, and user-friendly system.
                </p>
            </div>
            <div className={styles.scrollContainer} ref={scrollContainerRef}>
                <div className={styles.sliderTrack}>
                    {sliderData.map((item, index) => (
                        <div key={index} className={styles.slide}>
                            <img src={item.image} alt="slide img" className={styles.mainImage} />
                            <p className={styles.slideDescription}>{item.description}</p>
                            <div className={styles.userInfo}>
                                <img src={item.userImage} alt="User" className={styles.userImage} />
                                <div>
                                    <p className={styles.userName}>{item.userName}</p>
                                    <p className={styles.userTitle}>{item.userTitle}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* {sliderData.map((item, index) => (
                        <div key={`duplicate-${index}`} className={styles.slide}>
                            <img src={item.image} alt="slide img" className={styles.mainImage} />
                            <p className={styles.slideDescription}>{item.description}</p>
                            <div className={styles.userInfo}>
                                <img src={item.userImage} alt="User" className={styles.userImage} />
                                <div>
                                    <p className={styles.userName}>{item.userName}</p>
                                    <p className={styles.userTitle}>{item.userTitle}</p>
                                </div>
                            </div>
                        </div>
                    ))} */}
                </div>
            </div>
            <div className={styles.bottom_heading}>
                <h3>Key Learnings</h3>
                <p>This project provided me with invaluable insights into the design process at scale. Leading the design efforts for a system used by both developers and designers allowed me to deepen my understanding of cross-functional collaboration and sharpened my ability to think both visually and systematically. I learned how to manage dev and design cost and adopt to evolving business needs. </p>
            </div>
        </div>
    );
};

export default AutoScroller;
