import style from './footerMbl.module.css';
import {footerData} from '../mblData';
function Token() {
    return (
      <div className={style.maindiv}>
        <div className={style.container}>
            <div className={style.img_wraper}>
                <img src='\imagesVersion2\mobile\footer\footer.png' alt=''/>
            </div>
            <p className={style.heading}>{footerData.heading}</p>
            <p className={style.content}>{footerData.content}</p>
            <p className={style.content} style={{color: "#fff"}}>{footerData.mail}</p>
            <div className={style.anchor}>
            <a className={style.link} href={footerData.linkedin} target='_blank' rel="noreferrer">
                LinkedIn
             </a>
             <a className={style.link} href={footerData.instagram} target='_blank' rel="noreferrer">
                Instagram
             </a>
            </div>
        </div>
      </div>
    );
  }
  
  export default Token;