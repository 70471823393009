import style from './tokenSales.module.css';
// import Header from '../../comp/header';
import Footer from '../../comp/footerMbl';
import {tokenData1} from '../../mblData';
import Navbar from '../../comp/navbar/navbar';
import {cloudVideos} from '../../../videos';
function Token() {
  // const headerData = {
  //   title: "Design Walkthrough",
  //   heading: "Token Sale’s Page",
  //   duration: "1 month",
  //   image: "\\imagesVersion2\\mobile\\tokenSale\\tokenSale.svg",
  //   detail:"Investing in Innovation: The ComnCoin Token Sale",
  //   margin: "21.538vw auto 26.154vw auto",
  //   title1:"OVERVIEW",
  //   description:"Comncoin, developed by Opus AI, is a cutting-edge cryptocurrency designed to power the Opus AI ecosystem.",
  //   description1: "The CommonCoin token sale aims to fund the ongoing development of Opus AI and establish a thriving community of early adopters and supporters.",
  //   role: "Role",
  //   roleDetail: "Ui Designer, 3D & Vector Illustrator",
  //   textDesign: "center",
  // };

  return (
      <div className={style.maindiv}>
       <div className={style.nav_container}>
        <Navbar/>
      </div>
      <div className={style.header}>
        {/* <Header userData={headerData} /> */}
        <h1 className={style.heading_main}>Token Sale’s Page</h1>
      <p className={style.design_text} style={{ paddingTop: "14.615vw"}}>OVERVIEW</p>
      <p className={style.content2}>Comncoin, developed by Opus AI, is a cutting-edge cryptocurrency designed 
        to power the Opus AI ecosystem.<br/><br/> 
        The CommonCoin token sale aims to fund the ongoing development of 
        Opus AI and establish a thriving community of early adopters and supporters.</p>
        <p className={style.design_text} style={{paddingTop: "5.128vw"}}>Spring 2023 - 1 month</p>
        <p className={style.Illustrat}>Ui Designer, 3D & Vector Illustrator</p>
      </div>
      <div className={style.header_img}>
        <img src="/imagesVersion2/mobile/tokenSale/tokenSale.svg" alt=""/>
      </div>
        {/* <div className={style.role_box}>
          <p className={style.text}>Please be patient with loading this page, as the MP4s may take a moment to 
            awaken depending on your internet speed. Thanks for your understanding!</p>
        </div> */}
        <div className={style.wraper}>
          <div className={style.inner_wraper}>
            <p className={style.heading}>{tokenData1[0].heading}</p>
            <p className={style.content}>{tokenData1[0].content}</p>
          </div>
        </div>
        <div className={style.img_container}>
            <div className={style.img_1}>
              <video autoPlay loop muted playsInline preload='none' poster ='/images/tokenSale/token1.PNG' width={"100%"} height={"100%"}>
                <source src={cloudVideos.Token_V1_Detail} type="video/mp4"/>
                </video>
            </div>
            <div className={style.img_2}>
              <video autoPlay loop muted playsInline preload='none' poster ='' width={"100%"} height={"100%"}>
                <source src={cloudVideos.Token_V2_signup} type="video/mp4"/>
                </video>
            </div>
          </div>
          <div className={style.img_container2}>
            <div>
              <img src='\imagesVersion2\mobile\tokenSale\singup2.svg' alt='' width={"100%"} height={"100%"}/>
            </div>
          </div>
          <div className={style.wraper}>
          <div className={style.inner_wraper}>
            <p className={style.heading}>{tokenData1[1].heading}</p>
            <p className={style.content}>{tokenData1[1].heading2}</p>
            <p className={style.content1}>{tokenData1[1].content}</p>
            <p className={style.content1} style={{fontSize: "5.128vw", fontWeight:'bold'}}>{tokenData1[1].content2}</p>
          </div>
        </div>
        <div className={style.coin_img}>
          <img src='\imagesVersion2\mobile\tokenSale\comncoinlogo1.png' alt=''  width={"100%"} height={"100%"}/>
        </div>
        <div className={style.coin_design}>
          <p style={{textAlign: "right"}}>New Coin Design</p>
          <div className={style.line}>
            <img src='\imagesVersion2\mobile\tokenSale\line.svg' alt=''/>
          </div>
          <p style={{width: "59vw"}}>Centered around Cat</p>
        </div>
        <div className={style.coin_img} style={{marginTop: "0", marginBottom: '33.333vw'}}>
          <img src='\imagesVersion2\mobile\tokenSale\commoncoinlogo.png' alt=''  width={"100%"} height={"100%"}/>
        </div>
        <div className={style.img_container2}>
              <video loop width={"100%"} height={"100%"} controls autoPlay>
                <source src={cloudVideos.Token_V3_HomeScreen} type="video/mp4"/>
              </video>
          </div>
          <div className={style.img_container2}>
              <video loop width={"100%"} height={"100%"} controls autoPlay>
                <source src={cloudVideos.Token_V4_Payment} type="video/mp4"/>
              </video>
          </div>
          <div className={style.slide2}>
              <img src='\imagesVersion2\mobile\tokenSale\slide1.png' alt='' width={"100%"} height={"100%"}/>
            </div>
            <div className={style.slide2}>
              <img src='\imagesVersion2\mobile\tokenSale\slide2.png' alt='' width={"100%"} height={"100%"}/>
            </div>
            <div className={style.slide2}>
              <img src='\imagesVersion2\mobile\tokenSale\slide3.png' alt='' width={"100%"} height={"100%"}/>
            </div>
            <div className={style.img_container2}>
            <div>
              <img src='\imagesVersion2\mobile\tokenSale\payment.png' alt='' width={"100%"} height={"100%"}/>
            </div>
          </div>

        <div className={style.footer}>
          <Footer/>
        </div>
      </div>
    );
  }
  
  export default Token;